import { AiFillEdit, AiTwotoneDelete } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";

import { AiFillEye } from "react-icons/ai";

import ImagePopUpModal from "../../components/ImagePopUpModal";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import BannerForm from "../../components/formComponents/BannerForm";
import moment from "moment";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";

const BannerTable = ({
  setBannerId,
  showBannerData,
  setShowDeleteAlert,
  fetchBannerList,
  page,
  perPage,
}) => {
  // let navigate = useNavigate();
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [id, setId] = useState("");
  const [img, setImg] = useState();
  const [data, setData] = useState("");

  const handleOnClickEdit = (data) => {
    console.log(id, "-------------");
    setShowEditAlert(true);
    setId(data._id);
    setData(data);
  };

  const onClickEdit = () => {
    setShowEditAlert(false);
  };

  const handleOnClickEditClose = () => {
    setShowEditAlert(false);
  };

  const handleOpenBannerImage = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleCloseBannerImage = () => {
    setShowImageAlert(false);
  };

  const handleDeleteBanner = (id) => {
    setBannerId(id);
    setShowDeleteAlert(true);
  };

  return (
    <>
      <div className="banner__list__container web_table_container">
        <table className="banner__list__table__container">
          <thead>
            <th className="banner__list__table__head">S.No</th>
            <th className="banner__list__table__head">Banner ID</th>
            <th className="banner__list__table__head">Banner Name</th>
            <th className="banner__list__table__head">Banner Image</th>
            <th className="banner__list__table__head">Banner Type</th>
            <th className="banner__list__table__head">Date & Time</th>
            <th className="banner__list__table__head">Updated Date & Time</th>
            <th className="banner__list__table__head">Action</th>
          </thead>
          <tbody className="banner__list__body">
            {showBannerData && showBannerData.length > 0
              ? showBannerData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="banner__list__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="banner__list__data">{data?.bannerId}</td>

                      <td className="banner__list__data">{data?.name}</td>
                      <td className="banner__list__data">
                        <AiFillEye
                          onClick={() => {
                            handleOpenBannerImage(data?.imageUrl);
                          }}
                          className="banner__list__eye__icon"
                        />
                      </td>
                      {data?.bannerType ? (
                        <td className="banner__list__data">
                          {data?.bannerType ? data?.bannerType : "-"}
                        </td>
                      ) : (
                        <td className="banner__list__data">-</td>
                      )}
                      <td className="banner__list__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                      <td className="banner__list__data">
                        {moment(data?.updatedAt).format("DD/MM/YYYY , LT")}
                      </td>
                      <td className="banner__list__data">
                        <AiFillEdit
                          onClick={() => {
                            handleOnClickEdit(data);
                          }}
                          className="banner__list__edit__action"
                        />
                        <AiTwotoneDelete
                          className="banner__list__delete__action"
                          onClick={() => {
                            handleDeleteBanner(data._id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!showBannerData.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found"> No Data Found</p>
          </div>
        ) : null}
      </div>
      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleCloseBannerImage}
        img={img}
      />

      <FormAlertPopUp
        open={showEditAlert}
        onRequestClose={handleOnClickEditClose}
      >
        <BannerForm
          onClickEdit={onClickEdit}
          edit={true}
          id={id}
          data={data}
          fetchBannerList={fetchBannerList}
        />
      </FormAlertPopUp>
    </>
  );
};

export default BannerTable;
