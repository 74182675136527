import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { useLoader } from "../../../base/Context/loaderProvider";
import InputField from "../../library/InputField";
import Button from "../../library/Button";
import { IoClose } from "react-icons/io5";
import { PiLinkSimpleBold } from "react-icons/pi";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import APIProvider, { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";

export default function BulkPopup({
  show,
  setShow,
  handleMailList,
  users,
  type,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [host, setHost] = useState(false);
  const [user, setUser] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  // const [files, setFiles] = useState([]);
  const apiProvider = useApi();

  useEffect(() => {
    if (searchParams.get("appType") === "host") {
      setHost(true);
      setUser(false);
    } else {
      setHost(false);
      setUser(true);
    }
  }, [searchParams]);

  const textAreaRef = useRef();
  // const navigate = useNavigate();
  const app = useLoader();

  function auto_height() {
    textAreaRef.current.style.height = "1px";
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }

  const handleSubject = (e) => {
    setSubject(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleFile = (e) => {
    setImage([...image, e.target.files[0]]);
  };

  const handleRemoveFile = (e) => {
    const newFiles = image.filter((file) => {
      return file.name !== e;
    });
    setImage(newFiles);
  };

  const validate = () => {
    let result = true;
    if (!subject) {
      result = false;
    } else if (!message) {
      result = false;
    }
    return result;
  };

  const handleSendMail = () => {
    if (validate()) {
      const emailPayload = new FormData();
      emailPayload.append("subject", subject);
      emailPayload.append("message", message);
      image.forEach((item) => {
        emailPayload.append("images", item);
      });
      emailPayload.append(
        "to",
        searchParams.get("appType") === "host" ? "hosts" : "users"
      );

      app.showLoader(true);
      fetchDataFromAPI(
        searchParams.get("appType") === "host"
          ? apiProvider?.getApiUrl() + NetworkConfiguration.SENDMAILTOALL
          : apiProvider?.getApiUrl() + NetworkConfiguration.SENDMAILTOFEWUSERS,
        "POST",
        emailPayload,
        { "Content-Type": "multipart/form-data" }
      )
        .then((res) => {
          app.showLoader(false);
          successToast(res?.message);
          setShow(false);
          handleMailList();
        })
        .catch((err) => {
          app.showLoader(false);
          errorToast(err?.message);
        });
    }
  };

  const sendMailToFew = () => {
    if (validate()) {
      const emailPayload = new FormData();
      emailPayload.append("subject", subject);
      emailPayload.append("message", message);
      image.forEach((item) => {
        emailPayload.append("images", item);
      });
      // emailPayload.append(
      // "usersEmail",
      users && users.map((item) => emailPayload.append("usersEmail", item.id));
      // );
      // emailPayload.append(
      //   "to",
      //   searchParams.get("appType") === "host" ? "hosts" : "users"
      // );

      app.showLoader(true);
      fetchDataFromAPI(
        searchParams.get("appType") === "host"
          ? apiProvider?.getApiUrl() + NetworkConfiguration.SENDMAILTOFEWHOST
          : apiProvider?.getApiUrl() + NetworkConfiguration.SENDMAILTOFEWUSERS,
        "POST",
        emailPayload,
        { "Content-Type": "multipart/form-data" }
      )
        .then((res) => {
          app.showLoader(false);
          successToast(res?.message);
          setShow(false);
          if (type === "sendMail") {
            handleMailList();
          }
        })
        .catch((err) => {
          app.showLoader(false);
          errorToast(err?.message);
        });
    }
  };
  const handlePeople = () => {
    if (searchParams.get("appType") === "host") {
      return "Host";
    } else {
      return "User";
    }
  };
  console.log(users, "userssssss");
  return (
    <>
      <div className="mail_popup_bg">
        <div className="mail_popup_head_row">
          <p>New Message</p>
          <div onClick={() => setShow(false)} style={{ cursor: "pointer" }}>
            <IoClose size={20} color="#000" />
          </div>
        </div>

        <div className="mail_input_row">
          <p className="mail">To </p>

          <div className="mail_checkbox_row">
            <label for="user">
              {users
                ? users.map((item) => item.name).join(", ")
                : `All${handlePeople()}`}
              {/* {users ? users.map((item) => item.name) : `All${handlePeople()}`} */}
            </label>
          </div>
        </div>

        <div className="mail_input_row">
          <p>Subject </p>
          <InputField
            className="mail__input"
            label=""
            type="text"
            placeholder=""
            onChange={handleSubject}
            error=""
            value={subject}
            style={{
              border: "none",
              borderRadius: "0",
            }}
          />
        </div>

        <div className="mail_message_box">
          <textarea
            ref={textAreaRef}
            rows="1"
            className="auto_height"
            onInput={auto_height}
            value={message}
            onChange={handleMessage}
          ></textarea>

          <div className="mail_file_box">
            {image?.length
              ? image.map((file, index) => {
                  return (
                    <div key={index} className="mail_selected_file">
                      <p>{file?.name}</p>
                      <IoClose
                        size={18}
                        color="#000"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveFile(file?.name)}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div className="mail_bottom_box">
          <Button
            onClick={users ? sendMailToFew : handleSendMail}
            text="Send"
            style={null}
            className="header_btn"
            type="button"
          />

          <div className="file_upload_mail_bg">
            <div class="image-upload">
              <label for="file-input">
                <div className="file_link_icon">
                  <PiLinkSimpleBold className="file_link_icon_style" />
                </div>
              </label>

              <input id="file-input" type="file" onChange={handleFile} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
