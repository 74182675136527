import { useEffect, useState } from "react";
import { useApi } from "../../../base/Context/apiProvider";
import { useLoader } from "../../../base/Context/loaderProvider";
import "./style.css";
import { Box, Modal } from "@mui/material";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "50%",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
};

const HostLikedProfile = ({ open, handleClose, id }) => {
  const apiProvider = useApi();
  const loader = useLoader();

  const [likedBy, setLikedBy] = useState();

  useEffect(() => {
    fetchLikedProfile(id);
  }, [apiProvider?.getApiUrl, id]);

  const fetchLikedProfile = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETLIKEHOSTPOST +
        `/${id}`,
      "GET"
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setLikedBy(res?.result?.likedBy);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };
  console.log(likedBy,'likedBY')
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className=" user_moment_container "  style={{height:'100% !important'}}>
            <table className="user_moment_table">
              <thead>
                <th className="user_moment_header">S.NO.</th>

                <th className="user_moment_header">Name</th>
                <th className="user_moment_header">Email</th>
                <th className="user_moment_header">Mobile Number</th>
                {/* <th className="user_moment_header">Hashtag</th>
                  <th className="user_moment_header">Likes</th>
                  <th className="user_moment_header">Post Type</th>
    <th className="user_moment_header">Reason</th>*/}
                <th className="user_moment_header">Created At</th>
              </thead>
              <tbody>
                {likedBy && likedBy?.length ? (
                  likedBy.map((item, index) => {
                    return (
                      <tr>
                        <td className="user_moment_data">{index + 1}</td>
                        <td className="user_moment_data">{item?.name}</td>
                        <td className="user_moment_data">{item?.email}</td>
                        <td className="user_moment_data">
                          {item?.mobileNumber}
                        </td>
                        <td className="user_moment_data">
                          {moment(item?.createdAt).format("DD/MM/YYYY , LT")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td className="user_moment_no_data">No Data Found </td>
                )}
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default HostLikedProfile;
