import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useApi } from "../../../base/Context/apiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";

const UserRequestForm = ({ id, onSubmit }) => {
  const [data, setData] = useState();
  const [reason, setReason] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState({
    reasonError: "",
  });
  // const [showReason, setShowReason] = useState(false);
  const apiProvider = useApi();
  const navigate = useNavigate();
  const loader = useLoader();
useEffect(()=>{
setReason(data?.reasionUpdateProfile)
},[data])
  useEffect(() => {
    fetchOneUser();
  }, [apiProvider?.getApiUrl()]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    let result = true;
    console.log(reason)
    if (!reason) {
      setError({
        ...error,
        reasonError: "Enter the reason for the changed profile",
      });
      result = false;
    }
    return result;
  };

  const fetchOneUser = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETONEUSER + `/${id}`,
      "GET"
    )
      .then((res) => {
        delete res.getOneUser?._id;
        setData(res.getOneUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditUser = () => {
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEUSER,
        "PUT",
        {
          id: id,
          ...data,
          reasionUpdateProfile: reason,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          navigate(
            `${
              searchParams.get("appType") === "catchwoo"
                ? `/usermanagement/${id}?appType=` + searchParams.get("appType")
                : `/usermanagement/${id}`
            }`
          );
          onSubmit();
        })
        .catch((err) => {
          loader.showLoader(false);
        });
    }
  };
  return (
    <div className="user__form__container" style={{width:'350px'}}>
      <h2 className="user__form__heading ">Edit User Details</h2>
      <div className="user__form__content">
        <InputField
          style={{ pointerEvents: "none" }}
          value={data?.name}
          onChange={handleChange}
          name="name"
          placeholder="Name"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          onChange={handleChange}
          value={data?.gender}
          name="gender"
          placeholder="Gender"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.dateOfBirth}
          onChange={handleChange}
          name="dateOfBirth"
          placeholder="Date Of Birth"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.mobileNumber}
          onChange={handleChange}
          name="mobileNumber"
          placeholder="Mobile Number"
        />

        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.email}
          onChange={handleChange}
          name="email"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.country}
          onChange={handleChange}
          name="country"
          placeholder="Country"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.state}
          onChange={handleChange}
          name="state"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.city}
          onChange={handleChange}
          name="city"
        />
        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.proffession}
          onChange={handleChange}
          name="proffession"
          placeholder="Proffession"
        />

        <InputField
          style={{ pointerEvents: "none" }}
          readOnly
          value={data?.addBio}
          onChange={handleChange}
          name="addBio"
          placeholder="Add Bio"
        />
        <InputField
          placeholder="Reason for changing information"
          value={data?.reasionUpdateProfile}
          onChange={(e) => {
            setReason(e.target.value);
            setError({ ...error, reasonError: "" });
          }}
          name="showReason"
          maxlength="25"
          error={error.reasonError}
        />
        <div>
          <br />
          <Button
            style={{ margin: "auto" }}
            onClick={handleEditUser}
            text="Update"
          />
        </div>
      </div>
      {/* <Form */}
    </div>
  );
};
export default UserRequestForm;
