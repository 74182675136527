import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DynamicFavicon = () => {
  let appType = sessionStorage.getItem("selectedType");

  useEffect(() => {
    const updateFavicon = () => {
      let faviconUrl;

      switch (appType) {
        case "catchwoo":
          faviconUrl = "/favicon_ioCatchwoo/favicon.ico";
          break;
        case "host":
          faviconUrl = "/favicon_io/favicon.ico";
          break;

        default:
          faviconUrl = "/favicon_io/favicon.ico";
      }

      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = faviconUrl;
    };

    updateFavicon();
  }, [appType]);

  return null;
};

export default DynamicFavicon;
