import { AiFillCalendar, AiFillDelete, AiFillEdit } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import moment from "moment";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import AlertPopUp from "../../AlertPopUp";
import { useSearchParams } from "react-router-dom";
import InputField from "../../library/InputField";
import Button from "../../library/Button";
import WebModal from "../../WebModal";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import FormAlertPopUp from "../../FormAlertPopUp";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";

const SuspendedUserTable = () => {
  const [suspendedUserList, setSuspendedUserList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [showEditAlert, setShowEditAlert] = useState(false);
  const [id, setId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [showSuspendedPop, setShowSuspendedPop] = useState(false);
  const [exportAllData, setExportAllData] = useState([]);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [showCalenderEdit, setShowCalenderEdit] = useState(false);


  useEffect(() => {
    let array = [];
    suspendedUserList.map((item, index) => {
      let obj = {
        "S No.": index + 1,

        "User Id": item?.userId?.userId,
        "User Name": item?.userId?.name,
        "Suspended ID": item?.suspendedId,
        "Suspended From": moment(item?.createdAt).format("DD/MM/YYYY"),
        "Suspended To": moment(item?.suspensionEndDate).format("DD/MM/YYYY"),
        "Suspended Reason": item?.suspendedResion,
        "Is Suspended": item?.isSuspended,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [suspendedUserList]);

  const loader = useLoader();
  const apiProvider = useApi();

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Suspended Users Report";
    const headers = [
      [
        "S No",
        "User Id",
        "User Name",
        "Suspended ID",
        "Suspended From",
        "Suspended To",
        "Suspended Reason",
        "Is Suspended",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,

      item1["User Id"],
      item1["User Name"],
      item1["Suspended ID"],
      item1["Suspended From"],
      item1["Suspended To"],
      item1["Suspended Reason"],
      item1["Is Suspended"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Suspended users Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleSuspendedUser = (id) => {
    setShowSuspendedPop(true);
    setId(id);
  };
const handleActivateUser=()=>{
  loader.showLoader(true);
  fetchDataFromAPI(
    apiProvider?.getApiUrl() + NetworkConfiguration.ACTIVATEUSER,
    "POST",
    {
      id: id,
    }
  )
    .then((res) => {
      // getSuspendedHost();
      getSuspendedUserList();
      loader.showLoader(false);
      console.log(res);
      // setShowSuspendedPop(false);
    })
    .catch((err) => {
      loader.showLoader(false);

      console.log(err);
    });
}
  const handleSuspendedUserClose = () => {
    setShowSuspendedPop(false);
  };

  const getSuspendedUserList = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SUSPENDEDUSER,
      "POST",
      searchParams.get("id")
        ? { userId: searchParams.get("id") }
        : {
            key: value,
            page,
            perPage,
            startDate,
            endDate,
          }
    )
      .then((res) => {
        setSuspendedUserList(res.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setSuspendedUserList([]);
        console.log(err);
      });
  };

  useEffect(() => {
    getSuspendedUserList();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const handleOnClickDelete = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleCalenderEdit = (id) => {
    setShowEditAlert(true);
    setId(id);
  };
  const handleOnClickEdit = (id) => {
    setShowEditAlert(true);
    setId(id);
  };

  const handleOnCalenderClose = () => {
    setShowCalenderEdit(false);
  };
  const handleOnClickEditClose = () => {
    setShowEditAlert(false);
  };

  const handleAlertDelete = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETESUSPENSION +
        `/${id}`,
      "DELETE"
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteAlert(false);
        getSuspendedUserList();
      })
      .catch((err) => {
        loader.showLoader(false);

        console.log(err);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExcelExport = () => {
    const filename =`Suspended users Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCSV = () => {
    const filename =`Suspended users Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleUpdate = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.EDITSUSPENSION,
      "PUT",
      {
        id,
        suspensionEndDate: endDate1,
      }
    )
      .then((res) => {
        console.log(res);
        setShowCalenderEdit(false);
        getSuspendedUserList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAlertSuspended = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REMOVESUSPENSION,
      "PUT",
      {
        id: id,
      }
    )
      .then((res) => {
        getSuspendedUserList();
        loader.showLoader(false);
        console.log(res);
        setShowSuspendedPop(false);
      })
      .catch((err) => {
        loader.showLoader(false);

        console.log(err);
      });
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="suspended_table_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExcelExport} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCSV} />
      </div>
      <div className="suspended__table__container web_table_container">
        <div className="table_parent_box">
          <table className="suspended__table">
            <thead>
              <th className="suspended__table__header">S.no</th>
              {!searchParams.get("id") && (
                <>
                  <th className="suspended__table__header">User ID</th>
                  <th className="suspended__table__header">User Name</th>
                </>
              )}
              <th className="suspended__table__header">Suspended ID</th>

              <th className="suspended__table__header">Suspended From</th>
              <th className="suspended__table__header">Suspended To</th>
              <th className="suspended__table__header">Suspended Reason</th>
              <th className="suspended__table__header">Is Suspended</th>

              <th className="suspended__table__header">Action</th>
            </thead>
            <tbody>
              {suspendedUserList?.length > 0
                ? suspendedUserList.map((data, index) => {
                    return (
                      <tr>
                        <td className="suspended__table__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        {!searchParams.get("id") && (
                          <>
                            <td className="suspended__table__data">
                              {data?.userId!==null?data?.userId?.userId:'Deleted-User'}
                            </td>

                            <td className="suspended__table__data">
                              {data?.userId!==null?data?.userId?.name:'Deleted-user'}
                            </td>
                          </>
                        )}
                        <td className="suspended__table__data">
                          {data?.suspendedId}
                        </td>
                        <td className="suspended__table__data">
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="suspended__table__data">
                          {moment(data?.suspensionEndDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="suspended__table__data">
                          {data?.suspendedResion}
                        </td>
                        <td
                          className="suspended__table__data"
                          onClick={() => handleSuspendedUser(data?._id)}
                        >
                          {`${data?.isSuspended}`}
                        </td>
                        <td className="suspended__table__data suspended__table__edit__icon ">
                          <AiFillCalendar
                            onClick={() => {
                              handleCalenderEdit(data?.suspendedId);
                            }}
                          ></AiFillCalendar>
                          <AiFillEdit
                            onClick={() => {
                              handleOnClickEdit(data?._id);
                            }}
                          />
                          <AiFillDelete
                            className="suspended__table__delete__icon"
                            onClick={() => {
                              handleOnClickDelete(data?._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        {!suspendedUserList?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAlert}
          handleClose={handleDeleteAlertClose}
          header="Delete Alert"
          description="Are you sure you want to delete this Suspended User?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleAlertDelete}
          onCancelClick={handleDeleteAlertClose}
        />

        <AlertPopUp
          open={showSuspendedPop}
          handleOpen={handleSuspendedUser}
          handleClose={handleSuspendedUserClose}
          header="Remove Suspension"
          description="Are you sure you want to remove this profile from suspension?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleAlertSuspended}
          onCancelClick={handleSuspendedUserClose}
        />
         <WebModal
          open={showEditAlert}
          onRequestClose={handleOnClickEditClose}
          heading="Activate Suspended User"
        >
          {/* <InputField
            type="date"
            onChange={(e) => setEndDate1(e.target.value)}
            value={endDate1}
            min={endDate1}
          /> */}
          <br />
          <Button
            style={{ margin: "auto" }}
            text="Activate User"
            onClick={handleActivateUser}
          />
        </WebModal>
        <WebModal
          open={showCalenderEdit}
          onRequestClose={handleOnCalenderClose}
          heading="Edit Suspended User"
        >
          <InputField
            type="date"
            onChange={(e) => setEndDate1(e.target.value)}
            value={endDate1}
            min={endDate1}
          />
          <br />
          <Button
            style={{ margin: "auto" }}
            text="Update"
            onClick={handleUpdate}
          />
        </WebModal>

        {/* <FormAlertPopUp
          open={showEditAlert}
          onRequestClose={handleOnClickEditClose}
        ></FormAlertPopUp> */}
      </div>
      {suspendedUserList?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default SuspendedUserTable;
