import React, { useState } from "react";
import InputField from "../../library/InputField";
import Button from "../../library/Button";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import APIProvider, { useApi } from "../../../base/Context/apiProvider";
import { successToast } from "../../../utils/toast";

const AddCatogery = (setShowBannerForm) => {
  const [catogeryName, setCatogeryName] = useState("");
  const handleChange = (e) => {
    setCatogeryName(e.target.value);
  };
  const apiProvider = useApi();

  const AddCatogery = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ADDBANNERTYPE,
      "POST",
      {
        bannerType: catogeryName,
      }
    )
      .then((res) => {
        successToast("Category Added Successfully");
        console.log(res);
        setCatogeryName("");
      })
      .catch((err) => {
        // setShowBannerData([]);
        // console.log("err", err);
      });
  };
  return (
    <div
      style={{
        width: "300px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <p style={{ fontSize: "24px", fontWeight: "bold" }}>Add Catogery</p>
      <InputField
        type="text"
        // maxlength="4"
        value={catogeryName}
        onChange={handleChange}
        placeholder="Add catogery"
        // error={error.price}
      />
      <Button
        style={{ margin: "0px" }}
        className="add__gift__form__btn"
        text={"Add Catogery"}
        onClick={AddCatogery}
      />
    </div>
  );
};

export default AddCatogery;
