// import { BsFillEyeFill } from "react-icons/bs";
import "./style.css";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import AlertPopUp from "../../AlertPopUp";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { Modal } from "../../../base/Context/modalProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import jsPDF from "jspdf";

const FeedbackUserTable = () => {
  const { id } = useParams();
  const navigate=useNavigate()

  const [feedback, setFeedback] = useState([]);
  const [showRevertAlert, setShowRevertAlert] = useState(false);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [img, setImg] = useState("");
  // const [getId, setGetId] = useState("");
  const [replyFeedback, setReplyFeedback] = useState("");
  // const [response, setResponse] = useState("");
  const [getId, setGetId] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
 
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const modalProvider = useContext(Modal);

  useEffect(() => {
    let array = [];
    feedback?.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "User ID": item?.userId?.userId,
        "User Name": item?.userId?.name,
        Title: item?.feedbackType,
        Description: item?.comment,
        "Image/Video": item?.feedbackImage,
        "Contact Details": item?.email || item?.contact,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),

        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),

        "Track Status": item?.trackStatus[item?.trackStatus?.length - 1]
          ? item?.trackStatus[item?.trackStatus?.length - 1].title
          : item?.trackStatus[0].title,
        "Revert Back": item?.trackStatus[1]?.response,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [feedback]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Feedback List Report";
    const headers = [
      [
        "S.No.",
        "User ID",
        "User Name",
        "Title",
        "Description",
        "Image/Video",
        "Contact Details",
        "Created At",
        "Created At",
        "Track Status",
        "Revert Back",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["User ID"],
      item1["User Name"],
      item1["Title"],
      item1["Description"],
      item1["Image/Video"],
      item1["Contact Details"],
      item1["Created At"],
      item1["Created At"],
      item1["Track Status"],
      item1["Revert Back"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const loader = useLoader();
  const apiProvider = useApi();

  const handleShowImage = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleShowImageClose = () => {
    setShowImageAlert(false);
  };

  useEffect(() => {
    getAllUsersFeedback();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const getAllUsersFeedback = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERFEEDBACK,
      "POST",
      { userId: id, startDate, endDate, key: value, page, perPage }
    )
      .then((res) => {
        loader.showLoader(false);
        setFeedback(res.result);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setFeedback([]);
      });
  };

  const handleFeedbackReply = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.SENDREPLYUSER +
        `/${getId}`,
      "POST",
      {
        response: replyFeedback,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowRevertAlert(false);
        getAllUsersFeedback();
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  const handleFeedbackRevert = (getId) => {
    setShowRevertAlert(true);
    // setResponse(response);
    setGetId(getId);
  };

  const handleFeedbackRevertClose = () => {
    setShowRevertAlert(false);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleCloseClick = (id) => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.SENDREPLYUSER +
        `/${id}`,
      "POST",
      {
        response: replyFeedback,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowRevertAlert(false);
        getAllUsersFeedback();
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleExportCsv = () => {
    const filename =`Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="feedback_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="feedback__container web_table_container">
        {/* <div className="table_parent_box"> */}
        <table className="feedback__table__container">
          <thead>
            <th className="feedback__table__heading">S.No.</th>
            {!id && (
              <>
                <th className="feedback__table__heading">User ID</th>
                <th className="feedback__table__heading">User Name</th>
              </>
            )}
            <th className="feedback__table__heading">Title</th>
            <th className="feedback__table__heading">Description</th>
            <th className="feedback__table__heading">Image/Video</th>
            <th className="feedback__table__heading">Contact Details </th>
            <th className="feedback__table__heading">Created At</th>
            <th className="host__feedback__table__heading"> Track Status </th>
            <th className="feedback__table__heading">Revert Back</th>
            <th className="host__feedback__table__heading">Close Feedback </th>
          </thead>
          <tbody>
            {feedback?.length > 0
              ? feedback.map((data, index) => {
                  return (
                    <tr>
                      <td className="feedback__table__data">
                        {" "}
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {!id && (
                        <>
                          <td className="feedback__table__data">
                            {data?.userId?.userId?data?.userId?.userId:'Deleted-User'}
                          </td>
                          <td className="feedback__table__data">
                            <div
                              className="feedback__table__comment"
                              onClick={
                                data?.userId?.name?.length > 10
                                  ? () =>
                                      modalProvider.handleCommentClick(
                                        data?.userId?.name,
                                        "User Name"
                                      )
                                  : () => {}
                              }
                            >
                              {data?.userId?.name?data?.userId?.name:'Deleted-User'}
                            </div>
                          </td>
                        </>
                      )}
                      <td className="feedback__table__data">
                        {data?.feedbackType}
                      </td>
                      <td className="feedback__table__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.comment?.length > 0
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.comment,
                                    "Description"
                                  )
                              : () => {}
                          }
                        >
                          {data?.comment}
                        </div>
                      </td>

                      <td className="feedback__table__data">
                        <AiFillEye
                          onClick={() => {
                            handleShowImage(data?.feedbackImage);
                          }}
                          className="feedback__table__eye__icon"
                        />
                      </td>
                      <td className="feedback__table__data">
                        {data?.contact || data?.email}
                      </td>
                      <td className="feedback__table__data">
                        {moment(data.createdAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="host__feedback__table__data">
                        {
                          data?.trackStatus[data?.trackStatus?.length - 1]
                            ?.title
                        }
                      </td>

                      {data?.trackStatus[1]?.response ? (
                        <td className="feedback__table__data">
                          <div
                            className="feedback__table__comment"
                            onClick={
                              // data?.trackStatus[1]?.response?.length > 12
                              //   ? () =>
                              //       modalProvider.handleCommentClick(
                              //         data?.trackStatus[1]?.response,
                              //         "Revert"
                              //       )
                              //   : () => {}
                                () =>navigate(`/chat/${data._id}`, {
                                  state: { userId: data?.userId?._id ,description:data?.comment,status:data?.trackStatus[data?.trackStatus?.length - 1]
                                    ?.title,feedBackType:data?.feedbackType}
                                })
                            }
                          >
                            {data?.trackStatus[1]?.response}
                          </div>
                        </td>
                      ) : (
                        <td
                        onClick={() =>navigate(`/chat/${data._id}`, {
                          state: { userId: data?.userId?._id ,description:data?.comment,status:data?.trackStatus[data?.trackStatus?.length - 1]
                            ?.title,feedBackType:data?.feedbackType}
                        })}
                          className="feedback__table__data feedback__table__reply"
                        >
                          Reply
                        </td>
                      )}

                      <td className="host__feedback__table__data host_feedback_column">
                        {data?.trackStatus &&
                       data?.trackStatus[data?.trackStatus?.length - 1]
                       ?.title== "Closed" ? (
                          <p className="host_feedback_btn_disable">Close</p>
                        ) : (
                          <p
                            className="host_feedback_btn_click"
                            onClick={() => handleCloseClick(data?._id)}
                          >
                            Close
                          </p>
                        )}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {feedback &&!feedback?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>

      <AlertPopUp
        open={showRevertAlert}
        handleOpen={handleFeedbackRevert}
        handleClose={handleFeedbackRevertClose}
        submitText="Submit"
        cancelText="Cancel"
        header="Enter Your Response"
        onSubmitClick={handleFeedbackReply}
        onCancelClick={handleFeedbackRevertClose}
        onChangeField={(e) => {
          setReplyFeedback(e.target.value);
        }}
        textField={true}
      />

      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleShowImageClose}
        images={img}
      />
      {/* </div> */}
      {feedback?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default FeedbackUserTable;
