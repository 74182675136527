import "./style.css";

const Cards = (props) => {
  return (
    <div className="main__card" onClick={props.onClick} style={props.style}>
      <div className="card_icon_container">

        <img className="card__icon"src={props.icon} alt={props.icon}></img>
      </div>
      <div className="card__icon__number">
      <h3 className="card__heading">{props.name}</h3>
      </div>
        <p className="card__number">{props.number}</p>
      <div className="light-grey">
        <div className="container w3-green" style={{ width: "50%" }}></div>
      </div>
    </div>
  );
};

export default Cards;
