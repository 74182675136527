import { useContext, useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import moment from "moment";
import { AiFillDelete } from "react-icons/ai";
import AlertPopUp from "../../components/AlertPopUp";
import Pagination from "../../components/Pagination";
import { Modal } from "../../base/Context/modalProvider";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import Button from "../../components/library/Button";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import { useSearchParams } from "react-router-dom";

const ContactForm = () => {
  const apiProvider = useApi();
  const [contactForm, setContactForm] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [id, setId] = useState("");
  const modalProvider = useContext(Modal);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let array = [];
    contactForm.map((item, index) => {
      let obj = {
        "S.No": index + 1,

        "Contact ID": item?.contact_Id,
        Email: item?.email,
        Subject: item?.subject,
        Message: item?.message,
        "Date & Time": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [contactForm]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Feedback List Report";
    const headers = [
      ["Contact ID", "Email", "Subject", "Message", "Date & Time"],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1) => [
      item1["Contact ID"],
      item1["Email"],
      item1["Subject"],
      item1["Message"],
      item1["Date & Time"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Contacts Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchContactForm();
  }, [apiProvider?.apiUrl, page, perPage, value, endDate]);

  const fetchContactForm = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETCONTACTFORMLIST,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        console.log(res);
        setContactForm(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setContactForm([]);
      });
  };

  const handleDeleteSubmit = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETECONTACTMESSAGE +
        `/${id}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        setShowDeleteAlert(false);
        fetchContactForm();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Contacts Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportPdf = () => {
    exportToPDF(exportAllData);
  };

  const handleExportCsv = () => {
    const filename =`Contacts Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />
      <div className="moment_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="contact_form_container web_table_container">
        <table className="contact_form_table">
          <thead>
            <th className="contact_form_header">S.No.</th>
            <th className="contact_form_header">Contact ID</th>
            <th className="contact_form_header">Email</th>
            {searchParams.get("appType") === "catchwoo" && (
              <>
                <th className="contact_form_header">Name</th>
                <th className="contact_form_header">DOB</th>
                <th className="contact_form_header">Gender</th>
                <th className="contact_form_header">City</th>
                <th className="contact_form_header">Mobile Number</th>
              </>
            )}
            <th className="contact_form_header">Subject</th>
            <th className="contact_form_header">Message</th>
            <th className="contact_form_header">Date & Time</th>
            <th className="contact_form_header">Action</th>
          </thead>
          <tbody>
            {contactForm && contactForm?.length
              ? contactForm.map((data, index) => {
                  return (
                    <tr>
                      <td className="contact_form_data">{index + 1}</td>
                      <td className="contact_form_data">
                        {searchParams.get("appType") !== "catchwoo"
                          ? data?.contact_Id
                          : data?.conatect_Id}
                      </td>
                      <td className="contact_form_data">{data?.email}</td>
                      {searchParams.get("appType") === "catchwoo" && (
                        <>
                          <td className="contact_form_data">{data?.name}</td>
                          <td className="contact_form_data">
                            {data?.date_Of_Birth}
                          </td>
                          <td className="contact_form_data">{data?.gender}</td>
                          <td className="contact_form_data">{data?.city}</td>
                          <td className="contact_form_data">
                            {data?.mobileNumber}
                          </td>
                        </>
                      )}

                      <td className="contact_form_data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.subject.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.subject,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.subject}
                        </div>
                      </td>
                      <td className="contact_form_data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.message.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.message,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.message}
                        </div>
                      </td>
                      <td className="contact_form_data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                      <td className="contact_form_data">
                        <AiFillDelete
                          className="contact_form_icon"
                          onClick={() => handleDelete(data?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        {!contactForm.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDelete}
        handleClose={handleDeleteClose}
        header="Delete Alert"
        description="Are you sure you want to delete this message request?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDeleteSubmit}
        onCancelClick={handleDeleteClose}
      />

      {contactForm.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[5, 10, 15, 20]}
        />
      ) : null}
    </>
  );
};

export default ContactForm;
