import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base//Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import moment from "moment";

const HostCallHistoryTable = () => {
  const [getCallHistory, setGetCallHistory] = useState([]);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");

  const loader = useLoader();
  const apiProvider = useApi();

  const formatVideoCallTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours > 0 ? `${hours < 10 ? "0" : ""}${hours}:` : ""}${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    fetchCallHistory();
  }, [page, perPage, apiProvider?.getApiUrl()]);

  const fetchCallHistory = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.HOSTCALLHISTORY,
      "POST",
      {
        id: id,
        page,
        perPage,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
        setGetCallHistory(res?.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="host__call__history__container web_table_container">
        <table className="host__call__history__table">
          <thead>
            <th className="host__call__history__header">S.No.</th>
            <th className="host__call__history__header">User ID</th>
            <th className="host__call__history__header">User Name</th>
            <th className="host__call__history__header">Audio Coins</th>
            <th className="host__call__history__header">Video Coins</th>
            {/* <th className="host__call__history__header">Post Coins</th> */}
            <th className="host__call__history__header">Call Gift</th>
            <th className="host__call__history__header">Coin Spend</th>
            {/* <th className="host__call__history__header">Mode</th> */}
            <th className="host__call__history__header">Time Duration</th>
            <th className="host__call__history__header">Call Type</th>

            <th className="host__call__history__header">App Type </th>
            <th className="host__call__history__header">Created At</th>
          </thead>
          <tbody>
            {getCallHistory?.length > 0
              ? getCallHistory.map((data, index) => {
                  return (
                    <tr>
                      <td className="host__call__history__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="host__call__history__data">
                        {data?.targetId?.userId}
                      </td>
                      <td className="host__call__history__data">
                        {data?.targetId?.name}
                      </td>
                      <td className="host__call__history__data">
                        {data?.audioCoins ? data?.audioCoins : 0}
                      </td>
                      <td className="host__call__history__data">
                        {data?.videoCoins ? data?.videoCoins : 0}
                      </td>
                      {/* <td className="host__call__history__data">
                        {data?.targetId?.hostPostCoins}
                      </td> */}
                      <td className="host__call__history__data">
                        {data?.giftCoin ? data?.giftCoin : 0}
                      </td>
                      <td className="host__call__history__data">
                        {data?.audioCoins
                          ? data?.audioCoins
                          : 0 + data?.videoCoins
                          ? data?.videoCoins
                          : +0 + data?.giftCoin
                          ? data?.giftCoin
                          : 0}
                      </td>

                      <td className="host__call__history__data">
                        {data?.total_minute
                          ? formatVideoCallTime(data?.total_minute)
                          : "-"}
                      </td>
                      <td className="host__gift__data">{data?.calling}</td>
                      <td className="host__gift__data">
                        {data?.targetId?.appType}
                      </td>

                      <td className="host__call__history__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!getCallHistory?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>
      {getCallHistory?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default HostCallHistoryTable;
