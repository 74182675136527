import { BsFillEyeFill } from "react-icons/bs";
import "./style.css";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import AlertPopUp from "../../AlertPopUp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import noData from "../../../base/Animation/No Data Found.json";
import Lottie from "react-lottie";
import { useApi } from "../../../base/Context/apiProvider";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import HostAudioCharge from '../../FormAlertPopUp/HostAudioCharge'

import moment from "moment";
import jsPDF from "jspdf";
import Button from "../../library/Button";
import { errorToast } from "../../../utils/toast";
import FormAlertPopUp from "../../FormAlertPopUp";
import HostFeeForm from "../../formComponents/HostFeeForm/HostFeeForm";

const HostRequestTable = () => {
  let navigate = useNavigate();
  const [hostrequest, setHostRequest] = useState([]);
  const [showAcceptedHostAlert, setShowAcceptedHostAlert] = useState(false);
  const [showRedirectAcceptedHostAlert, setShowRedirectAcceptedHostAlert] = useState(false);

  const [showRejectedHostAlert, setShowRejectedHostAlert] = useState();
  const [showRejectedReason, setShowRejectedReason] = useState();
  const [id, setId] = useState();
  const [rejectedReason, setRejectedReason] = useState("");
  const [showImageAlert, setShowImageAlert] = useState("");
  const [img, setImg] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    hostrequest.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "Host ID": item?._id,
        "Host Name": item?.name,
        Gender: item?.gender,
        "Date Of Birth": item?.dateOfBirth,
        Email: item?.email,
        "Mobile Number": item?.mobileNumber,
        "Pin Code": item?.pinCode,
        Country: item?.country,
        State: item?.state,
        Profession: item?.proffession,
        Bio: item?.addBio,
        "Image/Video": item?.presentationPic,
        "Created At": item?.createdAt,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [hostrequest]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const filename =`Host Request Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    const title = " Host Request List";
    const headers = [
      [
        "S.No",
        "Host ID",
        "Host Name",
        "Gender",
        "Date Of Birth",
        "Email",
        "Mobile Number",
        "Pin Code",
        "Country",
        "State",
        "Profession",
        "Bio",
        "Image/Video",
        "Created At",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Host ID"],
      item1["Host Name"],
      item1["Gender"],
      item1["Date Of Birth"],
      item1["Email"],
      item1["Mobile Number"],
      item1["Pin Code"],
      item1["Country"],
      item1["State"],
      item1["Profession"],
      item1["Bio"],
      item1["Image/Video"],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleReasonChange = (e) => {
    setRejectedReason(e.target.value);
  };

  useEffect(() => {
    getHostRequest();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const getHostRequest = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.PENDINGHOST,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setHostRequest(res?.result);
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setHostRequest([]);
        console.log(err);
        loader.showLoader(false);
      });
  };

  const handleAcceptedHostsAlert = (id) => {
    setShowAcceptedHostAlert(true);
    setId(id);
  };

  const handleAcceptedHostsAlertClose = () => {
    setShowAcceptedHostAlert(false);
  };

  const handleAcceptedHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REQUESTEDHOST,
      "PUT",
      {
        id: id,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowAcceptedHostAlert(false);
        setShowRedirectAcceptedHostAlert(true)
        // navigate(`/acceptedhost?appType=${searchParams.get("appType")}`);
      })
      .catch((err) => {
        errorToast(err?.message);
        setShowAcceptedHostAlert(false);
        loader.showLoader(false);
        setShowRedirectAcceptedHostAlert(true)
        console.log(err, "err--------");
      });
  };

  const handleRejectedHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REJECTHOST,
      "PUT",
      {
        id: id,
        rejectedReason: rejectedReason,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowRejectedHostAlert(false);
        navigate(`/rejectedhost?appType=${searchParams.get("appType")}`);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };

  const handleExportData = () => {
    const filename =`Host Request Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Host Request Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleRejectedHostsAlert = (id) => {
    setShowRejectedHostAlert(true);
    setId(id);
  };
  const handleRedirectHostAlert=(id)=>{
    setShowRedirectAcceptedHostAlert(true)
  }

  const handleRejectedHostsAlertClose = () => {
    setShowRejectedHostAlert(false);
    navigate("/hostrequest");
  };

  const handleAcceptedHostCancel = () => {
    setShowAcceptedHostAlert(false);
    navigate("/hostrequest");
  };

  const handleRejectedReasonAlert = () => {
    setShowRejectedReason(true);
  };

  const handleRejectedReasonAlertClose = () => {
    setShowRejectedReason(false);
  };

  const handleRejectedReasonCancel = () => {
    setShowRejectedReason(false);
    navigate("/hostrequest");
  };

  const handleRejectedReason = () => {
    setShowRejectedReason(true);
    setShowRejectedHostAlert(false);
  };

  const handleImageAlert = (img) => {
    console.log("1234", img);
    setShowImageAlert(true);
    setImg(img);
  };

  const handleImageAlertClose = () => {
    setShowImageAlert(false);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const GotoAcceptedHost=()=>{
    navigate(`/acceptedhost?appType=${searchParams.get("appType")}`)
  }

  return (
    <>
      <SearchInput
        onChange={handleText}
        value={value}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="host_report_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
{/* <HostFeeForm /> */}
      <div className="host__request__container web_table_container">
        <div className="table_parent_box">
          <table className="host__request__table">
            <thead>
              <th className="host__request__header">S.No.</th>
              <th className="host__request__header">Host ID</th>
              <th className="host__request__header">Name</th>
              <th className="host__request__header">Gender</th>
              <th className="host__request__header">Date Of Birth</th>
              <th className="host__request__header">Email</th>
              <th className="host__request__header">Mobile Number</th>
              <th className="host__request__header">Pin Code</th>
              <th className="host__request__header">Country</th>
              <th className="host__request__header">State</th>
              <th className="host__request__header">Profession</th>
              <th className="host__request__header">Bio</th>
              <th className="host__request__header">Image/Video</th>
              <th className="host__request__header">Created At</th>
              <th className="host__request__header">Action</th>
            </thead>
            <tbody>
              {hostrequest?.length > 0
                ? hostrequest.map((data, index) => {
                    return (
                      <tr>
                        <td className="host__request__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        <td className="host__request__data">{data?.userId}</td>
                        <td className="host__request__data">{data?.name}</td>
                        <td className="host__request__data">{data?.gender}</td>
                        <td className="host__request__data">
                          {data?.dateOfBirth}
                        </td>
                        <td className="host__request__data">{data?.email}</td>
                        <td className="host__request__data">
                          {data?.mobileNumber}
                        </td>
                        <td className="host__request__data">{data?.pinCode}</td>
                        <td className="host__request__data">{data?.country}</td>
                        <td className="host__request__data">{data?.state}</td>
                        <td className="host__request__data">
                          {data?.proffession}
                        </td>
                        <td className="host__request__data">{data?.addBio}</td>
                        <td className="host__request__data">
                          <BsFillEyeFill
                            onClick={() => {
                              handleImageAlert(data?.presentationPic);
                            }}
                            className="host__request__eye__icon"
                          />
                        </td>
                        <td className="host__request__data">
                          {moment(data?.createdAt).format("DD/MM/YYYY, LT")}
                        </td>
                        <td className="host__request__data">
                          <div className="host__request__action__icons">
                            <TiTick
                              onClick={() => {
                                handleAcceptedHostsAlert(data?._id);
                              }}
                              className="host__request__accept__icon"
                            />
                            <RxCross2
                              onClick={() => {
                                handleRejectedHostsAlert(data?._id);
                              }}
                              className="host__request__reject__icon"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>

          {!hostrequest?.length ? (
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%" }}
              />
              <p className="host__no_data_found">No Data Found</p>
            </div>
          ) : null}
        </div>

        <AlertPopUp
          open={showAcceptedHostAlert}
          handleOpen={handleAcceptedHostsAlert}
          handleClose={handleAcceptedHostsAlertClose}
          header="Accept Host?"
          description="Are you sure you want to accept this host?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleAcceptedHost}
          onCancelClick={handleAcceptedHostCancel}
        />

        <AlertPopUp
          open={showRejectedHostAlert}
          handleOpen={handleRejectedHostsAlert}
          handleClose={handleRejectedHostsAlertClose}
          header="Reject Host?"
          description="Are you sure you want to reject this Host request?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={handleRejectedHostsAlertClose}
          onSubmitClick={handleRejectedReason}
        />
           <FormAlertPopUp
        open={showRedirectAcceptedHostAlert}
        handleOpen={handleRedirectHostAlert}
        onRequestClose={()=>setShowRedirectAcceptedHostAlert(false)}
      >
        <HostFeeForm id={id} setShowRedirectAcceptedHostAlert={setShowRedirectAcceptedHostAlert} GotoAcceptedHost={GotoAcceptedHost} />
      </FormAlertPopUp>
       
        <AlertPopUp
          open={showRejectedReason}
          handleOpen={handleRejectedReasonAlert}
          handleClose={handleRejectedReasonAlertClose}
          header="Rejected Reason"
          description="Choose a reason to reject this user request"
          submitText="Submit"
          cancelText="Cancel"
          onSubmitClick={handleRejectedHost}
          onCancelClick={handleRejectedReasonCancel}
          textField={true}
          reason={rejectedReason}
          handleReasonChange={handleReasonChange}
          maxlength="50"
        />

        <ImagePopUpModal
          open={showImageAlert}
          handleOpen={handleImageAlert}
          handleClose={handleImageAlertClose}
          images={img}
        />
      </div>
      {hostrequest?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[5, 10, 15, 20]}
        />
      ) : null}
    </>
  );
};

export default HostRequestTable;
