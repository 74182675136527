import "./style.css";
import { useEffect, useRef, useState } from "react";
import { FaUserTie } from "react-icons/fa6";
import PopMenu from "../PopUpMenu";
import { IoNotifications } from "react-icons/io5";
import Breadcrumbs from "../Breadcrumbs";
import PopUpNotification from "../PopUpNotification";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";

const Navbar = () => {
  const [popOpen, setPopOpen] = useState(false);
  const [popOpenNotify, setPopOpenNotify] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const myRef = useRef();
  const apiProvider = useApi();
  const searchParams=useParams()
  console.log(apiProvider);
  const appType = sessionStorage.getItem("selectedType")
  useEffect(() => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ALLNOTIFICATIONS,
      "POST",
      {}
    )
      .then((res) => {
        console.log(res);
        setNotifications(res?.result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handleNotificationClick = (id) => {
    console.log("abcd", id);
    setPopOpenNotify(!popOpenNotify);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ALLNOTIFICATIONS,
      "POST",
      {}
    )
      .then((res) => {
        console.log(res);
        setNotifications(res?.result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleProfileClick = () => {
    setPopOpen(!popOpen);
  };

  const handleClickOutside = (e) => {
    let className = e.target.className;
    if (typeof className !== "string") {
      className = className.baseVal || "";
    }
    if (
      !myRef.current.contains(e.target) &&
      className !== "logout_para" &&
      !className.includes("notification_box")
    ) {
      setPopOpen(false);
      setPopOpenNotify(false);
    }
  };

  useEffect(() => {
    if (popOpen || popOpenNotify) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [popOpen, popOpenNotify]);

  return (
    <div>
      <nav className="navbar__container">
        <div className="navbar__profile_details">
          {/* <Breadcrumbs /> */}
          <div className="profile_details">
            <p style={{ fontSize: "28px", fontWeight: "bold" }}>{`HI ${appType==='catchwoo'?'CATCHWOO':appType==='host'?"HOST":'SHUDHLOVE'}`}</p>
            <p style={{ fontSize: "11px", fontWeight: "normal" }}>
              `Hello there! Welcome to <span>{` ${appType==='catchwoo'?'CATCHWOO':appType==='host'?"HOST":'SHUDHLOVE'}`}</span> , where convenience meets
              innovation.`
            </p>
          </div>
          <div className="navbar_right_content">
            <PopUpNotification
              notifications={notifications}
              handleNotificationClick={handleNotificationClick}
              popOpen={popOpenNotify}
            >
              <div className="notification_parent">
                {notifications?.length > 0 ? (
                  <div className="notification_navbar_count">
                    {notifications?.length > 0}
                  </div>
                ) : (
                  ""
                )}
                <div
                  ref={myRef}
                  className="navbar__country"
                  onClick={handleNotificationClick}
                >
                  <IoNotifications className="admin__image" />
                </div>
              </div>
            </PopUpNotification>
            <PopMenu popOpen={popOpen}>
              <div
                ref={myRef}
                className="navbar__country"
                onClick={handleProfileClick}
              >
                <FaUserTie className="admin__image" />
                {/* <img src={"dummyAccount"} alt={"image"} /> */}
              </div>
            </PopMenu>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
