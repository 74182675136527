import Button from "../../../library/Button";
import InputField from "../../../library/InputField";
import "./style.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { fetchDataFromAPI } from "../../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../../network/NetworkConfiguration";
import { useApi } from "../../../../base/Context/apiProvider";
import AlertPopUp from "../../../AlertPopUp";

const SuspendUser = () => {
  let navigate = useNavigate();
  const [endDate, setEndDate] = useState();
  const [suspendedReason, setSuspendedReason] = useState("");
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const apiProvider = useApi();
  const [error, setError] = useState({
    endDateError: "",
    reasonError: "",
  });
  const [showSuspendReason, setShowSuspendReason] = useState(false);

  const handleSuspendAccount = () => {
    const appType =
      searchParams.get("appType") === "catchwoo" ? "catchWoo" : "";
    if (validate1()) {
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.POSTSUSPENDEDUSER,
        "POST",
        {
          appType,
          id: id,
          suspensionEndDate: endDate,
          suspendedResion: suspendedReason,
        }
      )
        .then((res) => {
          // navigate(`/suspendusers/?type=user&id=${id}`);
          navigate(
            `${
              searchParams.get("appType") === "catchwoo"
                ? "/suspendeddata?appType=" + searchParams.get("appType")
                : "/suspendeddata"
            }`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSuspendBtn = () => {
    if (validate()) {
      setShowSuspendReason(true);
    }
  };

  const handleSuspendBtnClose = () => {
    setShowSuspendReason(false);
  };

  const validate = () => {
    let result = true;
    if (!endDate) {
      setError({ ...error, endDateError: "Enter valid date" });
      result = false;
    }
    return result;
  };

  const validate1 = () => {
    let result = true;
    if (!suspendedReason) {
      setError({ ...error, reasonError: "Enter a valid reason" });
      result = false;
    }
    return result;
  };

  const handleEndDate = (e) => {
    setError({ ...error, endDateError: "" });
    setEndDate(e.target.value);
  };
  return (
    <div className="user__management__suspend__user">
      <p className="suspend__user__heading">Suspend Account</p>
      <InputField
        onChange={handleEndDate}
        error={error.endDateError}
        type="date"
        style={{boxShadow:'none',borderBottom:'1px solid black',borderRadius:'0px'}}
        min={new Date().toISOString().split("T")[0]}
      />
      <br />
      <Button
        onClick={handleSuspendBtn}
        text="Submit"
        style={{  textAlign: "center",dispaly:'flex',alignItems:"center",justifyContent:'center' }}
      />

      <AlertPopUp
        open={showSuspendReason}
        handleOpen={handleSuspendBtn}
        handleClose={handleSuspendBtnClose}
        textField={true}
        header="Reason for suspension"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleSuspendAccount}
        onChangeField={(e) => {
          setSuspendedReason(e.target.value);
          setError({ ...error, reasonError: "" });
        }}
        error={error.reasonError}
        onCancelClick={handleSuspendBtnClose}
      />
    </div>
  );
};

export default SuspendUser;
