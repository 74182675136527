import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../base/Context/apiProvider";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import Button from "../../library/Button";
import jsPDF from "jspdf";
import moment from "moment";

const HostSuspiciousData = () => {
  const apiProvider = useApi();
  const [hostSuspiciousList, setHostSuspiciousList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [count, setCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const loader = useLoader();

  useEffect(() => {
    let array = [];
    hostSuspiciousList.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        Name: item?.name,
        Gender: item?.gender,
        "Ai Gender": item?.attributes[0]?.gender.Value,
        Age: item?.age,
        "Ai Age": `${
          item?.attributes[0] &&
          item?.attributes[0]?.ageRange?.Low
        } '-'
          ${
            item?.attributes[0] &&
            item?.attributes[0]?.ageRange?.High
          }`,
        Explicit: item?.isExplicit,
        Reason: item?.reason,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [hostSuspiciousList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Warned Users Report";
    const headers = [
      [
        "S.No.",
        "Name",
        "Gender",
        "Ai Gender",
        "Age",
        "Ai Age",
        "Explicit",
        "Reason",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Name"],
      item1?.Gender,
      item1["Ai Gender"],
      item1["Age"],
      item1["Ai Age"],
      item1["Explicit"],
      item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Suspicious Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchSuspiciousData();
  }, [apiProvider?.getApiUrl(), page, perPage, value, endDate]);
  const navigate = useNavigate();

  const fetchSuspiciousData = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SUSPICIOUSDATA,
      "POST",
      {
        startDate,
        endDate,
        type: "host",
        page,
        perPage,
        key: value,
      }
    )
      .then((res) => {
        loader.showLoader(false);

        console.log(res, "!!!!!!!!!!!!!!!!!!");
        setHostSuspiciousList(res?.hosts);
        setCount(res?.count);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setHostSuspiciousList([]);
        loader.showLoader(false);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleExportData = () => {
    const filename =`Suspicious Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Suspicious Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />

      <div className="suspicious_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>

        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="suspicious__data__container web_table_container">
        <table className="suspicious__data__table">
          <thead>
            <th className="suspicious__data__header">S.No</th>
            <th className="suspicious__data__header">Name</th>
            <th className="suspicious__data__header">Gender</th>
            <th className="suspicious__data__header">Ai Gender</th>
            <th className="suspicious__data__header">Age</th>
            <th className="suspicious__data__header">Ai Age</th>
            <th className="suspicious__data__header">Explicit</th>
            <th className="suspicious__data__header">Reason</th>
            <th className="suspicious__data__header">Action</th>
          </thead>
          <tbody>
            {hostSuspiciousList &&
              hostSuspiciousList?.length > 0 &&
              hostSuspiciousList.map((data, index) => {
                return (
                  <tr>
                    <td className="suspicious__data__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="suspicious__data__data">{data?.name}</td>
                    <td className="suspicious__data__data">{data?.gender}</td>
                    <td className="suspicious__data__data">
                      {data?.attributes[0]?.gender.Value}
                    </td>
                    <td className="suspicious__data__data">{data?.age}</td>
                    <td className="suspicious__data__data">
                      {data?.attributes[0]?.ageRange?.Low}-
                      {data?.attributes[0]?.ageRange?.High}
                    </td>
                    <td className="suspicious__data__data">
                      {data?.isExplicit ? "TRUE" : "FALSE"}
                    </td>
                    <td className="suspicious__data__data">{data?.reason}</td>
                    <td className="suspicious__data__data">
                      <AiFillEdit
                        onClick={() => {
                          navigate(`/acceptedhost/hostmanagement/${data._id}`);
                        }}
                        className="suspicious__edit__icon"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!hostSuspiciousList?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      {hostSuspiciousList && hostSuspiciousList.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={count}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default HostSuspiciousData;
