import { useContext, useState } from "react";
import Button from "../../../library/Button";
import InputField from "../../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../../network/NetworkConfiguration";
import Dropdown from "../../../library/Dropdown";
import { useApi } from "../../../../base/Context/apiProvider";
import { errorToast, successToast } from "../../../../utils/toast";
import { socketProvider } from "../../../../base/Context/socketProvider";
import { useSearchParams } from "react-router-dom";

const EditCoins = ({ id, onSubmit }) => {
  const [reason, setReason] = useState("");
  const [deductCoins, setDeductCoins] = useState("");
  const [plusMinus, setPlusMinus] = useState("");
  const { socketRef } = useContext(socketProvider);
  const [searchParams, setSearchParams] = useSearchParams();
  const apiProvider = useApi();
  const [error, setError] = useState({
    reasonError: "",
    selectionError: "",
    numberOfCoinsError: "",
  });

  const fetchBucket = () => {
    if (validate()) {
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.COINSDEDUCTION,
        "PUT",
        {
          id: id,
          reasonDeductionCoins: reason,
          deductionCoins: plusMinus,
          coinType: deductCoins,
        }
      )
        .then((res) => {
          socketRef.current.emit('force-refresh',{userId:id,appType:searchParams.get("appType")})
          onSubmit();
          setReason("");
          setDeductCoins("");
          setPlusMinus("");
          // successToast(res?.message);
          // setPlusMinus("");
        })
        .catch((err) => {
          errorToast(err?.message);
        });
    }
  };

  const handleDropdownChange = (e) => {
    setError({ ...error, selectionError: "" });
    setDeductCoins(e.target.value);
  };

  const handleReason = (e) => {
    setError({ ...error, reasonError: "" });
    setReason(e.target.value);
  };

  const handleAmount = (e) => {
    setError({ ...error, numberOfCoinsError: "" });
    setPlusMinus(e.target.value.replace(/\D/g, ""));
  };

  const validate = () => {
    let result = true;
    if (!reason) {
      setError({ ...error, reasonError: "Please enter a reason" });
      result = false;
    } else if (!deductCoins) {
      setError({ ...error, selectionError: "Please select any value" });
      result = false;
    } else if (!plusMinus) {
      setError({ ...error, numberOfCoinsError: "Please enter a value" });
      result = false;
    }
    return result;
  };

  return (
    <div className="edit_coins-container">
      <h5 className="edit__coins__heading">Edit Coins</h5>
      <InputField
        value={reason}
        placeholder="Reason for coin Deduction/Increment"
        onChange={handleReason}
        error={error.reasonError}
      />
      <Dropdown
        onChange={handleDropdownChange}
        error={error.selectionError}
        dropdownStyle={{width:"100%"}}
        options={[
          { name: "--Select--", value: "" },
          { name: "increment", value: "increment" },
          { name: "decrement", value: "decrement" },
        ]}
        style={{
          backgroundColor: "transparent",width:'100%'
        }}
        value={deductCoins}
      />
      <InputField
        value={plusMinus}
        type="text"
        placeholder="Number of Coins"
        maxlength="5"
        onChange={handleAmount}
        error={error.numberOfCoinsError}
      />
      <br/>
      <Button onClick={fetchBucket} className="edit__coin__btn" text="Submit" />
    </div>
  );
};

export default EditCoins;
