import { AiFillDelete, AiFillEye } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import AlertPopUp from "../../AlertPopUp";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Lottie from "react-lottie";
import Pagination from "../../Pagination";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImagePopUpModal from "../../ImagePopUpModal";
import Button from "../../library/Button";

const NotificationTable = ({ endDate, startDate, setArray }) => {
  const [getNotification, setGetNotification] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState("");
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [image, setImage] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const loader = useLoader();
  const apiProvider = useApi();
  const navigate = useNavigate();
  const handleDeleteNotification = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteNotificationClose = () => {
    setShowDeleteAlert(false);
  };

  const handleImageClick = (image) => {
    setImage(image);
    setShowImage(true);
  };

  const handleImageClickClose = () => {
    setShowImage(false);
  };

  useEffect(() => {
    fetchNotification();
  }, [page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchNotification = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETNOTIFICATION,
      "POST",
      {
        page,
        startDate,
        endDate,
        perPage,
        to: searchParams.get("appType") === "host" ? "hosts" : "users",
      }
    )
      .then((res) => {
        setGetNotification(res.result);
        setArray(res?.result);
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setGetNotification([]);
        loader.showLoader(false);
      });
  };

  const handleNotification = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETENOTIFICATION +
        `/${id}`,
      "DELETE"
    )
      .then((res) => {
        loader.showLoader(false);

        console.log(res);
        setShowDeleteAlert(false);
        successToast(res.message);
        fetchNotification();
      })
      .catch((err) => {
        loader.showLoader(false);

        errorToast(err.message);
        console.log(err);
      });
  };

  // const searchIcon = () => {
  //   return <FiSearch />;
  // };

  return (
    <>
      <div className="notification__table__container web_table_container">
        {/* <div className="banner__search__btn">
          <SearchInput placeholder="Search" icon={searchIcon()} />
        </div> */}
        <table className="notification__table__table">
          <thead>
            <th className="notification__table__header">S.No.</th>
            <th className="user__notification__header"> Send To</th>
            <th className="user__notification__header">Notification Type</th>
            <th className="notification__table__header">Title</th>
            <th className="notification__table__header">Message</th>
            <th className="notification__table__header">Image</th>
            <th className="notification__table__header">Opened By</th>
            <th className="notification__table__header">Bounce Back</th>
            <th className="notification__table__header">Action</th>
          </thead>
          <tbody>
            {getNotification &&
              getNotification?.length > 0 &&
              getNotification.map((data, index) => {
                return (
                  <tr>
                    <td className="notification__table__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="notification__table__data">{data?.to}</td>
                    <td className="notification__table__data">
                      {data?.notificationType}
                    </td>
                    <td className="notification__table__data">{data?.title}</td>
                    <td className="notification__table__data">{data?.body}</td>
                    <td className="notification__table__data">
                      <AiFillEye
                        className="notification__eye__icon"
                        onClick={() => handleImageClick(data?.imageUrl)}
                      />
                    </td>

                    <td
                      className="notification__table__data notification__open"
                      onClick={() => {
                        console.log("ON click notification: ", data?.count);
                        if (data?.count) {
                          const appType = searchParams.get("appType");
                          navigate(
                            `${
                              appType
                                ? "/openedby?appType=" +
                                  `${appType}&id=${data?._id}`
                                : `/openedby?id=${data?._id}`
                            }`
                          );
                        }
                      }}
                    >
                      {data?.count}
                    </td>

                    <td
                      className="notification__table__data notification__open"
                      onClick={() =>
                        navigate(
                          data?.failedHost?.length || data?.failedUser?.length
                            ? `${
                                searchParams.get("appType")
                                  ? "/bounceback?appType=" +
                                    `${searchParams.get("appType")}&id=${
                                      data?._id
                                    }`
                                  : `/bounceback?id=${data?._id}`
                              }`
                            : ""
                        )
                      }
                    >
                      {searchParams.get("appType") === "host"
                        ? data?.failedHost?.length
                        : data?.failedUser?.length}
                    </td>

                    <td className="notification__table__data">
                      <AiFillDelete
                        onClick={() => {
                          handleDeleteNotification(data?._id);
                        }}
                        className="notification__table__delete__icon"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!getNotification?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}

        <ImagePopUpModal
          open={showImage}
          handleClose={handleImageClickClose}
          img={image}
        />

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteNotification}
          handleClose={handleDeleteNotificationClose}
          header="Delete Alert"
          description="Are you sure you want to delete this notification?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleNotification}
          onCancelClick={handleDeleteNotificationClose}
        />
      </div>
      {getNotification && getNotification.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default NotificationTable;
