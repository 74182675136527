import { BsFillEyeFill } from "react-icons/bs";
import "./style.css";
import { AiFillEdit, AiTwotoneDelete } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import AlertPopUp from "../../AlertPopUp";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import moment from "moment";
import ImagePopUpModal from "../../ImagePopUpModal";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { Modal } from "../../../base/Context/modalProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import UserLikedProfile from "../UserLikedProfile";

const MomentTable = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [showDeleteAlert, setShowDeleteAlert] = useState();
  const [getUserMoment, setGetUserMoment] = useState([]);
  const [momentId, setMomentId] = useState("");
  const [showImageAlert, setShowImageAlert] = useState("");
  const [img, setImg] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [reason, setReason] = useState("");
  const [showDeleteReason, setShowDeleteReason] = useState(false);
  const [showLikedProfile, setShowLikedProfile] = useState(false);
  const apiProvider = useApi();

  const loader = useLoader();
  const modalProvider = useContext(Modal);

  useEffect(() => {
    let array = [];
    getUserMoment.map((item,index) => {
      let obj = {
        "S.No":index+1,
        Name: item?.userId?.name,
        Caption: item?.subject,
        Likes: item?.likes,
        "Image/Video": item?.postImage,
        " Created At": moment(item?.postDate).format("DD/MM/yyyy ,LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getUserMoment]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Moments";
    const headers = [
      ["S.NO", "Name", "Caption", "Likes", "Image/Video", "Created At"],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Name"],
      item1["Caption"],
      item1["Likes"],
      item1["Image/Video"],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);

    doc.setFontSize(15);
  };

  const handleImageAlert = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleImageAlertClose = () => {
    setShowImageAlert(false);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteAlert(false);
    navigate("/moment");
  };

  useEffect(() => {
    fetchUserMoment();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate, startDate]);

  const fetchUserMoment = () => {
    // loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERMOMENT,
      "POST",
      {
        userId: id,

        key: value,
        page,
        startDate,
        endDate,
        perPage,
      }
    )
      .then((res) => {
        // loader.showLoader(false);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
        setGetUserMoment(res.result);
      })
      .catch((err) => {
        console.log(err);
        // loader.showLoader(false);
        setGetUserMoment([]);
      });
  };

  const handleOnClickAlert = (id) => {
    setShowDeleteAlert(true);
    setMomentId(id);
  };

  const handleDeleteReason = () => {
    setShowDeleteReason(true);
  };

  const handleDeleteApi = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEUSERMOMENT +
        `/${momentId}`,
      "POST",
      {
        resion: reason,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteReason(false);
        setShowDeleteAlert(false);
        successToast(res.message);
        fetchUserMoment();
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
        errorToast(err.message);
      });
  };

  const handleDeleteReasonClose = () => {
    setShowDeleteReason(false);
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleLikesPost = (momentId) => {
    setShowLikedProfile(true);
    setMomentId(momentId);
  };

  const handleLikesPostClose = () => {
    setShowLikedProfile(false);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="moment_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="moment__container web_table_container">
        <table className="moment__table__container">
          <thead>
            <th className="moment__table__head">S.No</th>
            {!id && <th className="moment__table__head">Name</th>}
            <th className="moment__table__head">Caption</th>
            <th className="moment__table__head">Likes</th>
            <th className="moment__table__head">Image/Video</th>
            {/* <th className="moment__table__head">Post Type</th> */}
            <th className="moment__table__head">Created At</th>
            <th className="moment__table__head">Action</th>
          </thead>
          <tbody>
            {getUserMoment.length > 0
              ? getUserMoment.map((data, index) => {
                  return (
                    <tr>
                      <td className="moment__table__body">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {!id && (
                        <td className="moment__table__body">
                          <div
                            className="feedback__table__comment"
                            onClick={
                              data?.userId?.name.length > 12
                                ? () =>
                                    modalProvider.handleCommentClick(
                                      data?.userId?.name,
                                      "Name"
                                    )
                                : () => {}
                            }
                          >
                            {data?.userId?.name}
                          </div>
                        </td>
                      )}
                      <td className="moment__table__body">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.subject.length > 12
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.subject,
                                    "Caption"
                                  )
                              : () => {}
                          }
                        >
                          {data?.subject}
                        </div>
                      </td>
                      <td
                        className="moment__table__body"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLikesPost(data?._id)}
                      >
                        {data?.likes}
                      </td>

                      <td className="moment__table__body">
                        {data?.postImage && (
                          <BsFillEyeFill
                            onClick={() => {
                              handleImageAlert(data?.postImage);
                            }}
                            className="moment__table__body__eye_icon"
                          />
                        )}
                      </td>
                      {/* <td className="moment__table__body">{data?.postTypes}</td> */}
                      <td className="moment__table__body">
                        {moment(data?.postDate).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="moment__table__body">
                        {/* <AiFillEdit className="moment__table__edit_icon" /> */}
                        <AiTwotoneDelete
                          onClick={() => {
                            handleOnClickAlert(data._id);
                          }}
                          className="moment__table__delete_icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!getUserMoment.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="host__no_data_found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {getUserMoment.length ? (
        <UserLikedProfile
          open={showLikedProfile}
          handleClose={handleLikesPostClose}
          id={momentId}
        />
      ) : (
        ""
      )}

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Moment?"
        description="Are you sure you want to delete this Moment?"
        submitText="Yes"
        onCancelClick={handleDeleteCancel}
        onSubmitClick={handleDeleteReason}
        cancelText="No"
      />

      <AlertPopUp
        open={showDeleteReason}
        handleOpen={handleDeleteReason}
        handleClose={handleDeleteReasonClose}
        submitText="Yes"
        cancelText="No"
        textField={true}
        onChangeField={(e) => setReason(e.target.value)}
        header="Add a Reason"
        description="Add a reason as to why are you deleting this post?"
        onSubmitClick={handleDeleteApi}
        onCancelClick={handleDeleteReasonClose}
      />

      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleImageAlertClose}
        img={img}
      />

      {getUserMoment && getUserMoment.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default MomentTable;
