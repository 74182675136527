import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Button from "../../components/library/Button";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import { useSearchParams } from "react-router-dom";
import { useLoader } from "../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import moment from "moment";

const CoinHistory = () => {
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [getCoinHistory, setGetCoinHistory] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();
  const [exportAllData, setExportAllData] = useState([]);

  useEffect(() => {
    let array = [];
    getCoinHistory.map((item,index) => {
      let obj = {
        "S.No":index+1,

        "User ID": item?.u,
        "User Name": item?.userName,
        "Host ID": item?.h,
        "Host Name": item?.hostName,
        "Sticker Coin": item?.StickerCoins?item?.StickerCoins:0,
        "Gift Coin": item?.GiftCoins?item?.GiftCoins:0,
        "Audio Coin": item?.audioCoins?item?.audioCoins:0,
        "Post Coin": item?.postCoins?item?.postCoins:0,
        "Video Coin": item?.videoCoins?item?.videoCoins:0,
        "Total Coins Earned":
          searchParams.get("appType") === "host"
            ? "Total Coins Earned"
            : "Total Coins Spent",
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getCoinHistory]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Feedback List Report";
    const headers = [
      [
        "User ID",
        "User Name",
        "Host ID",
        "Host Name",
        "Sticker Coin",
        "Gift Coin",
        "Audio Coin",
        "Video Coin",
        "Post Coin",
        // "Total Coins Earned",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1) => [
      item1["User ID"],
      item1["User Name"],
      item1["Host ID"],
      item1["Host Name"],
      item1["Sticker Coin"],
      item1["Gift Coin"],
      item1["Audio Coin"],
      item1["Video Coin"],
      item1["Post Coin"],
      // item1["Total Coins Earned"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Coin History Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  useEffect(() => {
    fetchCoinHistory();
  }, [apiProvider?.apiUrl, value, page, perPage, endDate]);

  const fetchCoinHistory = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        (searchParams.get("appType") === "host" ||
        searchParams.get("appType") === "catchwoo"
          ? NetworkConfiguration.GETCOINHISTORYHOST
          : NetworkConfiguration.GETCOINHISTORY),
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setGetCoinHistory(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setGetCoinHistory([]);
        console.log(err);
      });
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Coin History Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Coin History Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        value={value}
        icon={searchIcon()}
        placeholder="Search"
      />
      <div className="coin_history_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        {/* <button>Search</button> */}
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="coin_history_container web_table_container">
        <table className="coin_history_table">
          <thead>
            <th className="coin_history_header">S.No.</th>
            <th className="coin_history_header">User ID</th>
            <th className="coin_history_header">User Name</th>
            <th className="coin_history_header">Host ID</th>
            <th className="coin_history_header">Host Name</th>
            <th className="coin_history_header">Sticker Coin</th>
            <th className="coin_history_header">Gift Coin</th>
            <th className="coin_history_header">Audio Coin</th>
            <th className="coin_history_header">Post Coin</th>
            <th className="coin_history_header">Video Coin</th>
            {searchParams.get("appType") === "host" ? (
              <th className="coin_history_header">Total Coins Earned</th>
            ) : (
              <th className="coin_history_header">Total Coins Spent</th>
            )}
          </thead>
          <tbody>
            {getCoinHistory && getCoinHistory?.length
              ? getCoinHistory?.map((data, index) => {
                  return (
                    <tr>
                      <td className="coin_history_data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="coin_history_data">{data?.u}</td>
                      <td className="coin_history_data">{data?.userName}</td>
                      <td className="coin_history_data">{data?.h}</td>
                      <td className="coin_history_data">{data?.hostName}</td>
                      <td className="coin_history_data">
                        {data?.StickerCoins ? data?.StickerCoins : "0"}
                      </td>
                      <td className="coin_history_data">
                        {data?.GiftCoins ? data?.GiftCoins : "0"}
                      </td>
                      <td className="coin_history_data">
                        {data?.audioCoins ? data?.audioCoins : "0"}
                      </td>
                      <td className="coin_history_data">
                        {data?.postCoins ? data?.postCoins : "0"}
                      </td>
                      <td className="coin_history_data">
                        {data?.videoCoins ? data?.videoCoins : "0"}
                      </td>
                      <td className="coin_history_data">
                        {(Number(data?.StickerCoins) || 0) +
                          (Number(data?.GiftCoins) || 0) +
                          (Number(data?.audioCoins) || 0) +
                          (Number(data?.videoCoins) || 0) +
                          (Number(data?.postCoins) || 0)}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>

        {!getCoinHistory?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : (
          ""
        )}
      </div>

      {getCoinHistory.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default CoinHistory;
