import "./style.css";
import { Box, Modal } from "@mui/material";
// import TextArea from "../library/TextArea";

const FormAlertPopUp = ({
  open,
  handleOpen,
  // handleClose,
  header,
  description,
  submitText,
  cancelText,
  onSubmitClick,
  onCancelClick,
  modalOf,
  onRequestClose,
  children,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    height: "auto",
    border: "none",
    outline: "none",
    borderRadius: "15px",
    backgroundColor: "rgba(255,255,255, 0.3)",
    // bgcolor: "white",
    padding: "20px",
  };
  const styles = {
    backgroundColor: "white",
    borderRadius: "15px",
    padding:'10px'
  };
  // const theme = createTheme({
  //   components: {
  //     MuiBackdrop: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: 'rgba(0, 0, 0, 0.7)', // Custom backdrop color and opacity
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <Modal open={open} onClose={onRequestClose} closeAfterTransition>
      <Box sx={style}>
        <Box sx={styles}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default FormAlertPopUp;
