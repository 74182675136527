import moment from "moment";
import "./style.css";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";

const AllCallHistoryTable = ({
  callHistory,
  page,
  perPage,
  getSetValue,
  searchParams,
}) => {
  const formatVideoCallTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours > 0 ? `${hours < 10 ? "0" : ""}${hours}:` : ""}${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="web_table_container">
      <table className="top__talent__table">
        <thead>
          <th className="top__talent__header">S.No.</th>
          <th className="top__talent__header">Date</th>
          <th className="top__talent__header">User ID</th>
          <th className="top__talent__header">User Name</th>
          <th className="top__talent__header">Host ID</th>
          <th className="top__talent__header">Host Name</th>
          {searchParams.get("appType") === "host" ? (
            <th className="top__talent__header">App Type</th>
          ) : (
            ""
          )}
          {getSetValue === "Most Loved Call" ? (
            ""
          ) : (
            <th className="top__talent__header">Calling</th>
          )}
          {getSetValue === "Most Loved Call" ? (
            ""
          ) : (
            <th className="top__talent__header">Audio Coin Spent</th>
          )}
          {getSetValue === "Most Loved Call" ? (
            ""
          ) : (
            <th className="top__talent__header">Video Coin Spent</th>
          )}
          {getSetValue === "Most Loved Call" ? (
            <th className="top__talent__header">Audio Coins</th>
          ) : (
            <th className="top__talent__header">Call Type</th>
          )}
          {getSetValue === "Most Loved Call" ? (
            <th className="top__talent__header">Video Coins</th>
          ) : null}
          {/* <th className="top__talent__header">Total Coins</th> */}
          <th className="top__talent__header">Time Duration in sec</th>
          {/* <th className="top__talent__header">Created At</th> */}
        </thead>
        <tbody>
          {callHistory && callHistory?.length
            ? callHistory.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="top__talent__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="top__talent__data">
                      {moment(data?.createdAt).format("DD/MM/YYYY, LT")}
                    </td>
                    <td className="top__talent__data">
                      {data?.userId?.userId
                        ? data?.userId?.userId
                        : "Deleted Account"}
                    </td>
                    <td className="top__talent__data">
                      {data?.userId?.name
                        ? data?.userId?.name
                        : "Deleted Acount"}
                    </td>
                    <td className="top__talent__data">
                      {data?.targetId?.userId
                        ? data?.targetId?.userId
                        : "Deleted Account"}
                    </td>
                    <td className="top__talent__data">
                      {data?.targetId?.name
                        ? data?.targetId?.name
                        : "Deleted Account"}
                    </td>

                    {searchParams.get("appType") === "host" ? (
                      <td className="top__talent__data">
                        {data?.userId?.appType}
                      </td>
                    ) : (
                      ""
                    )}

                    {getSetValue === "Most Loved Call" ? (
                      ""
                    ) : (
                      <td className="top__talent__data">{data?.calling}</td>
                    )}
                    {getSetValue === "Most Loved Call" ? (
                      ""
                    ) : (
                      <td className="top__talent__data">
                        {data?.audioCoins ? data?.audioCoins : 0}
                      </td>
                    )}
                    {getSetValue === "Most Loved Call" ? (
                      ""
                    ) : (
                      <td className="top__talent__data">
                        {data?.videoCoins ? data?.videoCoins : 0}
                      </td>
                    )}

                    {getSetValue === "Most Loved Call" ? (
                      <td className="top__talent__data">
                        {data?.audioCoins ? data?.audioCoins : 0}
                      </td>
                    ) : (
                      <td className="top__talent__data">{data?.callType}</td>
                    )}
                    {getSetValue === "Most Loved Call" ? (
                      <td className="top__talent__data">
                        {data?.videoCoins ? data?.videoCoins : 0}
                      </td>
                    ) : null}

                    <td className="top__talent__data">
                      {formatVideoCallTime(
                        data?.total_minute ? data?.total_minute : ""
                      )}
                    </td>
                    {/* <td className="top__talent__data">
                      {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                    </td> */}
                  </tr>
                );
              })
            : ""}
        </tbody>
      </table>

      {!callHistory?.length ? (
        <div className="host__no__data__found__icon">
          <Lottie
            options={{ animationData: noData, loop: true }}
            style={{ width: "40%", height: "30%" }}
          />
          <p className="no__data__found">No Data Found</p>
        </div>
      ) : null}
    </div>
  );
};

export default AllCallHistoryTable;
