import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Dropdown from "../../library/Dropdown";

const BannerForm = ({
  onSubmit,
  edit,
  id,
  onClickEdit,
  fetchBannerList,
  data,
}) => {
  const [bannerName, setBannerName] = useState(data?.name);
  const [image, setImage] = useState("");
  const [bannerOptions, setBannerOptions] = useState([]);

  let [bannerType, setBannerType] = useState("");

  const [error, setError] = useState({
    nameError: "",
    imageUrlError: "",
    typeError: "",
  });
useEffect(()=>{
  setBannerType(data?.bannerType)
},[])
  const loader = useLoader();
  const apiProvider = useApi();

  const handleSetBannerName = (e) => {
    setError({ ...error, nameError: "" });
    setBannerName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""));
  };

  const handleSetBannerImage = (e) => {
    setImage(e.target.files[0]);
    setError({ ...error, imageUrlError: "" });
  };

  const handleBannerType = (e) => {
    setBannerType(e.target.value);
  };
  const fetchDropDownoptions = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETBANNERTYPE,
      "GET"
    )
      .then((res) => {
        loader.showLoader(false);
        setBannerOptions(res.result);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  useEffect(() => {
    fetchDropDownoptions();
  }, []);
  console.log(bannerType, "bannerType");
  const dropdownOptions =
    bannerOptions &&
    bannerOptions?.map((item) => ({
      name: item.bannerType,
      value: item.bannerType,
    }));

  const validate = () => {
    let result = true;
    if (!bannerType || bannerType === "" || bannerType === undefined) {
      setError({ ...error, typeError: "Select a banner type" });
      result = false;
    }
    if (!bannerName || bannerName.trim()?.length === 0) {
      setError({ ...error, nameError: "Enter a valid banner name" });
      result = false;
    } else if (image === "") {
      setError({ ...error, imageUrlError: "Image is required" });
      result = false;
    }
    return result;
  };

  const validate1 = () => {
    let result = true;
    if (!bannerType || bannerType === "" || bannerType === undefined) {
      setError({ ...error, typeError: "Select a banner type" });
      result = false;
    }
    if (
      !bannerName ||
      bannerName.trim()?.length === 0 ||
      bannerName?.length <= 3
    ) {
      setError({ ...error, nameError: "Enter a valid banner name" });
      result = false;
    }
    return result;
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (validate1()) {
      loader.showLoader(true);
      loader.showLoader(true);
      let data = new FormData();
      data.append("name", bannerName);
      data.append("image", image);
      // if (typeof bannerType == "undefined") {
      //   bannerType = "";
      // }
      data.append("bannerType", bannerType);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() +
          NetworkConfiguration.UPDATEBANNERNAME +
          `/${id}`,
        "PUT",
        data,
        { "Content-Type": "multipart/form-data" }
      )
        .then((res) => {
          loader.showLoader(false);
          // setBannerName(res?.data.name);
          fetchBannerList();
          successToast("Banner updated successfully");
          onClickEdit();
        })
        .catch((err) => {
          loader.showLoader(false);
        });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoader(true);
      let data = new FormData();
      data.append("name", bannerName);
      data.append("image", image);
      if (typeof bannerType == "undefined") {
        bannerType = "";
      }
      data.append("bannerType", bannerType);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDBANNER,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          loader.showLoader(false);
          setBannerName("");
          setImage(null);
          onSubmit();
          successToast("Banner added successfully");
        })
        .catch((err) => {
          loader.showLoader(false);

          errorToast(err);
        });
    }
  };

  return (
    <div className="banner__container">
      {edit ? (
        <h2 className="banner__heading">Update Banner</h2>
      ) : (
        <h2 className="banner__heading">Add Banner</h2>
      )}
      <form onSubmit={edit ? handleEdit : handleOnSubmit}>
        <div className="banner__fields__gap">
          <Dropdown
            value={bannerType}
            options={dropdownOptions}
            onChange={handleBannerType}
          />
          {error.typeError && <p className="error_alert">{error.typeError}</p>}
          <br />
          <InputField
            onChange={handleSetBannerName}
            value={bannerName}
            placeholder="Banner Name"
            error={error.nameError}
            maxlength="25"
          />
          <br />{" "}
          <InputField
            onChange={(e) => handleSetBannerImage(e)}
            type="file"
            error={error.imageUrlError}
            accept=".jpg , .jpeg , .png"
          />
          <p className="banner_para">
            Banner image should be 2000x600 to 2500x750 px
          </p>
          <br />
          <Button
            text={edit ? "Update" : "Submit"}
            onClick={edit ? handleEdit : handleOnSubmit}
            className="add__banner__button"
          />
        </div>
      </form>
    </div>
  );
};

export default BannerForm;
