import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import moment from "moment";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import AlertPopUp from "../../AlertPopUp";
import Button from "../../library/Button";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";

const AdminBlockHost = () => {
  const apiProvider = useApi();
  const navigate = useNavigate();
  const [adminBlockHost, setAdminBlockHost] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [id, setId] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let array = [];
    adminBlockHost.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "Host ID": item?.hostId?._id,
        "Host Name": item?.hostId?.name,
        "Block Reason": item?.blockReasion,
        "Blocked Date&Time": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        Status: item?.status,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [adminBlockHost]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Admin Block List Report";
    const headers = [
      ["Host ID", "Host Name", "Block Reason", "Blocked Date&Time"],
    ];

    const data = exportAllData.map((item1) => [
      item1["Host ID"],
      item1["Host Name"],
      item1["Block Reason"],
      item1["Blocked Date&Time"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Blocked Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`);
  };

  const handleDeleteAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  useEffect(() => {
    fetchBlockedHost();
  }, [apiProvider?.getApiUrl(), page, perPage, value, endDate]);

  const fetchBlockedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.BLOCKLIST,
      "POST",
      {
        blockType: "Host",
        startDate,
        endDate,
        page,
        perPage,
        key: value,
      }
    )
      .then((res) => {
        setAdminBlockHost(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setAdminBlockHost([]);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEBLOCKEDUSER +
        `/${id}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        setShowDeleteAlert(false);
        fetchBlockedHost();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Blocked Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportData = () => {
    const filename =`Blocked Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="moment_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="admin__block__container web_table_container">
        <table className="admin__block__table">
          <thead>
            <th className="admin__block__header">S.No.</th>
            <th className="admin__block__header">Host ID</th>
            <th className="admin__block__header">Host Name</th>
            <th className="admin__block__header">Block Reason</th>
            <th className="admin__block__header">Blocked Date&Time</th>
            <th className="admin__block__header">Status</th>
            <th className="admin__block__header">Action</th>
          </thead>
          <tbody>
            {adminBlockHost?.map((data, index) => {
              return (
                <tr>
                  <td className="admin__block__data">
                    {(page - 1) * perPage + index + 1}
                  </td>
                  <td className="admin__block__data">{data?.hostId!==null?data?.hostId.userId:'Deleted-user'}</td>
                  <td className="admin__block__data">{data?.hostId!==null?data?.hostId?.name:'Deleted-user'}</td>
                  <td className="admin__block__data">{data?.blockReasion}</td>
                  <td className="admin__block__data">
                    {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                  </td>
                  <td className="admin__block__data">{data?.status}</td>
                  <td className="admin__block__data">
                    <AiFillEdit
                      className="admin_block_edit_icon"
                      onClick={() => {
                        navigate(
                          `/acceptedhost/hostmanagement/${data?.hostId?._id}?appType=` +
                            searchParams.get("appType")
                        );
                      }}
                    />
                    <AiFillDelete
                      className="admin_block_delete_icon"
                      onClick={() => handleDeleteAlert(data?._id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!adminBlockHost?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      <AlertPopUp
        open={showDeleteAlert}
        handleClose={handleDeleteClose}
        handleOpen={handleDeleteAlert}
        header="Delete Alert"
        description="Are you sure you want to delete this block data?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={handleDeleteClose}
        onSubmitClick={handleDelete}
      />
      {adminBlockHost && adminBlockHost?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[5, 10, 25, 20]}
        />
      ) : null}
    </>
  );
};

export default AdminBlockHost;
