import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import EnterOtp from "../pages/EnterOtp";
import NewPassword from "../pages/NewPassword";
import Banner from "../pages/Banner";
import UserManagement from "../pages/UserManagement";
import Bucket from "../pages/Bucket";
import UserRequest from "../pages/UserRequest";
import Feedback from "../pages/UserFeedback";
import Moments from "../pages/Moments";
import Gifts from "../pages/Gift";
import Recharge from "../pages/Recharge";
import HostManagement from "../pages/HostManagement";
import AcceptedHost from "../pages/AcceptedHost";
import HostRequest from "../pages/HostRequest";
import RejectedHost from "../pages/RejectedHost";
import Report from "../pages/Report";
import SuspendedUsers from "../pages/SuspendedUsers";
import WarnedUsers from "../pages/WarnedUser";
import Leader from "../pages/Leader";
import Layout from "../components/Layout";
import UserFollowing from "../pages/Following";
import Coin from "../pages/Coin";
import UserCallHistory from "../components/Table/CallHistoryTable";
import PaymentHistory from "../pages/PaymentHistory";
import TopGrowing from "../pages/TopGrowing";
import TopTalentTable from "../components/Table/TopTalentTable";
import Sticker from "../pages/Sticker";
import Notification from "../pages/Notification";
import UserManagementOption from "../components/pages/userManagement/UserManagementOption";
import HostManagementOption from "../components/pages/hostManagement/HostManagementOption";
import EditProfile from "../pages/EditProfile";
import Earnings from "../pages/Earnings";
import SendNotification from "../pages/SendNotification";
import Interest from "../pages/Interest";
import SubAdminList from "../pages/subAdmin/list";
import AddSubAdmin from "../pages/subAdmin/add";
import EditSubAdmin from "../pages/subAdmin/edit";
import Flower from "../pages/Flower";
import SuspiciousData from "../pages/SuspiciousData";
import MainCallHistory from "../pages/MainCallHistory";
import ActiveUser from "../pages/ActiveUser";
import InactiveHost from "../pages/InactiveHost";
import InactiveUser from "../pages/InactiveUser";
import ActiveHost from "../pages/ActiveHost";
import AdminBlockList from "../pages/AdminBlockList";
import Snapshots from "../pages/Snapshots";
import OfflineUser from "../pages/OfflineUser";
import OfflineHost from "../pages/OfflineHost";
import OpenedBy from "../pages/OpenedBy";
import SendMails from "../pages/sendMails";
import BounceBack from "../pages/BounceBack";
import MailDetail from "../pages/sendMails/mailDetail";
import ContactForm from "../pages/ContactForm";
import ApkFile from "../pages/ApkFile";
import CoinHistory from "../pages/CoinHistory";
import DeletedAccount from "../pages/DeletedAccount";
import ApkList from "../pages/ApkList";
import HostStickerTable from "../components/Table/HostStickerTable";
import UserMomentTable from "../components/Table/UserMomentTable";
import DeleteMoments from "../pages/DeleteMoments";
import TotalHostEarning from "../pages/TotalHostEarning";
import TotalCoinsBought from "../pages/TotalCoinsBought";
import TotalRechargedAmount from "../pages/TotalRechargedAmount";
import Assist from "../pages/Assist";
import IndividualChat from "../pages/Assist/IndividualChat";
import LoaderProvider, { useLoader } from "./Context/loaderProvider";
import Dashboard from "../pages/Dashboard";
import ShareHistory from "../pages/ShareHistory";
const LazyDashboard = React.lazy(() => import("../pages/Dashboard"));

// import SubAdmin from "../pages/SubAdmin";

// import Coin from "../pages/Coin";

const Router = () => {
  const loader = useLoader();

  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
      <Route path="/enterotp" element={<EnterOtp />}></Route>
      <Route path="/newpassword/:email" element={<NewPassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <Layout>
            {/* <Suspense fallback={loader.showLoader(true)}>  */}
              {/* <LazyDashboard  /> */}
            {/* </Suspense> */}
            <Dashboard/>
          </Layout>
        }
      ></Route>
      <Route
        path="/banner"
        element={
          <Layout>
            <Banner />
          </Layout>
        }
      />
      <Route
        path="/usermanagement/:id"
        element={
          <Layout>
            <UserManagement />
          </Layout>
        }
      />
      <Route
        path="/shareHistory/:id"
        element={
          <Layout>
            <ShareHistory />
          </Layout>
        }
      />
      <Route
        path="/usermanagement/:selectedOption/:id"
        element={
          <Layout>
            <UserManagementOption />
          </Layout>
        }
      />
      <Route
        path="/bucket"
        element={
          <Layout>
            <Bucket />
          </Layout>
        }
      />

      <Route
        path="/deletedmoments"
        element={
          <Layout>
            <DeleteMoments />
          </Layout>
        }
      />
      <Route
        path="/chat/:id"
        element={
          <Layout>
            <IndividualChat />
          </Layout>
        }
      />

      {/* <Route
        path="/usermoment"
        element={
          <Layout>
            <UserMomentTable />
          </Layout>
        }
      /> */}

      <Route
        path="/report&spam"
        element={
          <Layout>
            <Report />
          </Layout>
        }
      />
      <Route path={`/allusers`} element={<UserRequest />} />
      <Route
        path="/userfollowing"
        element={
          <Layout>
            <UserFollowing />
          </Layout>
        }
      />
      <Route
        path="/acceptedhost/hostmanagement/:id"
        element={
          <Layout>
            <HostManagement />
          </Layout>
        }
      />
      <Route
        path="/hostmanagement/:selectedOption/:id"
        element={
          <Layout>
            <HostManagementOption />
          </Layout>
        }
      />
      <Route
        path="/acceptedhost"
        element={
          <Layout>
            <AcceptedHost />
          </Layout>
        }
      />

      {/* <Route
        path="/hoststickertable"
        element={
          <Layout>
            <HostStickerTable />
          </Layout>
        }
      /> */}
      <Route
        path="/rejectedhost"
        element={
          <Layout>
            <RejectedHost />
          </Layout>
        }
      />
      <Route
        path="/hostrequest"
        element={
          <Layout>
            <HostRequest />
          </Layout>
        }
      />
      <Route
        path="/warneddata"
        element={
          <Layout>
            <WarnedUsers />
          </Layout>
        }
      />

      <Route
        path="/bounceback"
        element={
          <Layout>
            <BounceBack />
          </Layout>
        }
      />
      <Route
        path="/leader"
        element={
          <Layout>
            <Leader />
          </Layout>
        }
      />

      <Route
        path="/apklist"
        element={
          <Layout>
            <ApkList />
          </Layout>
        }
      />

      <Route
        path="/deletedaccounts"
        element={
          <Layout>
            <DeletedAccount />
          </Layout>
        }
      />
      <Route
        path="/suspendeddata"
        element={
          <Layout>
            <SuspendedUsers />
          </Layout>
        }
      />
      <Route
        path="/feedback"
        element={
          <Layout>
            <Feedback />
          </Layout>
        }
      />
      <Route
        path="/assist"
        element={
          <Layout>
            <Assist />
          </Layout>
        }
      />
      <Route
        path="/gift"
        element={
          <Layout>
            <Gifts />
          </Layout>
        }
      />
      <Route
        path="sticker"
        element={
          <Layout>
            <Sticker />
          </Layout>
        }
      />
      <Route
        path="/coinrecharge"
        element={
          <Layout>
            <Coin />
          </Layout>
        }
      />
      <Route
        path="/snapshots"
        element={
          <Layout>
            <Snapshots />
          </Layout>
        }
      />

      <Route
        path="/contactform"
        element={
          <Layout>
            <ContactForm />
          </Layout>
        }
      ></Route>

      <Route
        path="/apkrequests"
        element={
          <Layout>
            <ApkFile />
          </Layout>
        }
      ></Route>
      {/* <Route path="/recharge" element={<Recharge />} /> */}
      <Route
        path="/moment"
        element={
          <Layout>
            <Moments />
          </Layout>
        }
      ></Route>
      <Route path="/paymenthistory" element={<PaymentHistory />} />
      <Route
        path="/usercallhistory"
        element={
          <Layout>
            <UserCallHistory />
          </Layout>
        }
      />
      <Route
        path="/topgrowing"
        element={
          <Layout>
            <TopGrowing />
          </Layout>
        }
      />

      <Route
        path="/suspiciousdata"
        element={
          <Layout>
            <SuspiciousData />
          </Layout>
        }
      />

      <Route
        path="/allcallhistory"
        element={
          <Layout>
            <MainCallHistory />
          </Layout>
        }
      />

      <Route
        path="/blockdata"
        element={
          <Layout>
            <AdminBlockList />
          </Layout>
        }
      />

      <Route
        path="/sendmails"
        element={
          <Layout>
            <SendMails />
          </Layout>
        }
      />

      <Route
        path="/openedby"
        element={
          <Layout>
            <OpenedBy />
          </Layout>
        }
      />

      <Route path="/toptalent" element={<TopTalentTable />} />
      <Route
        path="/notification"
        element={
          <Layout>
            <Notification />
          </Layout>
        }
      />

      <Route
        path="/coinHistory"
        element={
          <Layout>
            <CoinHistory />
          </Layout>
        }
      ></Route>
      <Route
        path="/editprofile"
        element={
          <Layout>
            <EditProfile />
          </Layout>
        }
      />
      <Route
        path="/earnings"
        element={
          <Layout>
            <Earnings />
          </Layout>
        }
      ></Route>
      <Route
        path="/sendnotification"
        element={
          <Layout>
            <SendNotification />
          </Layout>
        }
      ></Route>
      <Route
        path="/interest"
        element={
          <Layout>
            <Interest />
          </Layout>
        }
      ></Route>
      <Route
        path="/addSubAdmin"
        element={
          <Layout>
            <AddSubAdmin />
          </Layout>
        }
      ></Route>
      <Route
        path="/sub-Admin"
        element={
          <Layout>
            <SubAdminList />
          </Layout>
        }
      ></Route>
      <Route
        path="/editSubAdmin"
        element={
          <Layout>
            <EditSubAdmin />
          </Layout>
        }
      ></Route>

      <Route
        path="/flower"
        element={
          <Layout>
            <Flower />
          </Layout>
        }
      ></Route>

      <Route
        path="/mails/mailsList/mailDetails"
        element={
          <Layout>
            <MailDetail />
          </Layout>
        }
      ></Route>

      <Route
        path="/activeuser"
        element={
          <Layout>
            <ActiveUser />
          </Layout>
        }
      ></Route>

      <Route
        path="/offlineuser"
        element={
          <Layout>
            <OfflineUser />
          </Layout>
        }
      ></Route>

      <Route
        path="/activehost"
        element={
          <Layout>
            <ActiveHost />
          </Layout>
        }
      ></Route>

      <Route
        path="/offlinehost"
        element={
          <Layout>
            <OfflineHost />
          </Layout>
        }
      ></Route>

      <Route
        path="/inactiveUser"
        element={
          <Layout>
            <InactiveUser />
          </Layout>
        }
      ></Route>
      <Route
        path="/totalCoinsBought"
        element={
          <Layout>
            <TotalCoinsBought />
          </Layout>
        }
      ></Route>
      <Route
        path="/rechargedAmount"
        element={
          <Layout>
            <TotalRechargedAmount />
          </Layout>
        }
      ></Route>
      <Route
        path="/totalHostEarnings"
        element={
          <Layout>
            <TotalHostEarning />
          </Layout>
        }
      ></Route>
      <Route
        path="/inactivehost"
        element={
          <Layout>
            <InactiveHost />
          </Layout>
        }
      ></Route>
    </Routes>
  );
};

export default Router;
