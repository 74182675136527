import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "./style.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ResponsibilitiesDropdown({
  options,
  responsibilities,
  setResponsibilities,
  error,
  setText,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === "string" ? value.split(",") : value;
    const clickedItem = newValue.find(
      (item) => !responsibilities.includes(item)
    );
    const itemExists = (responsibilities || []).find(
      (item) =>
        item?.name === event.target.value[event.target.value.length - 1]?.name
    );
    console.log(
      responsibilities,
      event.target.value,
      event.target.value[event.target.value.length - 1],
      "itemmm",
      itemExists
    );
    if (itemExists) {
      const filtered = responsibilities.filter(
        (item) => item.name !== itemExists.name
      );
      console.log("here 1", filtered);
      setResponsibilities(filtered);
    } else {
      console.log("here 2");
      setResponsibilities([
        ...responsibilities,
        event.target.value[event.target.value.length - 1],
      ]);
    }
  };
  const selectedOptions = responsibilities?.map((item) => {
    return item?.name ? item?.name : item;
  });

  console.log("responsibilities", responsibilities);

  return (
    <>
      <div className="input_parent_div ">
        <label className="label_style" style={{ display: "block" }}>
          {setText}
        </label>
        <div className="input_wrapper select_checkbox_parent_wrapper">
          <FormControl className="select_checkbox_wrapper">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-simple-select-helper"
              multiple
              value={responsibilities}
              onChange={handleChange}
              renderValue={(selected) =>
                selected?.map((item) =>
                  item?.name ? item?.name + "," : item + ", "
                )
              }
              MenuProps={MenuProps}
              className="select_checkbox"
            >
              {options?.map((option) => (
                <MenuItem key={option?.name || option} value={option}>
                  <Checkbox
                    checked={
                      option?.name
                        ? selectedOptions?.indexOf(option?.name) > -1
                        : selectedOptions?.indexOf(option) > -1
                    }
                  />
                  <ListItemText
                    primary={
                      option?.name
                        ? option?.name?.replace("List", "")
                        : option.replace("List", "")
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <p className="input_error" style={{ display: error ? "flex" : "none" ,color:'red'}}>
          *{error}
        </p>
      </div>
    </>
  );
}
