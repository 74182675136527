import "./style.css";

const SecondaryButton = ({ text, style, onClick, isActive }) => {
  return (
    <button
      onClick={onClick}
      className="secondary__btn__container"
      style={
        isActive
          ? style
          : {
              background: "white",
              border: "1px solid #e3226f",
              color: "#e3226f",
            }
      }
    >
      <p className={isActive ? "active_btn__text" : "secondary__btn__text"}>
        {text}
      </p>
    </button>
  );
};

export default SecondaryButton;
