import { useEffect, useState } from "react";
import { useLoader } from "../../../base/Context/loaderProvider";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { useApi } from "../../../base/Context/apiProvider";
import { errorToast, successToast } from "../../../utils/toast";
import { checkLength } from "../../../utils/utility";

const HostAudioCharge = ({ id, getAcceptedHost, setShowAudioAlert }) => {
  const [audioCallFees, setAudioCallFees] = useState("");

const[error,setError]=useState()
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchHostAudioCharge();
  }, []);

  const fetchHostAudioCharge = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETHOSTAUDIOCHARGE +
        `/${id}`,
      "GET"
    )
      .then((res) => {
        setAudioCallFees(res?.result);
      })
      .catch((err) => {});
  };
  useEffect(()=>{
    if(audioCallFees!==''){
      setError('')
    }
  },[audioCallFees])
  
  const handleAudioCharges = () => {
    if(audioCallFees===''){
      setError("This field is required")
      return;
    }
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEAUDIOCALLCHARGE,
      "PUT",
      {
        id: id,
        audioCall_fees: audioCallFees,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        getAcceptedHost();
        setShowAudioAlert(false);
        successToast(res?.message);
      })
      .catch((err) => {
        errorToast(err.message);
        loader.showLoader(false);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length<e.target.maxLength) {
      setError("Only numbers are allowed and the length should not exceed 10 digits");
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="premium__coin__container" style={{width:'350px'}}>
      <h2 className="premium__coin__heading">Set Audio Charge(per second)</h2>
      <div className="premium__coin">
        <InputField
          value={audioCallFees}
          placeholder="Set Random Call Time in sec"
         type='number'
          onChange={(e) => setAudioCallFees(e.target.value.replace(/\D/g, ""))}
          onKeyDown={handleKeyDown}
          maxlength="5"
        />
        {error && (
          <p className="error_alert">{error}</p>
        )}
        <br />
        <Button
          onClick={handleAudioCharges}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default HostAudioCharge;
