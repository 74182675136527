import { useEffect, useState } from "react";
// import Pagination from "../../components/Pagination";
import SearchInput from "../../components/SearchInput";
import Dropdown from "../../components/library/Dropdown";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import AllCallHistoryTable from "../../components/Table/AllCallHistoryTable";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import Button from "../../components/library/Button";
import {
  exportToExcel,
  exportToCSV,
} from "react-easy-export/dist/react-easy-export";
import { useLoader } from "../../base/Context/loaderProvider";
import jsPDF from "jspdf";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const MainCallHistory = () => {
  const [getSetValue, setGetSetValue] = useState();
  const [callHistory, setCallHistory] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const apiProvider = useApi();
  const loader = useLoader();

  const onChangeDropdown = (e) => {
    setGetSetValue(e.target.value);
  };
  const formatVideoCallTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours > 0 ? `${hours < 10 ? "0" : ""}${hours}:` : ""}${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  // useEffect(() => {
  //   if (searchParams.get("appType") === "host") {
  //     setEndPoint(NetworkConfiguration.HOSTCALL);
  //   } else {
  //     setEndPoint(NetworkConfiguration.DROPPEDCALL);
  //   }
  // }, [searchParams]);

  useEffect(() => {
    switch (getSetValue) {
      case "Small Call": {
        console.log("apiProvider", apiProvider?.getApiUrl());
        fetchDataFromAPI(
          searchParams.get("appType") === "host"
            ? apiProvider?.getApiUrl() + NetworkConfiguration.HOSTCALL
            : apiProvider?.getApiUrl() + NetworkConfiguration.DROPPEDCALL,

          "POST",
          {
            startDate,
            endDate,
            key: "Small Call",
            search: value,
            page,
            perPage,
          }
        )
          .then((res) => {
            // loader.showLoader(false);
            setCallHistory(res?.result);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
            console.log("res", res);
          })
          .catch((err) => {
            // loader.showLoader(false);
            console.log(err);
            setCallHistory([]);
          });
        break;
      }

      case "Most Loved Call": {
        // loader.showLoader(true);
        fetchDataFromAPI(
          searchParams.get("appType") === "host"
            ? apiProvider?.getApiUrl() + NetworkConfiguration.HOSTCALL
            : apiProvider?.getApiUrl() + NetworkConfiguration.DROPPEDCALL,
          "POST",
          {
            startDate,
            endDate,
            search: value,
            key: "Most Loved Call",
            perPage,
            page,
          }
        )
          .then((res) => {
            // loader.showLoader(false);
            setCallHistory(res?.result);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
          })
          .catch((err) => {
            // loader.showLoader(false);
            console.log(err);
            setCallHistory([]);
          });
        break;
      }

      case "Long Call": {
        loader.showLoader(true);
        fetchDataFromAPI(
          searchParams.get("appType") === "host"
            ? apiProvider?.getApiUrl() + NetworkConfiguration.HOSTCALL
            : apiProvider?.getApiUrl() + NetworkConfiguration.DROPPEDCALL,
          "POST",
          {
            startDate,
            endDate,
            search: value,
            key: "Long Call",
            page,
            perPage,
          }
        )
          .then((res) => {
            loader.showLoader(false);
            setCallHistory(res?.result);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
          })
          .catch((err) => {
            loader.showLoader(false);
            console.log(err);
            setCallHistory([]);
          });
        break;
      }
      default: {
        // loader.showLoader(true);
        fetchDataFromAPI(
          searchParams.get("appType") === "host"
            ? apiProvider?.getApiUrl() + NetworkConfiguration.HOSTCALL
            : apiProvider?.getApiUrl() + NetworkConfiguration.DROPPEDCALL,
          "POST",
          {
            startDate,
            endDate,
            key: "Drop Call",
            page,
            search: value,
            perPage,
          }
        )
          .then((res) => {
            // loader.showLoader(false);
            setCallHistory(res?.result);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
          })
          .catch((err) => {
            // loader.showLoader(false);
            console.log(err);
            setCallHistory([]);
          });
        break;
      }
    }
  }, [getSetValue, apiProvider?.getApiUrl(), page, perPage, endDate, value]);

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    let array = [];
    callHistory.map((item,index) => {
      let obj = {
        "S.No":index+1,
        Date: moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "User ID": item?.userId?.userId?item?.userId?.userId:"Deleted Account",
        "User Name": item?.userId?.name?item?.userId?.name:'Deleted Account',
        "Host ID": item?.targetId?.userId?item?.targetId?.userId:"Deleted Account",
        "Host Name": item?.targetId?.name? item?.targetId?.name:'Deleted Account',
        "App Type":item?.userId?.appType,
        "Calling": item?.calling,
        "Audio Coin Spent": item?.audioCoins?item?.audioCoins:0,
        "Video Coin Spent": item?.videoCoins? item?.videoCoins:0,
        "Call Type": item?.callType,
        "Time Duration in sec":formatVideoCallTime( item?.total_minute?item?.total_minute:''),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [callHistory]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Call History Report";
    const headers = [
      [
        "S.no.",
        "Date",
        "User ID",
        "User Name",
        "Host ID",
        "Host Name",
        "App Type",
        "Calling",
        "Audio Coin Spent",
        "Video Coin Spent",
        "Call Type",
        "Time Duration in sec",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Date"],
      item1["User ID"],
      item1["User Name"],
      item1["Host ID"],
      item1["Host Name"],
      item1['App Type'],
      item1["Calling"],
      item1["Audio Coin Spent"],
      item1["Video Coin Spent"],
      item1["Call Type"],
      item1["Time Duration in sec"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Call History Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleExportData = () => {
    const filename =`Call History Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Call History Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />
      <div className="top__growing__dropdown">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Dropdown
          onChange={onChangeDropdown}
          options={[
            { name: "Drop Call", value: "Drop Call" },
            { name: "Small Call", value: "Small Call" },
            { name: "Long Call", value: "Long Call" },
            { name: "Most Loved Call", value: "Most Loved Call" },
          ]}
        ></Dropdown>
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export Excel" onClick={handleExportData} />
      </div>
      <>
        <AllCallHistoryTable
          callHistory={callHistory}
          page={page}
          getSetValue={getSetValue}
          perPage={perPage}
          searchParams={searchParams}
        />
      </>

      {callHistory && callHistory.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default MainCallHistory;
