import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { checkLength } from "../../../utils/utility";
import { toast } from "react-toastify";
import { successToast } from "../../../utils/toast";

export const SetRandomCallTime = ({ onSubmit }) => {
  const [randomCallTime, setRandomCallTime] = useState("");
  const [error, setError] = useState("");

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchRandomCallTime();
  }, []);

  const fetchRandomCallTime = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETRANDOMCALLTIME,
      "GET"
    )
      .then((res) => {
        loader.showLoader(false);

        setRandomCallTime(res.result);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  useEffect(() => {
    if (randomCallTime !== "") {
      setError("");
    }
  }, [randomCallTime]);

  const handleRandomCallTime = () => {
    if (randomCallTime === "") {
      setError("This field is required");
      return;
    }
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATERANDOMCALLTIME,
      "PUT",
      {
        randomCallSecondTime: randomCallTime,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        console.log(res,'resuuu')
        onSubmit();
        successToast(res?.data?.message);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length < e.target.maxLength) {
      setError(
        "Only numbers are allowed and the length should not exceed 10 digits"
      );
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="premium__coin__container" style={{ width: "350px" }}>
      <h2 className="premium__coin__heading">Set Random Call Time</h2>
      <div className="premium__coin">
        <InputField
          value={randomCallTime}
          placeholder="Set Random Call Time in sec"
          onChange={(e) => {
            setRandomCallTime(e.target.value);
          }}
          type="number"
          onKeyDown={handleKeyDown}
          maxlength="5"
        />
        {error && <p className="error_alert">{error}</p>}
        <br />
        <Button
          onClick={handleRandomCallTime}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  );
};
