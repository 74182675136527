import { useEffect, useState } from "react";
import EditCoins from "../../components/pages/bucket/EditCoins";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";
import { useLoader } from "../../base/Context/loaderProvider";
import moment from "moment";
import Coin from "../../base/Assets/walletCoin.png";
import { AiFillDelete } from "react-icons/ai";
import AlertPopUp from "../../components/AlertPopUp";
import { errorToast, successToast } from "../../utils/toast";
import { useApi } from "../../base/Context/apiProvider";

const Bucket = () => {
  const [bucket, setBucket] = useState([]);
  const { id } = useParams();
  const [amount, setAmount] = useState("");
  const [identity, setIdentity] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const apiProvider = useApi();
  const [getOneUserDetail, setGetOneUserDetail] = useState([]);

  const handleDeleteAlert = (identity) => {
    setShowDeleteAlert(true);
    setIdentity(identity);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const loader = useLoader();

  const handleCoin = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETBUCKETLIST,
      "POST",
      {
        userId: id,
      }
    )
      .then((res) => {
        console.log(res);
        setBucket(res?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBucketAmount();
    handleCoin();
  }, []);

  const fetchBucketAmount = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETAMOUNT + `/${id}`,
      "GET"
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setAmount(res.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const onSubmit = () => {
    handleCoin();
    fetchBucketAmount();
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEBUCKET +
        `/${identity}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        setShowDeleteAlert(false);
        handleCoin();
        successToast(res?.message);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err?.message);
      });
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  const getUserDetail = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.FINDONEUSER + `/${id}`,
      "GET"
    )
      .then((res) => {
        setGetOneUserDetail(res?.getOneUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="bucket_main_container">
      <div className="bucket__container">
        <div className="bucket__coin__amount">
          <div className="edit_coins_profile_img_container">
            <img
              src={
                getOneUserDetail?.profilePic ? getOneUserDetail?.profilePic : ""
              }
              alt="image"
              className="edit_coins_profile_img"
            />
          </div>
          
          <div className="bucket__coin">
            <p className="bucket__heading">{getOneUserDetail?.name?getOneUserDetail?.name:""}</p>
            <p>user</p>
            <p className="bucket__heading">Coins <span className="bucket__para">{amount}</span></p>
          </div>
        </div>
        <EditCoins id={id} onSubmit={onSubmit} />
      </div>

      <div className="bucket__table__container">
        <table className="bucket__table">
          <thead>
            <th className="bucket__table__header">S.No.</th>
            <th className="bucket__table__header">Total amount</th>
            <th className="bucket__table__header">
              Reason for increment/decrement
            </th>
            <th className="bucket__table__header">Deducted/Added Coins</th>
            <th className="bucket__table__header">Coin Type</th>
            <th className="bucket__table__header">Changed Amount</th>
            <th className="bucket__table__header">Created At</th>
            <th className="bucket__table__header">Action</th>
          </thead>
          <tbody>
            {bucket.map((data, index) => {
              return (
                <tr>
                  <td className="bucket__table__data">{index + 1}</td>
                  <td className="bucket__table__data">{data?.oldCoins}</td>
                  <td className="bucket__table__data">
                    {data?.reasonDeductionCoins}
                  </td>
                  <td className="bucket__table__data">
                    {data?.deductionCoins}
                  </td>
                  <td className="bucket__table__data">{data?.coinType}</td>
                  <td className="bucket__table__data">{data?.newCoins}</td>
                  <td className="bucket__table__data">
                    {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                  </td>
                  <td className="bucket__table__data">
                    <AiFillDelete
                      onClick={() => handleDeleteAlert(data?._id)}
                      className="bucket__delete"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Alert"
        description="Are you sure you want to delete this bucket row?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDelete}
        onCancelClick={handleDeleteAlertClose}
      />
    </div>
  );
};

export default Bucket;
