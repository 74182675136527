import "./style.css";

const NotificationCard = (props) => {
  return (
    <div className="notification__card__container" onClick={props.onClick}>
      <div className="notification__profile__image">
        <img className="notification__img" src={props.profile} alt="profile" />
      </div>

      <div className="notification_message_box">
        <div className="notification_content">
          <div className="notification_content_row">
            <p className="notification_title">{props.name}</p>
            <p>{props.time}</p>
          </div>
          <p className="notification_message">{props.message}</p>
        </div>

        {/* <div className="notification_feedback">{props.feedback}</div> */}
      </div>
    </div>
  );
};

export default NotificationCard;
