import { useNavigate } from "react-router-dom";
import InputField from "../../components/library/InputField";
import "./style.css";
import { useState } from "react";

import EnterOtp from "../EnterOtp";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../utils/toast";
// import { successToast } from "../../components/Toast";

const ForgotPassword = () => {
  const [enterOTP, setEnterOTP] = useState(true);
  const [email, setEmail] = useState("");
  const[disabled,setDisabled]=useState(false)
  const [error, setError] = useState({
    emailError: "",
  });

  const apiProvider = useApi();

  const handleEmail = (e) => {
    setError({ ...error, emailError: "" });
    setEmail(e.target.value);
  };

  const handleSentOtp = () => {
    setDisabled(true)
    if (validate()) {
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.FORGOTPASSWORD,
        "PUT",
        {
          email: email,
        }
      )
        .then((res) => {
          console.log(res, "res======");

          setEnterOTP(false);
          
          // successToast(res?.message);
        })
        .catch((err) => {
          errorToast(err.message);

          // setTimeout(()=>{
          //   setDisabled(false)
          // },2000)
          console.log(err, "error-------");
        });
    }
    setTimeout(()=>{
      setDisabled(false)
    },3000)
  };

  const validate = () => {
    let result = true;
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setError({ ...error, emailError: "Enter correct mail" });
      result = false;
    }
    return result;
  };

  let navigate = useNavigate();
  return (
    <div className="login__container">
      <div className="login__left_half">
        {enterOTP ? (
          <div className="login__content_container">
            <div>
              <h2 className="login__heading">Forgot Password</h2>
              <p>We'll sent you an OTP on this email</p>
            </div>
            <div className="otp__button">
              <InputField
                type="email"
                onChange={handleEmail}
                placeholder="Email Id"
                error={error.emailError}
              />
              <button disabled={disabled}className="forgot__otp" onClick={handleSentOtp}>
                Get OTP
              </button>
            </div>
            <div>
              <p
                className="back__to__login"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back To Login
              </p>
            </div>
          </div>
        ) : (
          <div>
            <EnterOtp email={email} />
          </div>
        )}
      </div>
      <div className="login__right_half"></div>
    </div>
  );
};

export default ForgotPassword;
