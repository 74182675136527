import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllowedItem } from "../edit";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import InputField from "../../../components/library/InputField";
import Button from "../../../components/library/Button";
import ResponsibilitiesDropdown from "../../../components/library/ResponsibilitiesDropdown";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useApi } from "../../../base/Context/apiProvider";
import { useSidebar } from "../../../base/Context/sidebarProvider";

import "./style.css";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";

export default function AddSubAdmin() {
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [Accessablity, setAccessability] = useState([]);
  // const [addSubAdmin, setAddSubAdmin] = useState({});
  const [passInput, setPassInput] = useState(true);
  const [passInputTwo, setPassInputTwo] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let [appType, setAppType] = useState("");
  const apiProvider = useApi();
  const sidebarProvider = useSidebar();
  const loader = useLoader();

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    responsibilities: "",
    accessbility: "",
  });

  const handleEye = () => {
    if (passInput) {
      return <FaEyeSlash />;
    } else {
      return <FaEye />;
    }
  };
  const handleType = () => {
    setPassInput(!passInput);
  };

  const handleEyeTwo = () => {
    if (passInputTwo) {
      return <FaEyeSlash />;
    } else {
      return <FaEye />;
    }
  };
  const handleTypeTwo = () => {
    setPassInputTwo(!passInputTwo);
  };

  const handleName = (e) => {
    setData({ ...data, name: e.target.value });
    setError({ ...error, name: "" });
  };
  const handleEmail = (e) => {
    setData({ ...data, email: e.target.value });
    setError({ ...error, email: "" });
  };
  const handlePassword = (e) => {
    setData({ ...data, password: e.target.value });
    setError({ ...error, password: "" });
  };
  const handleConfirmPassword = (e) => {
    setData({ ...data, confirmPassword: e.target.value });
    setError({ ...error, confirmPassword: "" });
  };

  useEffect(() => {
    if (searchParams.get("appType") === "host") {
      setAppType("cloneHost");
    } else if (searchParams.get("appType") === "catchwoo") {
      setAppType("catchwooUser");
    } else {
      setAppType("cloneUser");
    }
  }, [appType, searchParams]);

  const handleNavigate = () => {
    if (searchParams.get("appType") === "catchwoo") {
      navigate(`/sub-Admin?appType=` + searchParams.get("appType"));
    } else if (searchParams.get("appType") === "host") {
      navigate(`/sub-Admin?appType=` + searchParams.get("appType"));
    } else {
      navigate(`/sub-Admin`);
    }
  };

  //set side bar menu options for sub admin responsibilities
  useEffect(() => {
    setOptions(
      sidebarProvider?.sidebarContent.map((item) => ({
        name: item.label,
        accessType: [],
      }))
    );
  }, [sidebarProvider?.sidebarContent]);

  const handleAccess = (name, accessType) => {
    let updatedData = [];
    updatedData = responsibilities?.map((item, index) => {
      if (item?.name === name) {
        return {
          name: item.name,
          accessType: item?.accessType
            ? item?.accessType.includes(accessType)
              ? item?.accessType.filter((accessVal) => accessVal !== accessType)
              : [
                ...item?.accessType,
                accessType,
                ...(accessType === "edit" && !item?.accessType.includes("view") ? ["view"] : [])
              ]
            : accessType === "edit" ? ["view", accessType] : [accessType],
        };
      } else {
        return item;
      }
    });
    setResponsibilities(updatedData);
  };

  const handleRemove = (e) => {
    let newArray = responsibilities.filter((item) => {
      return item.name !== e.name;
    });
    setResponsibilities(newArray);
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Invalid Name" });
      result = false;
    } else if (!data.email||!data.email.match(/\S+@\S+\.\S+/)) {
      setError({ ...error, email: "Invalid Email" });
      result = false;
    } else if (!data.password||!validatePassword(data.password)) {
      setError({ ...error, password: "Invalid Password ,Password must be 8 charcters and include(a-z,A-Z,0-9,#@)" });
      result = false;
    } else if (!data.confirmPassword||data.confirmPassword!==data.password) {
      setError({ ...error, confirmPassword: "Passwords do not match" });
      result = false;
    } else if (!responsibilities.length) {
      setError({ ...error, responsibilities: "Invalid Responsibilities" });
      result = false;
    }else if (!Accessablity.length) {
      setError({ ...error, accessbility: "Invalid accessbility" });
      result = false;
    }

    return result;
  };
  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(password);
  };

  const handleAddSubAdmin = () => {
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDSUBADMIN,
        "POST",
        {
          name: data?.name,
          email: data?.email,
          password: data?.password,
          confirmPassword: data?.confirmPassword,
          adminType: Accessablity.map((access) => {
            return access.name;
          }),
          responsibility: responsibilities.map((responsibility) => {
            return { ...responsibility, appType };
          }),
        }
      )
        .then((res) => {
          // setAddSubAdmin(res?.result)
          handleNavigate();
          successToast(res?.message);
          loader.showLoader(false);
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err?.message);
          console.log("add subadmin", err);
        });
    }
  };

  useEffect(() => {
    if (responsibilities.length) {
      setError({ ...error, responsibilities: "" });
    }
  }, [responsibilities]);
  const AccessibilityOptions = [
    { name: "Shudhlove" },
    { name: "ShudhloveHost" },
    { name: "Catchwoo" },
  ];
  return (
    <div className="add_subAdmin_row">
      <div className="add_subAdmin_column">
        <div className="add_subadmin">
          <label className="add_subadmin_label">Name</label>
          <InputField
            //  label="Name"
            // placeholder="Name"
            type="text"
            onChange={handleName}
            error={error.name}
            value={data.name}
          />
        </div>
        <br />
        <div className="add_subadmin">
          <label className="add_subadmin_label">Email</label>

          <InputField
            // label="Email"
            placeholder=""
            type="text"
            onChange={handleEmail}
            error={error.email}
            value={data.email}
          />
        </div>
        <br />
        <div className="add_subadmin">
          <label className="add_subadmin_label">Password</label>
          <InputField
            // label="Password"
            placeholder=""
            type={passInput ? "password" : "text"}
            onChange={handlePassword}
            error={error.password}
            value={data.password}
            icon={handleEye()}
            onEyeClick={handleType}
          />
        </div>
        <br />
        <div className="add_subadmin">
          <label className="add_subadmin_label">Confirm Password</label>
          <InputField
            // label="Confirm Password"
            placeholder=""
            type={passInputTwo ? "password" : "text"}
            onChange={handleConfirmPassword}
            error={error.confirmPassword}
            value={data.confirmPassword}
            icon={handleEyeTwo()}
            onEyeClick={handleTypeTwo}
          />
        </div>
        <br />
        <br />
        <div>
          <ResponsibilitiesDropdown
            options={AccessibilityOptions}
            responsibilities={Accessablity}
            setResponsibilities={setAccessability}
            error={error.accessbility}
            setText={" Select Accessibility"}
          />
        </div>
        <br /> <br />
        <div>
          <ResponsibilitiesDropdown
            options={options}
            responsibilities={responsibilities}
            setResponsibilities={setResponsibilities}
            error={error.responsibilities}
            setText={" Select Responsibilities"}
          />
          <br />
        </div>
        <Button
          onClick={handleAddSubAdmin}
          text="Add Sub Admin"
          style={{
            textAlign: "center",
            border: "none",
          }}
          className="web_button"
        />
      </div>
      <div className="add_subAdmin_column">
        {responsibilities.length ? (
          <>
            <label className="label_style" style={{ display: "block" }}>
              Allowance
            </label>
            <div className="assign_responsibilities_box">
              {responsibilities.map((item, index) => {
                return (
                  <AllowedItem
                    item={item}
                    key={index}
                    handleAccess={handleAccess}
                    handleRemove={handleRemove}
                  />
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
