import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import moment from "moment";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../../components/library/Button";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import ApkFileForm from "../../components/formComponents/ApkAddForm";
import AlertPopUp from "../../components/AlertPopUp";
import { successToast } from "../../utils/toast";
import HostEditForm from "../../components/formComponents/HostEditForm";

const ApkList = () => {
  const apiProvider = useApi();
  const [page, setPage] = useState(1);
  const [editForm, setEditForm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showApkForm, setShowApkForm] = useState(false);
  const [deleteID, setDeleteId] = useState(null);
  const [apkData, setApkData] = useState([]);

  useEffect(() => {
    fetchApkData();
  }, [page, perPage]);

  const fetchApkData = () => {
    const appType =
      searchParams.get("appType") === "host" ? "host" : "shudhloveuser";
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.APKLIST,
      "POST",
      {
        appType,
        page,
        perPage,
      }
    )
      .then((res) => {
        setApkData(res?.result);
        console.log(res);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApkClick = () => {
    setShowApkForm(true);
  };

  const handleApkClickClose = () => {
    setShowApkForm(false);
  };
  const handleDeleteClick = (id) => {
    setShowDeleteAlert(true);
    setDeleteId(id);
  };
  const handleEditApk = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.EDITAPK + `/${deleteID}`,
      "PUT",
      {}
    )
      .then((res) => {
        fetchApkData();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteApk = (id) => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEAPK +
        `/${deleteID}`,
      "DELETE",
      { id }
    )
      .then((res) => {
        fetchApkData();
        setShowDeleteAlert(false)
        successToast(res?.message);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApkEdit = (id) => {
    setEditForm(true);
    setDeleteId(id);
  };
  return (
    <>
      <div className="apk_file_btn">
        <Button text="Add APK File" onClick={handleApkClick} />
      </div>
      <div className="apk_container web_table_container">
        <table className="apk_table">
          <thead>
            <th className="apk_header">S.No.</th>
            <th className="apk_header">Apk Name</th>
            <th className="apk_header">Apk File</th>
            <th className="apk_header">Apk Version</th>
            <th className="apk_header">Mandatory</th>

            <th className="apk_header">Created At</th>
            <th className="apk_header">Action</th>
          </thead>
          <tbody>
            {apkData &&
              apkData.length &&
              apkData.map((data, index) => {
                return (
                  <tr>
                    <td className="apk_data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="apk_data">{data?.name}</td>
                    <td className="apk_data">
                      {/* <a target="_blank" href={`${data?.apkFile}`} /> */}

                      <Link
                        to={data?.apkFile}
                        target="_blank"
                        rel="noopener noreffer"
                      >
                        <AiFillEye
                          style={{
                            cursor: "pointer",
                            color: "black",
                            fontSize: "20px",
                          }}
                        />
                      </Link>
                    </td>
                    <td className="apk_data">{data?.apkVersion}</td>
                    {console.log("mandatory1234", data.isMandatory)}
                    <td className="apk_data">{`${data.isMandatory}`}</td>

                    <td className="apk_data">
                      {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                    </td>
                    <td>
                      <AiFillEdit
                        onClick={() => {
                          handleApkEdit(data?._id);
                        }}
                        className="accepted__host__edit__icon"
                      />
                      <AiFillDelete
                        onClick={() => {
                          handleDeleteClick(data?._id);
                        }}
                        className="accepted__host__delete__icon"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!apkData.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      {apkData.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
      <FormAlertPopUp
        open={showApkForm}
        handleOpen={handleApkClick}
        onRequestClose={handleApkClickClose}
      >
        <ApkFileForm
          setShowApkForm={setShowApkForm}
          fetchApkData={fetchApkData}
        />
      </FormAlertPopUp>
      <FormAlertPopUp open={editForm} onRequestClose={()=>setEditForm(false)}>
        <ApkFileForm
          id={deleteID}
          setShowApkForm={setEditForm}
          editFile={true}
          fetchApkData={fetchApkData}
        />
      </FormAlertPopUp>
      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteClick}
        handleClose={() => setShowDeleteAlert(false)}
        header="Delete Accepted Host"
        description="Are you sure you want to delete this APK?"
        submitText="Yes"
        onCancelClick={() => setShowDeleteAlert(false)}
        cancelText="No"
        onSubmitClick={handleDeleteApk}
      />
    </>
  );
};

export default ApkList;
