import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HostManagementData } from "../../../../utils/hostManagementData";

const HostIconContainer = ({ id }) => {
  let navigate = useNavigate();
  const [hovereditem, setHoveredItem] = useState(null);
  const handleHoveredItem = (id) => {
    setHoveredItem(id);
  };
  return (
    // <div className="user__management__icon__container">
    <div className="user__management__icon__row">
      {HostManagementData?.map((data) => {
        return (
          <div
          style={{border:data?.name===''?'none':'',flex:data?.name===''?'0 1 calc(14.28% - 2px)':'',marginLeft:data?.name===''?'2px':''}}
            onMouseEnter={() => handleHoveredItem(data?.name===''?null:data?.name)}
            onMouseLeave={() => setHoveredItem(null)}
            className={data?.name===''?'':"icon__container"}
            onClick={() => {
              navigate(`/hostmanagement/${data?.name}/${id}`);
            }}
          >
            <div>
              <img
                className="icon"
                src={data?.name === hovereditem ? data.activeIcon : data?.icon}
                alt=""
              />
              <p
                className="icon__name"
                style={{ color: data?.name === hovereditem ? "white" : "" }}
              >
                {data?.name}
              </p>
            </div>
          </div>
        );
      })}
    </div>
    // </div>
  );
};

export default HostIconContainer;
