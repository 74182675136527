import { createContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { SOCKET_URL } from "../../network/NetworkConfiguration";

export const socketProvider = createContext();
const SocketProvider = ({ children }) => {
    const base=SOCKET_URL
  const TOKEN = localStorage.getItem("token");
  const socketRef = useRef(null);
  useEffect(() => {
    socketRef.current = io.connect(base, {
      auth: {
        token: TOKEN,
      },
    });

    
  }, []);
  

  return <socketProvider.Provider value={{socketRef}}>
    {children}
  </socketProvider.Provider>;

  //   return (
  //     // <socketContext.Provider value={{ socketRef, zegoRef }}>
  //     //   {children}
  //     // </socketContext.Provider>
  //     <>
  //       <Outlet context={[socketRef, zegoRef]} />
  //     </>
  //   );
};

export default SocketProvider;
