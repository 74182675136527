import { useParams } from "react-router-dom";
import "./style.css";
import MomentTable from "../../../Table/MomentTable";
import PaymentHistoryTable from "../../../Table/PaymentHistoryTable";

import CallHistory from "../../../Table/CallHistoryTable";
import FollowerTable from "../../../Table/FollowersTable";
import FollowingTable from "../../../Table/FollowingTable";
import UserBlockedList from "../../../Table/UserBlockList";
import UserReportTable from "../../../Table/UserReportTable";
import RechargeTable from "../../../Table/RechargeTable";
import FeedbackUserTable from "../../../Table/FeedbackTable";
import Bucket from "../../../../pages/Bucket";
import UserNotificationTable from "../../../Table/UserNotificationTable";
import UserGiftTable from "../../../Table/UserGiftTable";
import UserStickerTable from "../../../Table/UserStickerTable";
import { SuspendedSingleUser } from "../../../Table/SuspendedSingleUser";
import { WarnedSingleUser } from "../../../Table/WarnedSingleUser";

const UserManagementOption = () => {
  const { selectedOption } = useParams();

  const getOptionView = () => {
    switch (selectedOption) {
      case "feedback": {
        return <FeedbackUserTable />;
      }

      case "moment": {
        return <MomentTable />;
      }

      case "notification": {
        return <UserNotificationTable />;
      }
      case "gifts": {
        return <UserGiftTable />;
      }

      case "stickers": {
        return <UserStickerTable />;
      }

      case "bucket": {
        return <Bucket />;
      }

      // case "recharge": {
      //   return <RechargeTable />;
      // }

      case "report": {
        return <UserReportTable />;
      }

      case "payment history": {
        return <PaymentHistoryTable />;
      }

      case "call history": {
        return <CallHistory />;
      }

      case "followers": {
        return <FollowerTable />;
      }

      case "following": {
        return <FollowingTable />;
      }

      case "warned List": {
        return <WarnedSingleUser />;
      }

      case "suspend List": {
        return <SuspendedSingleUser />;
      }

      case "block list": {
        return <UserBlockedList />;
      }

      default: {
        return null;
      }
    }
  };

  return <>{getOptionView()}</>;
};

export default UserManagementOption;
