import { useEffect, useState } from "react";
import "./style.css";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import Pagination from "../../Pagination";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import ImagePopUpModal from "../../ImagePopUpModal";

const HostStickerTable = () => {
  const [getHostSticker, setGetHostSticker] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [stickerIcon, setStickerIcon] = useState(false);
  const [img, setImg] = useState("");
  const { id } = useParams();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchHostSticker();
  }, [apiProvider?.getApiUrl(), page, perPage]);

  const fetchHostSticker = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETHOSTSTICKER,
      "POST",
      { page, perPage, id }
    )
      .then((res) => {
        setGetHostSticker(res?.result);
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleHostSticker = (img) => {
    setStickerIcon(true);
    setImg(img);
  };

  const handleHostStickerClose = () => {
    setStickerIcon(false);
  };
  return (
    <>
      <div className="user__sticker__container web_table_container">
        <table className="user__sticker__table">
          <thead>
            <th className="user__sticker__header">S.No.</th>
            <th className="user__sticker__header">Sticker Name</th>
            <th className="user__sticker__header">Sticker Price</th>
            <th className="user__sticker__header">Sticker Image</th>

            <th className="user__sticker__header">Host Name</th>
            <th className="user__sticker__header">Created At</th>
          </thead>
          <tbody>
            {getHostSticker && getHostSticker?.length
              ? getHostSticker.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__sticker__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="user__sticker__data">
                        {data?.sendStickerId[0]?.name}
                      </td>
                      <td className="user__sticker__data">
                        {data?.sendStickerId[0]?.price}
                      </td>
                      <td className="user__sticker__data">
                        <AiFillEye
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleHostSticker(
                              data?.sendStickerId[0]?.stickerUrl
                            )
                          }
                        />
                      </td>
                      <td className="user__sticker__data">
                        {data?.userId?.name}
                      </td>
                      <td className="user__sticker__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>

        {getHostSticker && getHostSticker?.length > 0 ? (
          ""
        ) : (
          <>
            {" "}
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%"}}
              />
              <p className="no__data__found">No Data Found</p>
            </div>
          </>
        )}
      </div>

      <ImagePopUpModal
        open={stickerIcon}
        handleClose={handleHostStickerClose}
        img={img}
      />
      {getHostSticker?.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default HostStickerTable;
