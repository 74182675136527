import { useEffect, useState } from "react";
import SearchInput from "../../SearchInput";
import Button from "../../library/Button";
import "./style.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import ResponsibilitiesPopup from "../../../pages/subAdmin/responsibilitiesPopup";
import AlertPopUp from "../../AlertPopUp";
import { useApi } from "../../../base/Context/apiProvider";
import Pagination from "../../Pagination";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import moment from "moment";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";

const SubAdminTable = () => {
  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [subAdminList, setSubAdminList] = useState([]);
  const [value, setValue] = useState("");
  const [responsibilityPopup, setResponsibilityPopup] = useState(false);
  const [responsibilities, setResponsibilities] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subAdminId, setSubAdminId] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const apiProvider = useApi();
  const loader = useLoader();

  useEffect(() => {
    let array = [];
    subAdminList.map((item,index) => {
      let obj = {
        "S.No":index+1,
        Name: item?.name,
        Email: item?.email,
        Responsibilities: item?.responsibility?.map((item) => item.name),
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [subAdminList]);

  const handleSubAdmins = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SUBADMINLIST,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setSubAdminList(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setSubAdminList([]);
        loader.showLoader(false);
      });
  };

  useEffect(() => {
    handleSubAdmins();
  }, [page, perPage, value, apiProvider?.getApiUrl(), endDate]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Stickers";
    const headers = [
      ["S.NO", "Name", "Email", "Responsibilities", "Created At"],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,

      item1["Name"],

      item1["Email"],
      item1["Responsibilities"],

      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`SubAdmin Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const viewResponsibilities = (e) => {
    setResponsibilityPopup(true);
    setResponsibilities(e.responsibility);
  };

  const handleEditSubAdmin = (e) => {
    navigate(
      searchParams.get("appType") === "catchwoo"
        ? `/editSubAdmin/?id=${e._id}&name=${e.name}?appType=` +
            searchParams.get("appType")
        : `/editSubAdmin/?id=${e._id}&name=${e.name}`
    );
  };

  const handleDeleteSubAdminClose = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteSubAdmin = (e) => {
    setShowDeleteAlert(true);
    setSubAdminId(e._id);
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETESUBADMIN +
        `/${subAdminId}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        handleSubAdmins();
        handleDeleteSubAdminClose(false);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err?.message);
      });
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportPdf = () => {
    exportToPDF(exportAllData);
  };

  const handleExportCsv = () => {
    const filename =`SubAdmin Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportData = () => {
    const filename =`SubAdmin Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleNavigate = () => {
    if (searchParams.get("appType") === "catchwoo") {
      navigate(`/addsubadmin?appType=` + searchParams.get("appType"));
    } else if (searchParams.get("appType") === "host") {
      navigate(`/addsubAdmin?appType=` + searchParams.get("appType"));
    } else {
      navigate("/addsubAdmin");
    }
  };

  return (
    <>
      <div className="add__wallet">
        {" "}
        <div className="moment_btn">
          <label>Start Date</label>
          <input
            type="date"
            onChange={handleStartDate}
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
          />
          <label>End Date</label>
          <input
            type="date"
            onChange={handleEndDate}
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            min={startDate}
          />
          <Button text="Export Excel" onClick={handleExportData} />
          <Button text="Export CSV" onClick={handleExportCsv} />
          <Button text="Export Pdf" onClick={exportPDF} />
        </div>
        <Button
          style={{ textAlign: "center" }}
          text="Add Sub-Admin"
          onClick={handleNavigate}
        />
      </div>
      <div className="banner__search__btn">
        <SearchInput placeholder="Search" />
      </div>
      <div className="subadmin__container web_table_container">
        <table className="subadmin__table">
          <thead>
            <th className="subadmin__header">S.No</th>
            <th className="subadmin__header"> Name</th>
            <th className="subadmin__header">Email</th>
            <th className="subadmin__header">Responsibilities</th>
            <th className="subadmin__header">Created At</th>
            <th className="subadmin__header">Action</th>
          </thead>
          <tbody>
            {subAdminList &&
              subAdminList.map((data, index) => {
                return (
                  <tr>
                    <td className="sub__admin__data">{index + 1}</td>
                    <td className="sub__admin__data">{data?.name}</td>
                    <td className="sub__admin__data">{data?.email}</td>
                    <td
                      className="sub__admin__data subadmin__view__btn"
                      onClick={() => viewResponsibilities(data)}
                    >
                      View
                    </td>
                    <td className="sub__admin__data">
                      {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                    </td>

                    <td className="sub__admin__data">
                      <AiFillEdit
                        className="subadmin__edit__icon"
                        onClick={() => handleEditSubAdmin(data)}
                      />
                      <AiFillDelete
                        onClick={() => handleDeleteSubAdmin(data)}
                        className="subadmin__delete__icon"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!subAdminList?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {subAdminList && subAdminList?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}

      <ResponsibilitiesPopup
        modalIsOpen={responsibilityPopup}
        setIsOpen={setResponsibilityPopup}
        data={responsibilities}
      />
      <AlertPopUp
        open={showDeleteAlert}
        handleClose={handleDeleteSubAdminClose}
        header="Delete Alert"
        description="Are you sure you want to delete Sub Admin?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDelete}
        onCancelClick={handleDeleteSubAdminClose}
      />
    </>
  );
};

export default SubAdminTable;
