import Card from "../../components/Card";
import Notification from "../../components/Notification";
import { useEffect, useState } from "react";
import { FaUsers, FaUsersSlash } from "react-icons/fa";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import ActiveUsers from "../../base/Assets/Dashboard/activeUsers.png";
import coinStack from "../../base/Assets/Dashboard/coinStack.png";
import offlineUsers from "../../base/Assets/Dashboard/oflineUsers.png";
import groupUsers from "../../base/Assets/Dashboard/groupusers.png";
import rechargedAmount from "../../base/Assets/Dashboard/rechargedAmount.png";

import "./style.css";
import DashboardChart from "../../components/DashboardChart";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SecondaryButton from "../../components/library/SecondaryButton";
import { useLoader } from "../../base/Context/loaderProvider";
import { useApi } from "../../base/Context/apiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSidebar } from "../../base/Context/sidebarProvider";
import moment from "moment";

const Dashboard = () => {
  const loader = useLoader();
  const navigate = useNavigate();

  // let adminOnlineUser = 0;
  const [adminOnlineUser, setAdminOnlineUser] = useState(0);
  const [adminOfflineHost, setAdminOfflineHost] = useState(0);
  const [adminOnlineHost, setAdminOnlineHost] = useState(0);
  const [adminOfflineUser, setAdminOfflineUser] = useState(0);
  const [adminInactiveUser, setAdminInactiveUser] = useState(0);
  const [adminInactiveHost, setAdminInactiveHost] = useState(0);
  const [userRechargeCount, setUserRechargeCount] = useState(0);
  const [coinsBuyed, setCoinBuyed] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [hostEarning, setHostEarning] = useState([]);
  const [userPurchase, setUserPurchase] = useState([]);
  const [catchwooEarning, setCatchwooEarning] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [year, setYear] = useState(false);
  const [month, setMonth] = useState(false);
  const [week, setWeek] = useState(true);
  const [checkEndDate, setCheckEndDate] = useState(true);
  const [checkStartDate, setCheckStartDate] = useState(true);
  const apiProvider = useApi();
  const sidebarProvider = useSidebar();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams && searchParams.get("appType") === "catchwoo") {
      sessionStorage.setItem("selectedType", "catchwoo");
      sidebarProvider.setSelectedType("catchwoo");
    } else if (searchParams && searchParams.get("appType") === "host") {
      sessionStorage.setItem("selectedType", "host");
      sidebarProvider.setSelectedType("host");
    } else {
      sessionStorage.removeItem("selectedType");
      sidebarProvider.setSelectedType("");
    }
  }, [searchParams]);

  useEffect(() => {
    if(searchParams.get('appType')==='host'){

      handleOfflineHost();
      handleOnlineHost();
      handleHostEarning();
      handleInactiveHost();
      handleTotalEarning();
    }else{

      handleOnlineUser();
      handleOfflineUser();
      handleInactiveUser();
      handleUserPurchase();
      handleRechargeCoin();
      handleCatchwoo();
     
      handleCoins();
    }

  }, [year, month, week, endDate, endDate1, apiProvider?.getApiUrl()]);

  useEffect(() => {
    if(searchParams.get('appType')==='host'){

      handleHostEarning();
    }
    handleUserPurchase();
  }, []);

  const handleOnlineUser = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ONLINEUSER,
      "POST"
    )
      .then((res) => {
        loader.showLoader(false);
        setAdminOnlineUser(res?.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err, "err========");
      });
  };

  const handleOfflineHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.OFFLINEHOST,
      "POST"
    )
      .then((res) => {
        loader.showLoader(false);
        setAdminOfflineHost(res?.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err, "err========");
      });
  };

  const handleOnlineHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ONLINEHOST,
      "POST"
    )
      .then((res) => {
        setAdminOnlineHost(res?.result);
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err, "err========");
      });
  };

  const handleOfflineUser = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.OFFLINEUSER,
      "POST"
    )
      .then((res) => {
        setAdminOfflineUser(res?.result);
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err, "err========");
      });
  };

  const handleInactiveUser = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETINACTIVEUSER,
      "POST",
      {}
    )
      .then((res) => {
        setAdminInactiveUser(res?.result);
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };

  const handleRechargeCoin = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.USERRECHARGE,
      "GET"
    )
      .then((res) => {
        setUserRechargeCount(res);
        console.log(res);
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };

  const handleCoins = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.USERCOINBUYED,
      "GET"
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setCoinBuyed(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTotalEarning = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.PERDAYEARNING,
      "GET"
    )
      .then((res) => {
        setTotalEarning(res);
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInactiveHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETINACTIVEHOST,
      "POST",
      {}
    )
      .then((res) => {
        setAdminInactiveHost(res?.result);
        console.log("inactivehost", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCatchwoo = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.CATCHWOOEARNING,
      "POST",
      {
        startDate: moment(startDate1).format("DD-MM-YYYY"),
        endDate: moment(endDate1).format("DD-MM-YYYY"),
        month,
        year,
        week,
      }
    )
      .then((res) => {
        setCatchwooEarning(res?.totalPercentage.toFixed(2));
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
        setCatchwooEarning("");
      });
  };

  const handleUserPurchase = () => {
    const appType = searchParams.get("appType") === "host" ? "host" : "";
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.USERPURCHASE,
      "POST",
      {
        appType,
        startDate: moment(startDate1).format("DD-MM-YYYY"),
        endDate: moment(endDate1).format("DD-MM-YYYY"),
        month,
        year,
        week,
      }
    )
      .then((res) => {
        setUserPurchase(res.totalPercentage.toFixed(2));
        loader.showLoader(false);
        console.log("12345678910", res);
      })
      .catch((err) => {
        loader.showLoader(false);
        setUserPurchase("");
        console.log(err);
      });
  };

  ////////////////////////////////
  //host Earnings

  const handleHostEarning = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.TOTALHOSTEARNING,
      "POST",
      {
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
        month,
        year,
        week,
      }
    )
      .then((res) => {
        console.log(res.totalPercentage);
        setHostEarning(res.totalPercentage.toFixed(2));
        loader.showLoader(false);
      })
      .catch((err) => {
        setHostEarning("");
        console.log(err);
      });
  };

  const handleYear = () => {
    setYear(true);
    setMonth(false);
    setWeek(false);
  };

  const handleMonth = () => {
    setMonth(true);
    setYear(false);
    setWeek(false);
  };

  const handleWeek = () => {
    setMonth(false);
    setYear(false);
    setWeek(true);
    handleHostEarning();
  };

  const handleStartDate = (e) => {
    setCheckStartDate(false);
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    handleHostEarning();
    setWeek(false);
    setMonth(false);
    setYear(false);
  };

  // console.log("startdate", startDate);
  // console.log("enddate", endDate);

  const handleStartDate2 = (e) => {
    setCheckEndDate(false);
    setStartDate1(e.target.value);
  };

  const handleEndDate2 = (e) => {
    setEndDate1(e.target.value);
    setWeek(false);
    setMonth(false);
    setYear(false);
  };

  return (
    <div className="dashboard__container">
      {/* <div style={{ width: "60rem" }}> */}
      <div className="dashboard_cards_box">
        {/* <div className="dashboard__cards_row"> */}
        {searchParams.get("appType") !== "host" ? (
          <>
            {" "}
            <div className="dashboard__cards_column">
              <Card
                name="Active User"
                icon={ActiveUsers}
                number={adminOnlineUser}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/activeuser?appType=" + searchParams.get("appType")
                        : "/activeuser"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Offline User"
                icon={offlineUsers}
                number={adminOfflineUser}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/offlineuser?appType=" + searchParams.get("appType")
                        : "/offlineuser"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Inactive User"
                icon={groupUsers}
                number={adminInactiveUser}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/inactiveuser?appType=" + searchParams.get("appType")
                        : "/inactiveuser"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Recharged Amount"
                icon={rechargedAmount}
                number={userRechargeCount.totalPrice}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/rechargedAmount?appType=" +
                          searchParams.get("appType")
                        : "/rechargedAmount"
                    }`,
                    { state: userRechargeCount.response }
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Total Coins"
                icon={coinStack}
                number={coinsBuyed.totalCoins}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/totalCoinsBought?appType=" +
                          searchParams.get("appType")
                        : "/totalCoinsBought"
                    }`,
                    { state: coinsBuyed.response }
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="dashboard__cards_column">
              <Card
                name="Active Host"
                icon={ActiveUsers}
                number={adminOnlineHost}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/activehost?appType=" + searchParams.get("appType")
                        : "/activehost"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Offline Host"
                icon={groupUsers}
                number={adminOfflineHost}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/offlinehost?appType=" + searchParams.get("appType")
                        : "/offlinehost"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Inactive Host"
                icon={groupUsers}
                number={adminInactiveHost}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/inactivehost?appType=" + searchParams.get("appType")
                        : "/inactivehost"
                    }`
                  );
                }}
              />
            </div>
            <div className="dashboard__cards_column">
              <Card
                name="Total Host Earning"
                icon={coinStack}
                number={totalEarning?.totalCoins}
                onClick={() => {
                  navigate(
                    `${
                      searchParams.get("appType")
                        ? "/totalHostEarnings?appType=" +
                          searchParams.get("appType")
                        : "/totalHostEarnings"
                    }`,
                    { state: totalEarning.response }
                  );
                }}
              />
            </div>
          </>
        )}
        {/* </div> */}
      </div>
      <div className="section_two_dashboard">
        <div className="dashboard__graphs__loaders">
          <div className="dashboard__graph">
            <DashboardChart />
          </div>

          {/* <div>
          <div className="dashboard_filter_btn_row">
            <SecondaryButton isActive={year} onClick={handleYear} text="Year" />
            <SecondaryButton
              onClick={handleMonth}
              isActive={month}
              text="Month"
            />
            <SecondaryButton onClick={handleWeek} isActive={week} text="Week" />
          </div> */}
          {/* <div>
            <div className="dashboard__loader">
              {searchParams.get("appType") === "host" ? (
                <h3 className="dashboard__loader__heading">
                  Earning from Shudhlove
                </h3>
              ) : (
                <h3 className="dashboard__loader__heading">Host Revenue</h3>
              )}
              <div className="dashboard__input__flex">
                <input
                  value={startDate}
                  onChange={handleStartDate}
                  className="dashboard__input"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                />
                <input
                  value={endDate}
                  onChange={handleEndDate}
                  className="dashboard__input"
                  type="date"
                  min={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  disabled={checkStartDate}
                />
              </div>

              <CircularProgressbar
                className="dashboard__host__revenue"
                styles={{
                  path: {
                    stroke: `rgba(242, 0, 148, ${50 / 100})`,
                  },
                }}
                value={hostEarning}
                text={`${hostEarning}%`}
              />
            </div>

            <div className="dashboard__loader_2">
              {searchParams.get("appType") === "host" ? (
                <h3 className="dashboard__loader__heading">
                  Earning from Catchwoo
                </h3>
              ) : (
                <h3 className="dashboard__loader__heading">User Spent</h3>
              )}
              <div className="dashboard__input__flex">
                <input
                  value={startDate1}
                  onChange={handleStartDate2}
                  className="dashboard__input"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                />
                <input
                  value={endDate1}
                  onChange={handleEndDate2}
                  min={startDate1}
                  max={new Date().toISOString().split("T")[0]}
                  className="dashboard__input"
                  type="date"
                  disabled={checkEndDate}
                />
              </div>

              {searchParams.get("appType") === "host" ? (
                <CircularProgressbar
                  className="dashboard__host__revenue"
                  styles={{
                    path: {
                      stroke: `rgba(242, 0, 148, ${50 / 100})`,
                    },
                  }}
                  value={catchwooEarning}
                  text={`${catchwooEarning}%`}
                />
              ) : (
                <CircularProgressbar
                  className="dashboard__host__revenue"
                  styles={{
                    path: {
                      stroke: `rgba(242, 0, 148, ${50 / 100})`,
                    },
                  }}
                  value={userPurchase}
                  text={`${userPurchase}%`}
                />
              )}
            </div>
          </div> */}
        </div>
        <div className="dashboard__notification">
          <Notification />
        </div>
      </div>
      {/* </div> */}
    </div>
    // </div>
  );
};

export default Dashboard;
