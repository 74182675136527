import { useEffect, useState } from "react";
import Button from "../../components/library/Button";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import { useLoader } from "../../base/Context/loaderProvider";
import noData from "../../base/Animation/No Data Found.json";
import { AiFillDelete } from "react-icons/ai";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import AlertPopUp from "../../components/AlertPopUp";
import { useSearchParams } from "react-router-dom";

const ApkFile = () => {
  const [formData, setFormData] = useState([]);
  const [key, setKey] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const apiProvider = useApi();
  const loader = useLoader();

  const handleDelete = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  useEffect(() => {
    fetchFormData();
  }, [apiProvider?.getApiUrl(), page, perPage, key]);

  const fetchFormData = () => {
    const appType =
      searchParams.get("appType") === "Host" ? "host" : "shudhloveuser";
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETFORMDATA,
      "POST",
      {
        appType,
        key,
        page,
        perPage,
      }
    )
      .then((res) => {
        setFormData(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitClick = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEFORMDATA + `${id}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        setShowDeleteAlert(false);
        fetchFormData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleText = (e) => {
    setKey(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={key}
      />

      <div className="apk_file_container web_table_container">
        <table className="apk_file_table">
          <thead>
            <th className="apk_file_header">S.No.</th>
            <th className="apk_file_header">Name</th>
            <th className="apk_file_header">Gender</th>
            <th className="apk_file_header">Email</th>
            <th className="apk_file_header">Mobile Number</th>
            <th className="apk_file_header">Country</th>
            <th className="apk_file_header">State</th>
            <th className="apk_file_header">Action</th>
          </thead>
          <tbody>
            {formData && formData.length > 0
              ? formData.map((data, index) => {
                  return (
                    <tr>
                      <td className="apk_file_data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="apk_file_data">{data?.name}</td>
                      <td className="apk_file_data">{data?.gender}</td>
                      <td className="apk_file_data">{data?.email}</td>
                      <td className="apk_file_data">{data?.mobileNumber}</td>
                      <td className="apk_file_data">{data?.country}</td>
                      <td className="apk_file_data">{data?.state}</td>
                      <td className="apk_file_data">
                        <AiFillDelete
                          className="apk_file_icon"
                          onClick={() => handleDelete(data?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        {!formData.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{width: "40%", height: "30%"}}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      <AlertPopUp
        open={showDeleteAlert}
        handleClose={handleDeleteClose}
        handleOpen={handleDelete}
        header="Delete Alert"
        description="Are you sure you want to delete this form data?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleSubmitClick}
        onCancelClick={handleDeleteClose}
      />
      {formData.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default ApkFile;
