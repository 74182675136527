import { useContext, useEffect, useState } from "react";
import "./style.css";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { Modal } from "../../../base/Context/modalProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import { AiFillEdit } from "react-icons/ai";
import jsPDF from "jspdf";
import UserMomentTable from "../UserMomentTable";

const UserReportTable = () => {
  const { id } = useParams();
  const [userReportList, setUserReportList] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [postDetails, setPostDetails] = useState(false);
  const [getId, setGetId] = useState("");
  const loader = useLoader();
  const modalProvider = useContext(Modal);
  const apiProvider = useApi();
  let navigate = useNavigate();

  useEffect(() => {
    let array = [];
    userReportList.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "User Id": item?.userId?.userId,
        "User Name": item?.userId?.name,
        Report: item?.hostId?.name || item?.targetId?.hostId?.name,
        "Report ID": item?.reportId,
        Title: item?.Choose_the_Reason,
        "Report Type": item?.reportsType,
        "Date & Time": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [userReportList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Report List Report";
    const headers = [
      [
        "S.No",
        "User Id",
        "User Name",
        "Report",
        "Report ID",
        "Title",
        "Report Type",
        "Date & Time",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["User Id"],
      item1["User Name"],
      item1["Report"],
      item1["Report ID"],
      item1["Title"],
      item1["Report Type"],
      item1["Date & Time"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Users Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    getUserReport();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const getUserReport = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.USERREPORT,
      "POST",
      { userId: id, key: value, page, perPage, startDate, endDate }
    )
      .then((res) => {
        setUserReportList(res.result);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
        setUserReportList([]);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Users Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Users Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handlePostDetails = (getId) => {
    setPostDetails(true);
    setGetId(getId);
  };

  const handlePostDetailsClose = () => {
    setPostDetails(false);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="user_report_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
      </div>
      <div className="web_table_container user__report__container">
        <table className="user__report__table">
          <thead>
            <th className="user__report__header">S.No.</th>
            {!id && (
              <>
                <th className="user__report__header">User ID</th>
                <th className="user__report__header">User Name</th>
              </>
            )}
            <th className="user__report__header">Report</th>
            <th className="user__report__header">Report ID</th>
            <th className="user__report__header">Title</th>
            <th className="user__report__header">Report Type</th>
            <th className="user__report__header">report Post Count</th>
            <th className="user__report__header">Report Profile Count</th>

            <th className="user__report__header">Date & Time</th>
            <th className="user__report__header">Action</th>
          </thead>
          <tbody>
            {userReportList && userReportList.length > 0
              ? userReportList.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__report__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {!id && (
                        <>
                          <td className="user__report__data">
                            {data?.userId!==null?data?.userId?.userId:'Deleted-user'}
                          </td>
                          <td className="user__report__data">
                            <div
                              className="feedback__table__comment"
                              onClick={
                                data?.userId?.name.length > 12
                                  ? () =>
                                      modalProvider.handleCommentClick(
                                        data?.userId?.name,
                                        "Name"
                                      )
                                  : () => {}
                              }
                            >
                              {data?.userId!==null?data?.userId?.name:'Deleted-user'}
                            </div>
                          </td>
                        </>
                      )}

                      <td className="user__report__data">
                        {data?.hostId?.name || data?.targetId?.hostId?.name}
                      </td>
                      <td className="user__report__data">{data?.reportId}</td>
                      <td className="user__report__data">
                        {data?.Choose_the_Reason}
                      </td>
                      <td className="user__report__data">
                        {data?.reportsType === "Post" ? (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePostDetails(data?._id)}
                          >
                            {data?.reportsType}
                          </p>
                        ) : (
                          <p>{data?.reportsType}</p>
                        )}
                      </td>
                      <td className="user__report__data">
                        {data?.reportPostCount ? data?.reportPostCount : "-"}
                      </td>
                      <td className="user__report__data">
                        {data?.reportProfileCount
                          ? data?.reportProfileCount
                          : "-"}
                        <br />
                        
                      </td>
                      <td className="user__report__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                      <td className="user__report__data">
                        <AiFillEdit
                          className="user_report_icon"
                          onClick={() =>
                            navigate(
                              `${
                                `/acceptedhost/hostmanagement/${
                                  data?.reportsType === "Post"
                                    ? data?.targetId?.hostId?._id
                                    : data?.hostId?._id
                                }`
                              }`
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <UserMomentTable
          open={postDetails}
          handleClose={handlePostDetailsClose}
          id={getId}
        />

        {!userReportList.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{
                width: "40%",
                height: "30%",
              }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>

      {userReportList.length > 0 ? (
        <Pagination
          page={page}
          perPage={perPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPage={setPage}
          setPerPage={setPerPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default UserReportTable;
