import { useEffect, useState } from "react";
import { useLoader } from "../../../base/Context/loaderProvider";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
// import "./style.css";
import { useApi } from "../../../base/Context/apiProvider";
import { errorToast, successToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";

const HostFeeForm = ({ id, setShowRedirectAcceptedHostAlert,GotoAcceptedHost}) => {
  const [audioCallFees, setAudioCallFees] = useState("");
  const [hostuser_fees, setHostuser_fees] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();

  

  const handleAudioCharges = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEAUDIOCALLCHARGE,
      "PUT",
      {
        id: id,
        audioCall_fees: audioCallFees,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        // getAcceptedHost();
        // setShowAudioAlert(false);
        successToast(res?.message);
      })
      .catch((err) => {
        errorToast(err.message);
        loader.showLoader(false);
      });
  };
  const handleHostLeader = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.UPDATEHOSTVIDEOFEES +
        `/${id}`,
      "PUT",
      {
        hostuser_fees: hostuser_fees,
      }
    )
      .then((res) => {
        // getAcceptedHost();
        setShowRedirectAcceptedHostAlert(false)
        loader.showLoader(false);
        GotoAcceptedHost()
        successToast(res?.message);
      })
      .catch((err) => {
        errorToast(err?.message);
        loader.showLoader(false);
      });
  };
  return (
    <>
    <div className="premium__coin__container">
      <h2 className="premium__coin__heading">Set Audio Charge(per second)</h2>
      <div className="premium__coin">
        <InputField
          value={audioCallFees}
          placeholder="Set Random Call Time in sec"
          type='number'
          onChange={(e) => setAudioCallFees(e.target.value.replace(/\D/g, ""))}
          maxlength="5"

        />
        <br />
        <Button
          onClick={handleAudioCharges}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
    <div className="premium__coin__container">
    <h2 className="premium__coin__heading">Set Video Charge(per second)</h2>
    <div className="premium__coin">
      <InputField
        value={hostuser_fees}
        placeholder="Set Random Call Time in sec"
        onChange={(e) => {
          setHostuser_fees(e.target.value.replace(/\D/g, ""));
        }}
        maxlength="5"
        type='number'
      />
      <br />
      <Button
        onClick={handleHostLeader}
        text="Update"
        style={{ margin: "auto" }}
      />
    </div>
  </div>
    </>
  );
};

export default HostFeeForm;
