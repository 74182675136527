import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { PiLinkSimpleBold } from "react-icons/pi";
import "./style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputField from "../../library/InputField";
import Button from "../../library/Button";
import { useLoader } from "../../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";

export default function MailPopup({ show, setShow, handleMailList ,allMailsData, mailsData}) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [mails, setMails] = useState([]);
  const [mailValue, setMailValue] = useState("");
  const [paste, setPaste] = useState(false);
  const [searchParams , setSearchParams]= useSearchParams();
  const apiProvider = useApi();
  const app = useLoader();
  const textAreaRef = useRef();
  const navigate = useNavigate();


  useEffect(()=>{
    if(mailsData?.length){
      mailsData.map((item)=>{
        setMails([...mails, { color: getRandomColor(), mail: item }]);
      })
    }

  },[mailsData])

  useEffect(()=>{
    if(allMailsData?.length){
      allMailsData.map((item)=>{
        setMails([...mails, { color: getRandomColor(), mail: item }]);
      })
    }
  },[allMailsData])




  const Colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const getRandomColor = () => {
    const color = Colors[Math.floor(Math.random() * Colors.length)];
    return color;
  };

  // function auto_height() {
  //   textAreaRef.current.style.height = "1px";
  //   textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  // }

  const handleSubject = (e) => {
    setSubject(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleFile = (e) => {
    setFiles([...files, e.target.files[0]]);
  };

  const handleMails = (e) => {
    setMailValue(e.target.value);
  };

   const RejexConstants = {
    emailRegex: /^[A-Za-z0-9.]+@[A-Za-z]+.[A-Za-z]{2,3}$/,
    numRegex: /^[0-9\b]+$/,
  };

  const handleEnter = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (RejexConstants.emailRegex.test(mailValue)) {
        if (mails?.length === 0) {
          setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
          setMailValue("");
        } else if (!mails.some((mail) => mail.mail === mailValue)) {
          setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
          setMailValue("");
        }
      }
    }
  };

  const handleOnBlur = () => {
    if (RejexConstants.emailRegex.test(mailValue)) {
      if (mails?.length === 0) {
        setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
        setMailValue("");
      } else if (!mails.some((mail) => mail.mail === mailValue)) {
        setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
        setMailValue("");
      }
    }
  };

  const handleOnPaste = () => {
    setPaste(true);
  };

  const handleRemoveFile = (e) => {
    const newFiles = files.filter((file) => {
      return file.name !== e;
    });
    setFiles(newFiles);
  };

  const handleRemovePerson = (e) => {
    const newMails = mails.filter((item) => {
      return item.mail !== e.mail;
    });
    setMails(newMails);
  };

  const validate = () => {
    let result = true;
    if (!mails?.length) {
      result = false;
      
    } else if (!subject) {
      result = false;
    } else if (!message) {
      result = false;
    }
    return result;
  };

  const handleSendMail = () => {
    if (validate()) {
      const emailPayload = new FormData();
      emailPayload.append("subject", subject);
      emailPayload.append("message", message);

      files.forEach((item) => {
        emailPayload.append("images", item);
      });

      mails.forEach((item) => {
        emailPayload.append("emails", item.mail);
      });

      emailPayload.append("to" , searchParams.get("appType")==="host" ? "hosts" : "users");

      app.showLoader(true);
    fetchDataFromAPI(apiProvider?.getApiUrl() + NetworkConfiguration.SENDMAILTOFEW, "POST" ,emailPayload ,{
      "Content-Type" :"multipart/form-data"
    })
        .then((res) => {
          app.showLoader(false);
         successToast(res?.message)
          setShow(false);
          handleMailList();
        })
        .catch((err) => {
          app.showLoader(false);
          errorToast(err?.message);
        });
    }
  };

  useEffect(() => {
    if (paste) {
      if (RejexConstants.emailRegex.test(mailValue)) {
        if (mails?.length === 0) {
          setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
          setMailValue("");
          setPaste(false);
        } else if (!mails.some((mail) => mail.mail === mailValue)) {
          setMails([...mails, { color: getRandomColor(), mail: mailValue }]);
          setMailValue("");
          setPaste(false);
        }
      }
    }
  }, [paste, mailValue]);

  return (
    <div className="mail_popup_bg">
      <div className="mail_popup_head_row">
        <p>New Message</p>

        <div
          onClick={() => {
            setShow();
          }}
        >
          <IoClose size={20} color="#000" style={{ cursor: "pointer" }} />
        </div>
      </div>

      <div className="mail_input_row to_mail_row">
        <p style={{ paddingTop: "10px" }}>To </p>
        <div>
          <div className="selected_mails_row">
            {mails.map((item, index) => {
              return (
                <p key={index} className="selected_mail_item">
                  <span
                    style={{
                      background: item.color,
                    }}
                  >
                    {item?.mail?.charAt(0)}
                  </span>
                  {item.mail}

                  <div className="selected_mail_item_remove">
                    <IoClose
                      size={18}
                      color="#000"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemovePerson(item)}
                    />
                  </div>
                </p>
              );
            })}
          </div>


          <InputField
            label=""
            type="text"
            placeholder=""
            onChange={handleMails}
            onKeyUp={handleEnter}
            onBlur={handleOnBlur}
            onpaste={handleOnPaste}
            error=""
            value={mailValue}
            style={{
              border: "none",
              borderRadius: "0",
            }}
          />
        </div>      
      </div>

      <div className="mail_input_row">
        <p>Subject </p>
        <InputField
          label=""
          type="text"
          placeholder=""
          onChange={handleSubject}
          error=""
          value={subject}
          style={{
            border: "none",
            borderRadius: "0",
          }}
        />
      </div>

      <div className="mail_message_box ">
        <textarea
          ref={textAreaRef}
          rows="1"
          className="auto_height"
          // onInput={auto_height}
          value={message}
          onChange={handleMessage}
        ></textarea>

        <div className="mail_file_box">
          {files?.length
            ? files.map((file, index) => {
                return (
                  <div key={index} className="mail_selected_file">
                    <p>{file?.name}</p>
                    <IoClose
                      size={18}
                      color="#000"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveFile(file.name)}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="mail_bottom_box">
        <Button
          onClick={handleSendMail}
          text="Send"
          style={null}
          className="header_btn"
          type="button"
        />

        <div className="file_upload_mail_bg">
          <div className="image-upload">
            <label for="file-input">
              <div className="file_link_icon">
                <PiLinkSimpleBold className="file_link_icon_style" />
              </div>
            </label>

            <input id="file-input" type="file" onChange={handleFile} />
          </div>
        </div>
      </div>
    </div>
  );
}
