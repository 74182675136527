import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Pagination from "../../Pagination";
import { AiFillEye } from "react-icons/ai";
import AlertPopUp from "../../AlertPopUp";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";

const UserStickerTable = () => {
  const [getUserSticker, setGetUserSticker] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [showSticker, setShowSticker] = useState(false);
  const [img, setImg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const id = useState(params.id);

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchUserSticker();
  }, []);

  const fetchUserSticker = () => {
    const appType =
      searchParams.get("appType") === "catchwoo" ? "catchWoo" : "";
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETSTICKERUSER,
      "POST",
      {
        appType,
        page,
        perPage,
        id,
      }
    )
      .then((res) => {
        setGetUserSticker(res?.result);
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);

        console.log(err);
      });
  };

  const handleStickerIcon = (img) => {
    setShowSticker(true);
    setImg(img);
  };

  const handleStickerIconClose = () => {
    setShowSticker(false);
  };

  return (
    <>
      <div className="user__sticker__container web_table_container">
        <table className="user__sticker__table">
          <thead>
            <th className="user__sticker__header">S.No.</th>
            <th className="user__sticker__header">Sticker ID</th>
            <th className="user__sticker__header">Sticker Name</th>
            <th className="user__sticker__header">Host Name</th>
            <th className="user__sticker__header">Sticker Image</th>
            <th className="user__sticker__header">Sticker Price</th>
            <th className="user__sticker__header">Created At</th>
          </thead>
          <tbody>
            {getUserSticker && getUserSticker?.length
              ? getUserSticker.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__sticker__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="user__sticker__data">
                        {data?.sendStickerId ? data.sendStickerId[0]?.name : ""}
                      </td>

                      <td className="user__sticker__data">
                        {data?.sendStickerId[0]?.name}
                      </td>
                      <td className="user__sticker__data">
                        {data?.hostId?.name}
                      </td>

                      <td className="user__sticker__data">
                        <AiFillEye
                          style={{ width: "20px", height: "20px" }}
                          onClick={() =>
                            handleStickerIcon(
                              data?.sendStickerId[0]?.stickerUrl
                            )
                          }
                        />
                        {}
                      </td>
                      <td className="user__sticker__data">
                        {data?.sendStickerId[0]?.price}
                      </td>

                      <td className="user__sticker__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>

        {!getUserSticker?.length ? (
          <>
            {" "}
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%" }}
              />
              <p className="no__data__found">No Data Found</p>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <ImagePopUpModal
        open={showSticker}
        handleClose={handleStickerIconClose}
        img={img}
      />
      {getUserSticker?.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default UserStickerTable;
