import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { handleNavLinkClassName } from "../../helpers/sidebarHelpers";
import { useSidebar } from "../../base/Context/sidebarProvider";
import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./style.css";
import Logo from '../../base/Assets/Logo/Logo.png'
import secondLogo from '../../base/Assets/Logo/Shudhlovelogo.png'


const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const sidebarProvider = useSidebar();
  const location = useLocation();
  const navigate = useNavigate();
  const [src,setSrc]=useState('')
  // const [searchParams, setSearchParams] = useSearchParams();
  const [activeMenu, setActiveMenu] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const appType = sessionStorage.getItem("selectedType");
  useEffect(() => {
    if (appType === "catchwoo") {
     setSrc(Logo)
    } else if (appType === "host") {
      setSrc(secondLogo)
    }else{
      setSrc(secondLogo)

    }

  }, [appType]);
  const handleSubMenus = (data) => {
    setActiveMenu(data.label);
    if (activeMenu === data.label) {
      setMenuOpen(true);
    } else {
      setMenuOpen(true);
    }
  };
  const toggleActiveMenu=(data)=>{
    setMenuOpen(true)
    const navigateTo=data.subMenus[0].link
    handleNavigate(navigateTo)
    console.log(data)
  }
  const handleNavigate = (link) => {
    navigate(link);
  };

  const handleNavLinkClassName = ({ isActive }) => {
    if (isActive) {
      return "sidebar__active_data";
    } else {
      return "sidebar__data";
    }
  };

  const handleNavigateMenu = (link) => {
    setActiveMenu();
    navigate(link.link);
  };

  return (
    <>
      <div className="sidebar_div">
        <div style={{width:'100%',display:'flex' ,alignItems:'center',justifyContent:'center'}}>


          <img src={src} alt='logo' style={{width:'100px', height:"70px"}}/>
          {/* <img src={secondLogo} alt='logos' style={{width:'100px',height:'100px'}}/> */}
        </div>
        <div className="sidebar_content">
          {sidebarProvider?.sidebarContent.map((data, index) => {
            const isActive =
              data?.link === location?.pathname + location?.search;
            return (
              <div
                className={
                  data?.label === activeMenu && menuOpen
                    ? "sidebar_item_container_active_submenu "
                    : "sidebar_item_container"
                }
                key={index}
              >
                <div className="sidebar_item_container">
                  <div
                    className={
                      isActive && !activeMenu
                        ? "active_sidebar_item"
                        : "sidebar_item"
                    }
                    onClick={
                      !data.subMenus ? () => handleNavigateMenu(data) :()=> toggleActiveMenu(data)
                    }
                  >
                    <div
                      onClick={() => handleSubMenus(data)}
                      className={
                        data?.label === activeMenu 
                          ? "sidebar__label_active_sub_data active_sidebar_item"
                          : data?.label
                          ? "sidebar_label_container"
                          : ""
                      }
                    >
                      <div
                        className=""
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          style={{ height: "100%", width: "100%" }}
                          src={
                            isActive || activeMenu===data.label ? data.activeIcon : data.icon
                          }
                          alt={data.icon}
                        />
                      </div>
                      <p style={{fontSize:'13px'}}>{data.label}</p>
                      <p  style={{
                             alignSelf:'center',
                             marginLeft:'auto',}}>
                        {data.subMenus ? (
                          <IoIosArrowForward
                            style={{
                             alignSelf:'end',
                             marginLeft:'auto',

                              transform:
                                data?.label === activeMenu && menuOpen
                                  ? "rotate(90deg)"
                                  : null,
                            }}
                          />
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                        className="navbar_subMenu"
                        style={{
                          display: activeMenu === data.label && menuOpen? "" : "none",
                        }}
                      >
                        {data?.subMenus?.map((item) => {
                          console.log(
                            "Item color: ",
                            item?.link.split("?")[0],
                            item?.link === location?.pathname
                          );
                          return (
                            <p
                              style={
                                item?.link.split("?")[0] === location?.pathname
                                  ? {
                                      color: "#6C5DD3",
                                      textDecoration:'underline'
                                    }
                                  : {}
                              }
                              onClick={() => handleNavigate(item.link)}
                            >
                              {item.label}
                            </p>
                          );
                        })}
                      </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;


