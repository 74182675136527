import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { checkLength } from "../../../utils/utility";

const AudioChargeCall = ({ onSubmit }) => {
  const [audioCallChargeTime, setAudioCallChargeTime] = useState("");
const[error,setError]=useState()
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchAudioCallTime();
  }, []);

  const fetchAudioCallTime = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETAUDIOCALLTIME,
      "GET"
    )
      .then((res) => {
        setAudioCallChargeTime(res.result);
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
useEffect(()=>{
  if(audioCallChargeTime!==''){
    setError('')
  }
},[audioCallChargeTime])
  const handleAudioCallTime = () => {
    if(audioCallChargeTime===''){
      setError("This field is required")
      return;

    }
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEAUDIOCALLTIME,
      "PUT",
      {
        audioCallTimeset: audioCallChargeTime,
      }
    )
      .then((res) => {
        onSubmit();
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length<e.target.maxLength) {
      setError("Only numbers are allowed and the length should not exceed 10 digits");
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="premium__coin__container" style={{width:'350px'}}>
      <h2 className="premium__coin__heading">Set Audio Call Charge Time</h2>
      <div className="premium__coin">
        <InputField
          value={audioCallChargeTime}
          placeholder="Set Random Call Time in sec"
          onChange={(e) => {
            setAudioCallChargeTime(e.target.value.replace(/\D/g, ""));
          }}
          onKeyDown={handleKeyDown}
          type='number'
          maxlength="5"
        />
        {error && (
          <p className="error_alert">{error}</p>
        )}
        <br />
        <Button
          onClick={handleAudioCallTime}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default AudioChargeCall;
