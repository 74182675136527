import { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "./style.css";
import { Outlet, useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();

  const path = location.pathname;
  return (
    <div className="layout__container">
      <div className="section_one">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>

      <div className={`section_two ${isSidebarOpen ? "open" : "close"}`}>
        <Navbar />
      {path!=="/dashboard"&&  <Breadcrumbs />}
        <div
          className="outlet_container"
          style={{ padding: path === "/dashboard" ? "0px" : "" }}
        >
          {children}
        </div>
        {/* </div> */}
        {/* <div className="outlet_container">
          <Outlet />
        </div> */}
      </div>
    </div>
  );
};

export default Layout;
