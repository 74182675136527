import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";
import "./style.css";
import { useSearchParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";

const ApkFileForm = ({ setShowApkForm, fetchApkData,id,editFile }) => {
  const [apkName, setApkName] = useState("");
  const [apkFile, setApkFile] = useState("");
  const [apkVersion, setApkVersion] = useState("");
  const [mandatory, setMandatory] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState({
    apkNameError: "",
    apkFileError: "",
    apkVersionError: "",
  });
  const loader = useLoader();
  useEffect(() => {}, [mandatory]);
  const apiProvider = useApi();

  const handleOnSubmit = () => {
    const appType =
      searchParams.get("appType") === "host" ? "host" : "shudhloveuser";
    let data = new FormData();
    data.append("appType", appType);
    data.append("name", apkName);
    data.append("image", apkFile);
    data.append("apkVersion", apkVersion);
    data.append("isMandatory", mandatory);
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDAPKFILE,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          loader.showLoader(false);
          successToast(res?.message);
          fetchApkData();
          setShowApkForm(false);
        })
        .catch((err) => {
          errorToast(err?.message);
          loader.showLoader(false);
        });
    }
  };
  const handleEditApk=()=>{
    const appType =
      searchParams.get("appType") === "host" ? "host" : "shudhloveuser";
    let data = new FormData();
    data.append("appType", appType);
    data.append("name", apkName);
    data.append("image", apkFile);
    data.append("apkVersion", apkVersion);
    data.append("isMandatory", mandatory);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.EDITAPK+`/${id}`,
      "PUT",
      
        data
      
    ) .then((res) => {
      fetchApkData()
      successToast(res?.message)
      setShowApkForm()
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  const validate = () => {
    let result = true;
    if (!apkName || apkName.trim()?.length === 0) {
      setError({ ...error, apkNameError: "Enter valid APK Name" });
      result = false;
    } else if (!apkVersion || apkVersion.trim()?.length === 0) {
      setError({ ...error, apkVersionError: "Enter Apk Version" });
      result = false;
    } else if (!apkFile) {
      setError({ ...error, apkFileError: "Upload Apk File" });
      result = false;
    }
    return result;
  };

  const handleApkName = (e) => {
    setApkName(e.target.value);
    setError({ ...error, apkNameError: "" });
  };

  const handleApkFile = (e) => {
    setApkFile(e.target.files[0]);
    setError({ ...error, apkFileError: "" });
  };

  const handleApkVersion = (e) => {
    setApkVersion(e.target.value);
    setError({ ...error, apkVersionError: "" });
  };

  return (
    <>
      <h2 className="banner__heading">Add Apk File</h2>
      <div className="banner__fields__gap">
        <InputField
          onChange={handleApkName}
          value={apkName}
          placeholder="Apk File Name"
          error={error.apkNameError}
        />
        <br />
        <InputField
          onChange={handleApkVersion}
          placeholder="Apk Version"
          error={error.apkVersionError}
        />
        <br />
        <InputField
          onChange={handleApkFile}
          type="file"
          accept=".apk"
          error={error.apkFileError}
        />
        <div className="add_banner_div">
          <InputField
            value={mandatory}
            onChange={(e) => {
              setMandatory(e.target.checked);
            }}
            type="checkbox"
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
            }}
          />
          <p>Is mandatory</p>
        </div>
        <Button
          text="Submit"
          onClick={editFile?handleEditApk:handleOnSubmit}
          className="add__banner__button"
        />
      </div>
    </>
  );
};

export default ApkFileForm;
