import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Pagination from "../../components/Pagination";
import noData from "../../base/Animation/No Data Found.json";
import Lottie from "react-lottie";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import { useSearchParams } from "react-router-dom";
import UserLikedProfile from "../../components/Table/UserLikedProfile";

const DeleteMoments = () => {
  const [deleteMoments, setDeleteMoments] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [momentId, setMomentId] = useState("");

  const [showPostImage, setShowPostImage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showLikedProfile, setShowLikedProfile] = useState(false);

  const [img, setImg] = useState("");

  const apiProvider = useApi();

  useEffect(() => {
    fetchDeleteMoments();
  }, [page, perPage, apiProvider?.getApiUrl()]);

  const fetchDeleteMoments = () => {
    let appType = searchParams.get("appType") === "host" ? "host" : "user";
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEDMOMENTS,
      "POST",
      {
        appType,
        page,
        perPage,
      }
    )
      .then((res) => {
        setDeleteMoments(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePostImage = (img) => {
    setShowPostImage(true);
    setImg(img);
  };

  const handlePostImageClose = () => {
    setShowPostImage(false);
  };

  const handleLikesPost = (momentId) => {
    setShowLikedProfile(true);
    setMomentId(momentId);
  };

  const handleLikesPostClose = () => {
    setShowLikedProfile(false);
  };

  return (
    <>
      <div className="delete_moments_container web_table_container">
        <table className="delete_moments_table">
          <thead>
            <th className="delete_moments_header">S.No.</th>
            <th className="delete_moments_header">Name</th>
            <th className="delete_moments_header">Caption</th>
            <th className="delete_moments_header">Likes</th>
            <th className="delete_moments_header">Hashtag</th>
            <th className="delete_moments_header">Image/Video</th>
            <th className="delete_moments_header">Deleted Reason</th>
            <th className="delete_moments_header">Created At</th>
          </thead>
          <tbody>
            {deleteMoments.map((data, index) => {
              return (
                <tr>
                  <td className="delete_moments_data">
                    {(page - 1) * perPage + index + 1}
                  </td>
                  <td className="delete_moments_data">
                    {searchParams.get("appType") === "host"
                      ? data?.hostId!==null?data?.hostId.name:'Deleted-user'
                      :data?.userId!==null? data?.userId?.name:'Deleted-user'}
                  </td>
                  <td className="delete_moments_data">{data?.subject}</td>
                  <td
                    className="delete_moments_data"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLikesPost(data?._id)}
                  >
                    {data?.likes}
                  </td>
                  <td className="delete_moments_data">{data?.hashTag?data?.hashTag:'No-hastag'}</td>

                  <td className="delete_moments_data">
                    <AiFillEye
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={() => handlePostImage(data?.postImage)}
                    />
                  </td>
                  <td className="delete_moments_data">{data?.resion}</td>

                  <td className="delete_moments_data">
                    {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!deleteMoments.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>

      {deleteMoments.length ? (
        <UserLikedProfile
          open={showLikedProfile}
          handleClose={handleLikesPostClose}
          id={momentId}
        />
      ) : (
        ""
      )}

      <ImagePopUpModal
        open={showPostImage}
        handleClose={handlePostImageClose}
        img={img}
      />
      {deleteMoments && deleteMoments.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default DeleteMoments;
