import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import moment from "moment";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import { useDebounce } from "use-debounce";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import Button from "../../library/Button";
import { AiFillEdit } from "react-icons/ai";
import jsPDF from "jspdf";
import UserMomentTable from "../UserMomentTable";

const HostReportTable = () => {
  const [getHostReport, setGetHostReport] = useState([]);
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [postDetails, setPostDetails] = useState(false);
  const [getId, setGetId] = useState("");
  let navigate = useNavigate();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    getHostReport.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "Host ID": item?.hostId?.userId,
        "Host Name": item?.hostId?.name,
        Report: item?.targetId?.userId?.name,
        "Report ID": item?.reportId,
        Title: item?.Choose_the_Reason,
        // Reason: item?.comment,
        "Report Type": item?.reportsType,
        "Date & Time": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getHostReport]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Report List Report";
    const headers = [
      [
        "S.No",
        "Host ID",
        "Host Name",
        "Report",
        "Report ID",
        "Title",
        "Report Type",
        "Date & Time",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Host ID"],
      item1["Host Name"],
      item1["Report"],
      item1["Report ID"],
      item1["Title"],
      item1["Report Type"],
      item1["Date & Time"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const getHostReportList = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.HOSTREPORT,
      "POST",
      {
        hostId: id,
        page,
        perPage,
        key: value,
        page,
        perPage,
        startDate,
        endDate,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setGetHostReport(res.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setGetHostReport([]);
        console.log(err);
      });
  };

  useEffect(() => {
    getHostReportList();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handlePostDetails = (getId) => {
    setPostDetails(true);
    setGetId(getId);
  };

  const handlePostDetailsClose = () => {
    setPostDetails(false);
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        value={value}
        placeholder="Search"
        icon={searchIcon()}
      />

      <div className="host_report_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="host__report__container web_table_container">
        <table className="host__report__table">
          <thead>
            <th className="host__report__header">S.No.</th>
            {!id && (
              <>
                <th className="host__report__header">Host ID</th>
                <th className="host__report__header">Host Name</th>
              </>
            )}
            <th className="host__report__header">Report</th>
            <th className="host__report__header">Report ID</th>
            <th className="host__report__header">Title</th>
            <th className="host__report__header">Report Type</th>
            <th className="host__report__header">Date & Time</th>
            <th className="host__report__header">Action</th>
          </thead>
          <tbody>
            {getHostReport?.length > 0
              ? getHostReport.map((data, index) => {
                  return (
                    <tr>
                      <td className="host__report__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {!id && (
                        <>
                          {" "}
                          <td className="host__report__data">
                            {data?.hostId!==null?data?.hostId?.userId:'Deleted-user'}
                          </td>
                          <td className="host__report__data">
                            {data?.hostId?data?.hostId?.name:'Deleted-user'}
                          </td>
                        </>
                      )}
                      <td className="host__report__data">
                        {data?.targetId?.userId?.name}
                      </td>
                      <td className="host__report__data">{data?.reportId}</td>
                      <td className="host__report__data">
                        {data?.Choose_the_Reason}
                      </td>
                      <td className="host__report__data">
                        {data?.reportsType === "Post" ? (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePostDetails(data?._id)}
                          >
                            {data?.reportsType}
                          </p>
                        ) : (
                          <p>{data?.reportsType}</p>
                        )}
                      </td>
                      <td className="host__report__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                      <td className="host__report__data">
                        <AiFillEdit
                          className="host_report_icon"
                          onClick={() => {
                            navigate(
                              `${
                                searchParams.get("appType")
                                  ? `/usermanagement/${
                                      data?.reportsType === "Post"
                                        ? data?.targetId?.userId?._id
                                        : data?.userId?._id
                                    }?appType=` + searchParams.get("appType")
                                  : ""
                              }`
                            );
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <UserMomentTable
          open={postDetails}
          handleClose={handlePostDetailsClose}
          id={getId}
        />

        {!getHostReport?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon no_data_found">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>
      {getHostReport?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          perPage={perPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[5, 10, 15, 20]}
        />
      ) : null}
    </>
  );
};

export default HostReportTable;
