import feedBack from "../base/Assets/Icons/Grey/feedBack.png";
import feedBackLight from "../base/Assets/Icons/white/feedback.png";
import notification from "../base/Assets/Icons/Grey/notification.png";
import notificationLight from "../base/Assets/Icons/white/Notificatioons.png";
import gift from "../base/Assets/Icons/Grey/Gift.png";
import giftsLight from "../base/Assets/Icons/white/Gifts.png";
import moment from "../base/Assets/Icons/Grey/moments.png";
import momentLight from "../base/Assets/Icons/white/moments.png";
import sticker from "../base/Assets/Icons/Grey/stickers.png";
import stickerLight from "../base/Assets/Icons/white/stickers.png";
import bucket from "../base/Assets/Icons/Grey/bucket.png";
import bucketLight from "../base/Assets/Icons/white/bucket.png";
import report from "../base/Assets/Icons/Grey/report.png";
import reportLight from "../base/Assets/Icons/white/reports.png";
import payment from "../base/Assets/Icons/Grey/paymentHistory.png";
import paymentHistoryLight from "../base/Assets/Icons/white/paymentHistory.png";
import callHistory from "../base/Assets/Icons/Grey/callHistory.png";
import callHistoryLight from "../base/Assets/Icons/white/callHistory.png";
import followers from "../base/Assets/Icons/Grey/followers.png";
import followersLight from "../base/Assets/Icons/white/follower.png";
import following from "../base/Assets/Icons/Grey/following.png";
import followingLight from "../base/Assets/Icons/white/following.png";
import suspendedList from "../base/Assets/Icons/Grey/suspendedlist.png";
import suspendedListLight from "../base/Assets/Icons/white/suspendedList.png";
import warnedList from "../base/Assets/Icons/Grey/warnList.png";
import warnedListLight from "../base/Assets/Icons/white/warnedlist.png";
import blockList from "../base/Assets/Icons/Grey/blockList.png";
import blockListLight from "../base/Assets/Icons/white/blockList.png";

export const HostManagementData = [
  {
    name: "feedback",
    icon: feedBack,
    activeIcon: feedBackLight,
  },

  {
    name: "notification",
    icon: notification,
    activeIcon: notificationLight,
  },

  {
    name: "gifts",
    icon: gift,
    activeIcon: giftsLight,
  },

  {
    name: "moment",
    icon: moment,
    activeIcon: momentLight,
  },

  {
    name: "stickers",
    icon: sticker,
    activeIcon: stickerLight,
  },

  {
    name: "Earnings",
    icon: bucket,
    activeIcon: bucketLight,
  },

  {
    name: "report",
    icon: report,
    activeIcon: reportLight,
  },

  {
    name: "call history",
    icon: callHistory,
    activeIcon: callHistoryLight,
  },

  {
    name: "followers",
    icon: followers,
    activeIcon: followersLight,
  },

  {
    name: "following",
    icon: following,
    activeIcon: followingLight,
  },

  {
    name: "suspend List",
    icon: suspendedList,
    activeIcon: suspendedListLight,
  },

  {
    name: "warned List",
    icon: warnedList,
    activeIcon: warnedListLight,
  },

  {
    name: "block list",
    icon: blockList,
    activeIcon: blockListLight,
  },
  {
    name: "",
    icon: '',
    activeIcon: '',
  },
];
