import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { errorToast, successToast } from "../../../utils/toast";
import { checkLength } from "../../../utils/utility";

const HostVideoCallCharge = ({ id, getAcceptedHost, setShowChargeAlert }) => {
  // const [videoCallCharge, setVideoCallCharge] = useState("");
  const [hostuser_fees, setHostuser_fees] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();
  const[error,setError]=useState('')

  useEffect(() => {
    fetchVideoCallCharge();
  }, []);

  const fetchVideoCallCharge = () => {
    if(hostuser_fees===''){
      setError("This field is required")
      return;

    }
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETVIDEOCALLBALANCE +
        `/${id}`,
      "GET"
    )
      .then((res) => {
        setHostuser_fees(res.result);
// successToast(res.message)

        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  useEffect(()=>{
    if(hostuser_fees!==''){
      setError('')
    }
  },[hostuser_fees])
    // const handleAudioCallTime = () => {
    
  const handleHostLeader = () => {

    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.UPDATEHOSTVIDEOFEES +
        `/${id}`,
      "PUT",
      {
        hostuser_fees: hostuser_fees,
      }
    )
      .then((res) => {
        getAcceptedHost();
        setShowChargeAlert(false);
        loader.showLoader(false);
        successToast(res?.message);
      })
      .catch((err) => {
        errorToast(err?.message);
        loader.showLoader(false);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length<e.target.maxLength) {
      setError("Only numbers are allowed and the length should not exceed 10 digits");
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="premium__coin__container" style={{width:'350px'}}>
      <h2 className="premium__coin__heading">Set Video Charge(per second)</h2>
      <div className="premium__coin">
        <InputField
          value={hostuser_fees}
          placeholder="Set Random Call Time in sec"
          onChange={(e) => {
            setHostuser_fees(e.target.value.replace(/\D/g, ""));
          }}
          maxlength="5"
           type='number'
           onKeyDown={handleKeyDown}
          
         />
         {error && (
           <p className="error_alert">{error}</p>
         )}
        <br />
        <Button
          onClick={handleHostLeader}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default HostVideoCallCharge;
