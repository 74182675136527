import { useEffect, useState } from "react";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { checkLength } from "../../../utils/utility";

const Helpline = ({ helplineNumber, onSubmit }) => {
  const [mobileNumber, setMobileNumber] = useState("");
const[error,setError]=useState('')
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchHelplineNumber();
  }, []);

  const fetchHelplineNumber = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETHELPLINENUMBER,
      "GET"
    )
      .then((res) => {
        loader.showLoader(false);
        setMobileNumber(res.result);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  const isPhoneNumberValid = (phoneNumber) => {
    const regex = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;
    return regex.test(phoneNumber);
  };
  const handleCreateHelpline = () => {
    if(mobileNumber===''){
      setError('This field is Required')
    }

    // if(!isPhoneNumberValid(mobileNumber)){
    //   setError('Please Enter a Valid Number')
    //   return
    // }
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEHELPLINE,
      "PUT",
      {
        mobileNumber: mobileNumber,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        onSubmit();
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length<e.target.maxLength) {
      setError("Only numbers are allowed and the length should not exceed 10 digits");
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="helpline__container" style={{width:'350px'}}>
      <h2 className="helpline__header" style={{ textAlign: "center" }}>
        Helpline Number
      </h2>
      <div className="helpline__content">
        <InputField
          value={mobileNumber}
          type='number'
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
          maxlength='10'
        />
       {error && (
          <p className="error_alert">{error}</p>
        )}
        <br />
        <Button
          style={{ margin: "auto", cursor: "pointer" }}
          onClick={handleCreateHelpline}
          text={helplineNumber ? "Update" : "Create"}
        />
      </div>
    </div>
  );
};

export default Helpline;
