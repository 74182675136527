import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoader } from "../base/Context/loaderProvider";
import { useApi } from "../base/Context/apiProvider";
import Pagination from "../components/Pagination";
import Lottie from "react-lottie";
import noData from "../base/Animation/No Data Found.json";
import { NetworkConfiguration } from "../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../network/NetworkConnection";

const ShareHistory = () => {
  const [page, setPage] = useState(1);
  const [shareHistory, setShareHistory] = useState();
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const loader = useLoader();
  const apiProvider = useApi();
const {id}=useParams()

useEffect(()=>{
    fetchShareHistory()
},[])
  const fetchShareHistory = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SHAREHISTORY,
      "POST",
      {
        id:id,
        // startDate,
        // endDate,
        // key: value,
        // page,
        // perPage,
      },
    //   { appType: searchParams.get("appType") === "catchWoo" ? "catchWoo" : "" }
    )
      .then((res) => {
        loader.showLoader(false);
        setShareHistory(res.result)
        // set(res?.totalCount);
        // setTotalPages(res?.totalPages);
        // setGetGift(res.result);
      })

      .catch((err) => {
        loader.showLoader(false);
        // setGetGift([]);
      });
  };
  return (
    <>
      <div className="gift__container web_table_container">
        <table className="gift__table__container">
          <thead>
            <th className="gift__table__heading">S.No</th>
            <th className="gift__table__heading">User Id</th>

            <th className="gift__table__heading">User Name</th>

            <th className="gift__table__heading">No of Shares</th>
          </thead>

          <tbody>
            {shareHistory && shareHistory?.length && shareHistory?.length > 0
              ? shareHistory.map((data, index) => {
                  return (
                    <tr>
                      <td className="gift__table__body">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="gift__table__body">{data?.userId}</td>

                      <td className="gift__table__body">{data?.userName}</td>

                      <td className="gift__table__body">{data?.count}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!shareHistory
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
        {/* </div> */}

        

       
      </div>
      {shareHistory && shareHistory?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={setPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default ShareHistory;
