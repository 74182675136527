import "./style.css";
import InputField from "../../library/InputField";
import Button from "../../library/Button";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useEffect, useState } from "react";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { errorToast } from "../../../utils/toast";

const ChangePassword = ({ onSubmit }) => {
  const [eye, setEye] = useState("");
  const [eye1, setEye1] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [errors, setErrors] = useState({
    newPassord: "",
    password: "",
    confirmPassword: "",
  });
  const loader = useLoader();
  const apiProvider = useApi();

  const eyeIcon = () => {
    return eye ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType = () => {
    return eye ? "text" : "password";
  };

  const toHideShowPassword = () => {
    setEye(!eye);
  };

  const eyeIcon1 = () => {
    return eye1 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType1 = () => {
    return eye1 ? "text" : "password";
  };

  const toHideShowPassword1 = () => {
    setEye1(!eye1);
  };
  const handleChangePassword = () => {
    if(password===''){
      setErrors({
        ...errors,
        password:"This Field is required",
      });
      return; 
    }
    if (!validatePassword(newPassword)) {
      setErrors({
        ...errors,
        newPassord: "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.",
      });
      return;
    }

    if (conformPassword!==newPassword) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match." });
      return;
    }
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.CHANGEPASSWORD,
      "PUT",
      {
        password,
        newPassword,
        conformPassword,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        onSubmit();
      })
      .catch((err) => {
        errorToast(err.message)
        loader.showLoader(false);
      });
  };
  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(password);
  };
  useEffect(()=>{
    setErrors((prev) => ({
      ...prev,
      password: password!==''&&
         ""
    }));
  },[password])
  // }
  const newPasswordFieldChange = (e) => {
    setErrors({ ...errors, newPassword: "" });
    setNewPassword(e.target.value);
    setErrors((prev) => ({
      ...prev,
      newPassord: validatePassword(e.target.value)
        ? ""
        : "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character",
    }));
  };
  const onConfirmInputChange = (e) => {
    setErrors({ ...errors, confirmPassword: "" });
    const confirmPassword = e.target.value;
    setConformPassword(confirmPassword);
    setErrors((prev) => ({
      ...prev,
      confirmPassword:
        e.target.value !== newPassword ? "Passwords do not match." : "",
    }));
  };
  return (
    <div className="premium__coin__container" style={{ width: "450px" }}>
      <h2 className="premium__coin__heading">Change Password</h2>
      <div className="premium__coin">
        <InputField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Old Password"
        />
 {errors.password && (
          <p className="error_alert">{errors.password}</p>
        )}
        <br />
        <InputField
          onChange={newPasswordFieldChange}
          type={inputType()}
          icon={eyeIcon()}
          onEyeClick={toHideShowPassword}
          placeholder="New Password"
        />
        {errors.newPassord && (
          <p className="error_alert">{errors.newPassord}</p>
        )}
        <br />

        <InputField
          onChange={onConfirmInputChange}
          type={inputType1()}
          icon={eyeIcon1()}
          onEyeClick={toHideShowPassword1}
          placeholder="Confirm Password"
        />
          {errors.confirmPassword && (
          <p className="error_alert">{errors.confirmPassword}</p>
        )}
        <br />

        <Button
          style={{ margin: "auto" }}
          onClick={handleChangePassword}
          text="Update"
        />
      </div>
    </div>
  );
};

export default ChangePassword;
