import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { useEffect, useState } from "react";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { checkLength } from "../../../utils/utility";
import { successToast } from "../../../utils/toast";

const RandomCallCharge = ({ onSubmit }) => {
  const [randomCallCharge, setRandomCallCharge] = useState("");
  const[error,setError]=useState('')

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    handleSetRandomCallCharge();
  }, []);

  const handleSetRandomCallCharge = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.RANDOMCALLCHARGE,
      "GET"
    )
      .then((res) => {
        // successToast(res.message)


        loader.showLoader(false);
        setRandomCallCharge(res.result);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };
  useEffect(()=>{
    if(randomCallCharge!==''){
      setError('')
    }
  },[randomCallCharge])
   
  
  const handleEditChargeCoin = () => {
    
    if(randomCallCharge===''){
      setError("This field is required")
      return;
    }
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATERANDOMCOINS,
      "PUT",
      {
        randomCallCoins: randomCallCharge,
      }
    )
      .then((res) => {
        onSubmit();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleKeyDown = (e) => {
    const isValid = checkLength(e);
    if (!isValid && e.target.value.length<e.target.maxLength) {
      setError("Only numbers are allowed and the length should not exceed 10 digits");
    } else {
      setError(""); // Clear the error if the input is valid
    }
  };
  return (
    <div className="premium__coin__container" style={{width:'350px'}}>
      <h2 className="premium__coin__heading">Set Random Call Charge</h2>
      <div className="premium__coin">
        <InputField
          value={randomCallCharge}
          placeholder="Set Random Call Time in sec"
          onChange={(e) => {
            setRandomCallCharge(e.target.value.replace(/\D/g, ""));
          }}
        type='number'
          onKeyDown={handleKeyDown}
          maxlength="5"
        />
        {error && (
          <p className="error_alert">{error}</p>
        )}
        <br />
        <Button
          onClick={handleEditChargeCoin}
          text="Update"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default RandomCallCharge;
