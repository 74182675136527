import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Pagination from "../../Pagination";
import { useApi } from "../../../base/Context/apiProvider";

const UserBlockedList = () => {
  const { id } = useParams();
  const [getBlockedList, setGetBlockedList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchBlockList();
  }, [page, perPage, apiProvider?.getApiUrl()]);

  const fetchBlockList = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERBLOCKLIST,
      "POST",
      {
        id: id,
        page,
        perPage,
      }
    )
      .then((res) => {
        setGetBlockedList(res.result?.block);
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };
  return (
    <>
      <div className="user__block__list__container web_table_container">
        <table className="user__block__list__table">
          <thead>
            <th className="user__block__list__header">S.No.</th>
            <th className="user__block__list__header">Host ID</th>
            <th className="user__block__list__header">Host Name</th>
            <th className="user__block__list__header">Date Of Birth</th>
            <th className="user__block__list__header">Email</th>
            <th className="user__block__list__header">Mobile Number</th>
            <th className="user__block__list__header">Action</th>
          </thead>
          <tbody>
            {getBlockedList && getBlockedList?.length
              ? getBlockedList.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__block__list__data">{index + 1}</td>
                      <td className="user__block__list__data">
                        {data?.userId}
                      </td>
                      <td className="user__block__list__data">{data?.name}</td>
                      <td className="user__block__list__data">
                        {data?.dateOfBirth}
                      </td>
                      <td className="user__block__list__data">{data?.email}</td>
                      <td className="user__block__list__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="user__block__list__data">Block</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!getBlockedList?.length ? (
          <div className="block_no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "20rem", height: "20rem" }}
            />
            <p className="block__para">No Data Found</p>
          </div>
        ) : null}
      </div>
      {getBlockedList?.length > 0 ? (
        <Pagination
          page={page}
          perPage={perPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPage={setPage}
          setPerPage={setPerPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default UserBlockedList;
