import { createContext, useContext, useState } from "react";
import dashboard from "../Assets/Sidebar/dashboard.png";
import dashboardActive from "../Assets/Sidebar/Active/dashboard.png";
import allUsers from "../Assets/Sidebar/allUsers.png";
import allUsersActive from "../Assets/Sidebar/Active/allUsers.png";
import coinsActive from "../Assets/Sidebar/Active/coins.png";
import coins from "../Assets/Sidebar/coins.png";
import topGrowing from "../Assets/Sidebar/topGrowing.png";
import topGrowingActive from "../Assets/Sidebar/Active/topGrowing.png";
import moment from "../Assets/Sidebar/moments.png";
import momentActive from "../Assets/Sidebar/Active/moments.png";
import interestActive from "../Assets/Sidebar/Active/interest.png";
import interest from "../Assets/Sidebar/interests.png";
import Notifications from "../Assets/Sidebar/notifications.png";
import NOtificationsActive from "../Assets/Sidebar/Active/Notifications.png";
import Apk from "../Assets/Sidebar/apk.png";
import ApkActive from "../Assets/Sidebar/Active/apk.png";
import sendMails from "../Assets/Sidebar/sendMails.png";
import sendMailsActive from "../Assets/Sidebar/Active/sendMails.png";
import deletedAccountsActive from "../Assets/Sidebar/Active/deletedAccountss.png";
import deletedAccounts from "../Assets/Sidebar/deletedAccouonts.png";
import deletedMoments from "../Assets/Sidebar/deletedMoments.png";
import deletedMomentsActive from "../Assets/Sidebar/Active/deletedMOments.png";
import coinHistory from "../Assets/Sidebar/coinHistory.png";
import coinHistoryActive from "../Assets/Sidebar/Active/coinHistory.png";
import suspisiousDataActive from "../Assets/Sidebar/Active/suspiciousData.png";
import suspidiousData from "../Assets/Sidebar/suspiciousData.png";
import blockedDataActive from "../Assets/Sidebar/Active/blockedData.png";
import blockedData from "../Assets/Sidebar/blockeddata.png";
import contactForm from "../Assets/Sidebar/contactForm.png";
import contactFormActive from "../Assets/Sidebar/Active/contactForm.png";
import subAdmin from "../Assets/Sidebar/subAdmin.png";
import subAdminActive from "../Assets/Sidebar/Active/subAdmin.png";
import suspendedData from "../Assets/Sidebar/suspendedData.png";
import suspendedDataActive from "../Assets/Sidebar/Active/suspendedData.png";
import WarnedDataLight from "../Assets/Sidebar/Active/warnedData.png";
import WarnedData from "../Assets/Sidebar/WarnedData.png";
import callHistory from "../Assets/Sidebar/callHistory.png";
import callHistoryLight from "../Assets/Sidebar/Active/CallHistory.png";
import banner from "../Assets/Sidebar/banner.png";
import bannerLight from "../Assets/Sidebar/Active/Banner.png";
import hostRequest from "../Assets/Sidebar/hostRequest.png";
import hostRequestLight from "../Assets/Sidebar/Active/hostRequest.png";
import acceptedHost from "../Assets/Sidebar/acceptedHost.png";
import acceptedHostLight from "../Assets/Sidebar/Active/acceptedHOst.png";
import rejectedHost from "../Assets/Sidebar/rejectedHost.png";
import rejectedHostLight from "../Assets/Sidebar/Active/rejectedHost.png";

import { useEffect } from "react";

export const sidebarData = createContext();

const SidebarData = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: dashboard,
    activeIcon: dashboardActive,
  },

  {
    label: "All Users",
    link: "/allusers",
    icon: allUsers,
    activeIcon: allUsersActive,
  },

  {
    label: "Coins",
    icon: coins,
    activeIcon: coinsActive,
    subMenus: [
      {
        label: "Coin Recharge",
        link: "/coinrecharge",
      },
      {
        label: "Gift",
        link: "/gift",
      },
      {
        label: "Sticker",
        link: "/sticker",
      },

      {
        label: "Flower",
        link: "/flower",
      },
    ],
  },

  {
    label: "Host Request",
    link: "/hostrequest",
    icon: hostRequest,
    activeIcon: hostRequestLight,
  },

  {
    label: "Accepted Host",
    link: "/acceptedhost",
    icon: acceptedHost,
    activeIcon: acceptedHostLight,
  },

  {
    label: "Rejected Host",
    link: "/rejectedhost",
    icon: rejectedHost,
    activeIcon: rejectedHostLight,
  },

  {
    label: "Leader",
    link: "/leader",
    icon: deletedAccounts,
    activeIcon: deletedAccountsActive,
  },

  {
    label: "Suspended User",
    link: "/suspendeddata",
    icon: suspendedData,
    activeIcon: suspendedDataActive,
  },

  {
    label: "Warned User",
    link: "/warneddata",
    icon: WarnedData,
    activeIcon: WarnedDataLight,
  },

  {
    label: "Banner",
    link: "/banner",
    icon: banner,
    activeIcon: bannerLight,
  },

  {
    label: "All Call History",
    link: "/allcallhistory",
    icon: callHistory,
    activeIcon: callHistoryLight,
  },

  {
    label: "Report & Feedback",
    icon: WarnedData,
    activeIcon: WarnedDataLight,
    subMenus: [
      {
        label: "Report & Spam",
        link: "/report&spam",
      },
      {
        label: "Feedback",
        link: "/feedback",
      },
      
    ],
  },
  {
    label: "Top Growing",
    link: "/topgrowing",
    icon: topGrowing,
    activeIcon: topGrowingActive,
  },

  {
    label: "Moment",
    link: "/moment",
    icon: moment,
    activeIcon: momentActive,
  },

  {
    label: "Interest",
    link: "/interest",
    icon: interest,
    activeIcon: interestActive,
  },

  {
    label: "Suspicious Data",
    link: "/suspiciousdata",
    icon: suspidiousData,
    activeIcon: suspisiousDataActive,
  },

  {
    label: "Coin History",
    link: "/coinHistory",
    icon: coinHistory,
    activeIcon: coinHistoryActive,
  },

  {
    label: "Contact Form",
    link: "/contactform",
    icon: contactForm,
    activeIcon: contactFormActive,
  },

  {
    label: "Send Mails",
    link: "/sendmails",
    icon: sendMails,
    activeIcon: sendMailsActive,
  },

  {
    label: "APK",
    icon: Apk,
    activeIcon: ApkActive,
    subMenus: [
      {
        label: "APK Requests",
        link: "/apkrequests",
      },

      {
        label: "APK List",
        link: "/apklist",
      },
    ],
  },

  {
    label: "Blocked Data",
    link: "/blockdata",
    icon: blockedData,
    activeIcon: blockedDataActive,
  },

  {
    label: "Deleted Accounts",
    link: "/deletedaccounts",
    icon: deletedAccounts,
    activeIcon: deletedAccountsActive,
  },

  {
    label: "Deleted Moments",
    link: "/deletedmoments",
    icon: deletedMoments,
    activeIcon: deletedMomentsActive,
  },

  {
    label: "Sub-Admin",
    link: "/sub-Admin",
    icon: subAdmin,
    activeIcon: subAdminActive,
  },

  {
    label: "Notification",
    link: "/notification",
    icon: Notifications,
    activeIcon: NOtificationsActive,
  },
];

const RemoveCathwooItem = [
  "Host Request",
  "Accepted Host",
  "Rejected Host",
  "APK",
  "Leader",
];
const RemoveHostItem = [
  "All Users",
  "Contact Form",
  "Leader",
  "Contact Form",
  // "Gift",
  // "Flower",
  ,
];
const RemoveShudhLoveItem = ["Host Request", "Accepted Host", "Rejected Host"];
const RemoveHostSubMenus = ["Coin Recharge"];

const SidebarProvider = ({ children }) => {
  const [sidebarContent, setSidebarContent] = useState(SidebarData);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    setSelectedType(sessionStorage.getItem("selectedType"));
  }, []);

  const [finalSideBar, setFinalSidebar] = useState([]);

  useEffect(() => {
    if (selectedType === "catchwoo") {
      setFinalSidebar(
        SidebarData.filter((item) => {
          return !RemoveCathwooItem.includes(item.label);
        })
      );
    } else if (selectedType === "host") {
      setFinalSidebar(
        SidebarData.filter((item) => {
          if (item.subMenus) {
            item.subMenus = item.subMenus.filter((subItem) => {
              return !RemoveHostSubMenus.includes(subItem.label);
            });
          }
          return !RemoveHostItem.includes(item.label);
        })
      );
    } else {
      setFinalSidebar(
        SidebarData.filter((item) => {
          return !RemoveShudhLoveItem.includes(item.label);
        })
      );
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedType === "catchwoo") {
      const updatedSidebar = finalSideBar?.map((sidebar) => ({
        ...sidebar,
        link: `${sidebar?.link}?appType=catchwoo`,
        subMenus: sidebar?.subMenus?.map((item) => ({
          ...item,
          link: `${item?.link}?appType=catchwoo`,
          icon: item.icon,
          activeIcon: item.activeIcon,
        })),
      }));
      setSidebarContent(updatedSidebar);
    } else if (selectedType === "host") {
      const updatedSidebar = finalSideBar?.map((sidebar) => ({
        ...sidebar,
        link: `${sidebar?.link}?appType=host`,
        subMenus: sidebar?.subMenus?.map((item) => ({
          ...item,
          link: `${item?.link}?appType=host`,
          icon: item.icon,
          activeIcon: item.activeIcon,
        })),
      }));
      setSidebarContent(updatedSidebar);
    } else {
      setSidebarContent(finalSideBar);
    }
  }, [finalSideBar]);

  return (
    <sidebarData.Provider
      value={{ sidebarContent, setSidebarContent, setSelectedType }}
    >
      {children}
    </sidebarData.Provider>
  );
};

export const useSidebar = () => useContext(sidebarData);
export default SidebarProvider;
