import "./style.css";

const Button = (props) => {
  
  return (
    <button
      className={`button__style ${props.className}`}
      onClick={props.onClick}
      style={props.style}
      type={props.type}
    >
      <p className={`button__text ${props.className}`} style={{fontSize:'12px',fontWeight:'bold'}} >
        {props.text}
      </p>
    </button>
  );
};

export default Button;
