import React, { useState } from "react";
import { MdImage } from "react-icons/md";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";


export default function   MailList({ mails }) {
  
  return (
    <div style={{height:'70%',overflow:'auto'}}>
      {mails?.map((item, index) => {
        return <MailItem mail={item} key={index} />;
      })}
    </div>
  );
}

const MailItem = ({ mail }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleMailDetails = () => {
    navigate(`${searchParams.get("appType") ? `/mails/mailsList/mailDetails/?id=${mail._id}?appType=` + searchParams.get("appType") : `/mails/mailsList/mailDetails/?id=${mail._id}` }`);
  };

  const dateval = new Date();

  return (
    <div className="mail_item_bg" onClick={handleMailDetails}>
      <div className="mails_row">
        <div>
          <p className="mail_item_to">
            {mail?.emails?.map((item) => {
              return item?.split("@")[0] + ", ";
            })}
          </p>
          <p className="mail_item_title">{mail?.subject} - </p>
          <p className="mail_item_message">{mail?.message}</p>
        </div>

        <p className="mail_time">
          {moment(dateval).format("Do MMM YYYY") ===
          moment(mail?.createdAt).format("Do MMM YYYY")
            ? moment(mail?.createdAt).format("hh:mm a")
            : moment(mail?.createdAt).format("Do MM YYYY")}
        </p>
      </div>

      {mail?.fileNames?.length ? (
        <div className="mail_files ">
          {mail?.fileNames?.map((item, index) => {
            return (
              <div className="mail_file">
                <div>
                  <MdImage />
                </div>
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
