import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { useEffect, useState } from "react";
import { useLoader } from "../../../base/Context/loaderProvider";

const HostEditForm = ({ id, setEditForm, getAcceptedHost }) => {
  const [host, setHost] = useState({
    name: "",
    gender: "",
    dateOfBirth: "",
    mobileNumber: "",
    email: "",
    country: "",
    state: "",
    city: "",
    bio: "",
    proffession: "",
  });

  const [reason, setReason] = useState("");
  const [error, setError] = useState({
    reasonError: "",
  });
  const apiProvider = useApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const loader = useLoader();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHost({ ...host, [name]: value });
  };

  const fetchOneHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETONEHOST + `/${id}`,
      "GET"
    )
      .then((res) => {
        setHost({
          ...host,
          name: res?.result?.name,
          gender: res?.result?.gender,
          dateOfBirth: res?.result?.dateOfBirth,
          mobileNumber: res?.result?.mobileNumber,
          email: res?.result?.email,
          country: res?.result?.country,
          state: res?.result?.state,
          city: res?.result?.city,
          bio: res?.result?.addBio,
          proffession: res?.result?.proffession,
          reason:res?.result?.reasionUpdateProfile
        });
        setReason(res?.result?.reasionUpdateProfile)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = () => {
    let result = true;
    if (!reason) {
      setError({ ...error, reasonError: "Enter reason to change profile" });
      result = false;
    }
    return result;
  };

  useEffect(() => {
    fetchOneHost();
  }, []);

  const handleEditHost = () => {
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.EDITHOST,
        "PUT",
        {
          id,
          ...host,
          reasionUpdateProfile: reason,
        }
      )
        .then((res) => {
          console.log(res);
          loader.showLoader(false);
          navigate(
            searchParams.get("appType") === "host"
              ? `/acceptedhost/hostmanagement/${id}?appType=` +
                  searchParams.get("appType")
              : ""
          );
          setEditForm(false);
          getAcceptedHost();
        })
        .catch((err) => {
          loader.showLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <div className="user__form__container">
      <h2 className="user__form__heading ">Edit Host Details</h2>
      <div className="user__form__content">
        <InputField value={host?.name} onChange={handleChange} name="name" placeholder='Name'/>
        <InputField
          onChange={handleChange}
          value={host?.gender}
          name="gender"
          placeholder='Gender'
        />
        <InputField
          value={host?.dateOfBirth}
          onChange={handleChange}
          name="dateOfBirth"
          placeholder='Date Of Birth'

        />
        <InputField
          value={host?.mobileNumber}
          onChange={handleChange}
          name="mobileNumber"
          placeholder='Mobile Number'

        />

        <InputField value={host?.email} onChange={handleChange} name="email" />
        <InputField
          value={host?.country}
          onChange={handleChange}
          name="country"
          placeholder='Country'

        />
        <InputField value={host?.state} onChange={handleChange} name="state" />
        <InputField value={host?.city} onChange={handleChange} name="city" />
        <InputField
          value={host?.proffession}
          onChange={handleChange}
          name="proffession"
          placeholder='Proffession'

        />

        <InputField value={host?.bio} onChange={handleChange} name="bio" />
        <InputField
          placeholder="Reason for changing information"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
            setError({ ...error, reasonError: "" });
          }}
          name="reason"
          maxlength="50"
          error={error.reasonError}
        />
        <div>
          <br />
          <Button
            style={{ margin: "auto" }}
            onClick={handleEditHost}
            text="Update"
          />
        </div>
      </div>
      {/* <Form */}
    </div>
  );
};

export default HostEditForm;
