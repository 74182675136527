import { useEffect, useState } from "react";
import { useApi } from "../../base/Context/apiProvider";
import { useLoader } from "../../base/Context/loaderProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import "./style.css";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import Button from "../../components/library/Button";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import { BsFillEyeFill } from "react-icons/bs";
import ImagePopUpModal from "../../components/ImagePopUpModal";

const DeletedAccount = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [deletedAccount, setDeletedAccount] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [img, setImg] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    deletedAccount.map((item, index) => {
      let obj = {
        "S.No": index + 1,

        ID: item?._id,
        Name: item?.name,
        Email: item?.email,
        "Mobile Number": item?.mobileNumber,
        Comment: item?.comment,
        "Deleted At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [deletedAccount]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Deleted Account List Report";
    const headers = [
      ["ID", "Name", "Email", "Mobile Number", "Comment", "Deleted At"],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1) => [
      item1["ID"],
      item1["Name"],
      item1["Email"],
      item1["Mobile Number"],
      item1?.Comment,
      item1["Deleted At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Deleted Accounts Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchDeletedAccount();
  }, [page, perPage, value, apiProvider?.apiUrl, endDate]);

  const fetchDeletedAccount = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      searchParams.get("appType") === "host"
        ? apiProvider?.getApiUrl() + NetworkConfiguration.DELETEDHOSTACCOUNT
        : apiProvider?.getApiUrl() + NetworkConfiguration.DELETEDACCOUNT,
      "POST",
      {
        key: value,
        startDate,
        endDate,
        page,
        perPage,
        appType: searchParams.get("appType") === "host" ? "host" : "user",
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setDeletedAccount(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
        setDeletedAccount([]);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleSearchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportPdf = () => {
    exportToPDF(exportAllData);
  };

  const handleExportCsv = () => {
    const filename =`Deleted Accounts Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  const handleEyeProfilePicPopUpClose = () => {
    setShowProfileAlert(false);
  };
  const handleExportData = () => {
    const filename =`Deleted Accounts Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };
  const handleEyeProfilePicPopUp = (img) => {
    setShowProfileAlert(true);
    setImg(img);
  };
  return (
    <>
      <SearchInput
        placeholder="Search"
        onChange={handleText}
        value={value}
        icon={handleSearchIcon()}
      />
      <div className="accepted_host_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="deleted_account_container web_table_container">
        <table className="deleted_account_table">
          <thead>
            <th className="deleted_account_header">S.No</th>
            <th className="deleted_account_header">ID</th>
            <th className="deleted_account_header">Name</th>
            <th className="deleted_account_header">Email</th>
            <th className="deleted_account_header">Mobile Number</th>
            <th className="deleted_account_header">Comment</th>
            <th className="deleted_account_header">Profile Pic</th>

            <th className="deleted_account_header">Deleted At</th>
          </thead>
          <tbody>
            {deletedAccount && deletedAccount.length
              ? deletedAccount.map((data, index) => {
                  return (
                    <tr>
                      {data?.appType === "host" ? (
                        <>
                          <td className="deleted_account_data"> {index + 1}</td>
                          <td className="deleted_account_data">
                            {data?.userId?data?.userId:'-'}
                          </td>
                          <td className="deleted_account_data">{data?.name}</td>
                          <td className="deleted_account_data">
                            {data?.email?data?.email:'-'}
                          </td>
                          <td className="deleted_account_data">
                            {data?.mobileNumber?data?.mobileNumber:'-'}
                          </td>
                          <td className="deleted_account_data">
                            {data?.comment?data?.comment:'-'}
                          </td>
                          <td className="user__request__data">
                        {data?.profilePic ? (
                          <BsFillEyeFill
                            onClick={() => {
                              handleEyeProfilePicPopUp(data?.profilePic);
                            }}
                            className="user__request__eye__icon"
                          />
                        ):'No-pic'}
                      </td>
                          <td className="deleted_account_data">
                            {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                          </td>
                        </>
                      ) : (
                        <>
                          {" "}
                          <td className="deleted_account_data"> {index + 1}</td>
                          <td className="deleted_account_data">
                            {data?.userId?data?.userId:''}
                          </td>
                          <td className="deleted_account_data">{data?.name}</td>
                          <td className="deleted_account_data">
                            {data?.email?data?.email:''}
                          </td>
                          <td className="deleted_account_data">
                            {data?.mobileNumber?data?.mobileNumber:'-'}
                          </td>
                          <td className="deleted_account_data">
                            {data?.comment?data?.comment:'-'}{" "}
                          </td>
                          <td className="user__request__data">
                        {data?.profilePic ? (
                          <BsFillEyeFill
                            onClick={() => {
                              handleEyeProfilePicPopUp(data?.profilePic);
                            }}
                            className="user__request__eye__icon"
                          />
                        ):'No-pic'}
                      </td>
                          <td className="deleted_account_data">
                            {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!deletedAccount.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      <ImagePopUpModal
        open={showProfileAlert}
        handleClose={handleEyeProfilePicPopUpClose}
        img={img}
      />
      {deletedAccount.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default DeletedAccount;
