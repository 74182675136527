import { useEffect, useState } from "react";
import SearchInput from "../../SearchInput";
import Button from "../../library/Button";
import "./style.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import moment from "moment";
import Pagination from "../../Pagination";
import FormAlertPopUp from "../../FormAlertPopUp";
import InterestForm from "../../formComponents/InterestForm";
import AlertPopUp from "../../AlertPopUp";
import { errorToast, successToast } from "../../../utils/toast";
import { useApi } from "../../../base/Context/apiProvider";
import { FiSearch } from "react-icons/fi";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import TablePopUp from "./Poptable";

const InterestTable = () => {
  const [interestData, setInterestData] = useState([]);
  const[data,setData]=useState([])
  const[showUser,setShowUser]=useState(false)

  const [showInterestForm, setShowInterestForm] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const[fileNameData,setFileNameData]=useState('')
  const [totalPages, setTotalPages] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [id, setId] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const apiProvider = useApi();
  const handleViewHostData = (data,name,userId) => {
    setShowUser(true);
    setFileNameData((prev)=>({...prev,name:name,id:userId}))
    setData(data);
  };

  const handleViewHostDataClose = () => {
    setShowUser(false);
  };
  useEffect(() => {
    let array = [];
    interestData.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "Interest Name": item?.name,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Updated At": moment(item?.updatedAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [interestData]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Interests Report";
    const headers = [["S.No.", "Interest Name", "Created At", "Updated At"]];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Interest Name"],
      item1["Created At"],
      item1["Updated At"],

      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Interest Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleEditAlert = (id, name) => {
    setShowEditForm(true);
    setId(id);
    setName(name);
  };

  const handleEditAlertClose = () => {
    setShowEditForm(false);
  };

  const handleDeleteAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleAddInterest = () => {
    setShowInterestForm(true);
  };

  const handleAddInterestClose = (id) => {
    setShowInterestForm(false);
    setId(id);
  };

  useEffect(() => {
    fetchInterestData();
  }, [apiProvider?.getApiUrl(), page, perPage, value, endDate]);

  const fetchInterestData = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETINTEREST,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setInterestData(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setInterestData([]);
      });
  };

  const onSubmit = () => {
    setShowInterestForm();
    fetchInterestData();
  };

  const handleDeleteApi = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEINTEREST + `/${id}`,
      "DELETE",
      {
        name: interestData,
      }
    )
      .then((res) => {
        successToast(res?.message);
        console.log(res);
        setShowDeleteAlert(false);
        fetchInterestData();
      })
      .catch((err) => {
        errorToast(err?.message);
        console.log(err);
      });
  };

  const onEdit = () => {
    setShowEditForm(false);
    fetchInterestData();
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Interest Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Interest Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        onChange={handleText}
        value={value}
        icon={searchIcon()}
        placeholder="Search"
      />
      <div className="add__wallet">
        {/* <div className="moment_btn"> */}
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
        {/* </div> */}
        <Button
          style={{ textAlign: "center" }}
          text="Add Interest"
          onClick={handleAddInterest}
        />
      </div>

      <div className="interest__container web_table_container">
        <table className="interest__table">
          <thead>
            <th className="interest__header">S.No</th>
            <th className="interest__header">Interest Name</th>
            <th className="interest__header">People</th>

            <th className="interest__header">Created At</th>
            <th className="interest__header">Updated At</th>
            <th className="interest__header">Actions</th>
          </thead>
          <tbody>
            {interestData.map((data, index) => {
              return (
                <tr>
                  <td className="interest__data">
                    {(page - 1) * perPage + index + 1}
                  </td>
                  <td className="interest__data">{data?.name}</td>
                  <td className="interest__data" onClick={()=>handleViewHostData(data?.users,data?.name)}>View</td>

                  <td className="interest__data">
                    {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                  </td>
                  <td className="interest__data">
                    {moment(data?.updatedAt).format("DD/MM/YYYY , LT")}
                  </td>
                  <td className="interest__data">
                    <AiFillEdit
                      onClick={() => handleEditAlert(data?._id, data?.name)}
                      className="interest__edit__icon"
                    />
                    <AiFillDelete
                      onClick={() => handleDeleteAlert(data?._id)}
                      className="interest__delete__icon"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!interestData?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>

      <FormAlertPopUp
        open={showEditForm}
        handleOpen={handleAddInterest}
        onRequestClose={handleEditAlertClose}
      >
        {" "}
        <InterestForm
          name={name}
          onEdit={onEdit}
          id={id}
          setName={setName}
          edit={true}
        />
      </FormAlertPopUp>

      <AlertPopUp
        open={showDeleteAlert}
        handleClose={handleDeleteAlertClose}
        handleOpen={handleDeleteAlert}
        header="Delete Alert"
        description="Are you sure you want to delete this interest?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDeleteApi}
        onCancelClick={handleDeleteAlertClose}
      />

      <FormAlertPopUp
        open={showInterestForm}
        handleOpen={handleAddInterest}
        onRequestClose={handleAddInterestClose}
      >
        <InterestForm onSubmit={onSubmit} />
      </FormAlertPopUp>

      {interestData?.length ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
       {data ? (
          <TablePopUp
            open={showUser}
            handleClose={handleViewHostDataClose}
            data={data}
            fileNameData={fileNameData}
          />
        ) : (
          ""
        )}
    </>
  );
};

export default InterestTable;
