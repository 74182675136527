import { useContext, useEffect, useState } from "react";
import "./style.css";

import AlertPopUp from "../../AlertPopUp";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { AiFillEye } from "react-icons/ai";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Pagination from "../../Pagination";
import noData from "../../../base/Animation/No Data Found.json";
import Lottie from "react-lottie";
import { Modal } from "../../../base/Context/modalProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import moment from "moment";
import Button from "../../library/Button";
import jsPDF from "jspdf";

const HostFeedbackTable = () => {
  const [hostFeedback, setHostFeeback] = useState([]);
  const [showRevertAlert, setShowRevertAlert] = useState(false);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [img, setImg] = useState("");
  const [getId, setGetId] = useState("");
  const [replyFeedback, setReplyFeedback] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const loader = useLoader();
  const modalProvider = useContext(Modal);
  const apiProvider = useApi();
  const { id } = useParams();

  useEffect(() => {
    let array = [];
    hostFeedback.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "Host ID": item?.hostId?.userId,
        "Host Name": item?.hostId?.name,
        Title: item?.feedbackType,
        Description: item?.comment,
        "Image/Video": item?.feedbackImage,
        "Contact Details": item?.contact || item?.email,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Trackstatus":item?.trackStatus[item?.trackStatus?.length - 1]?.title,
        "Revert Back": item?.trackStatus[1]?.response,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [hostFeedback]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Feedback List Report";
    const headers = [
      [
        "S.No.",
        "Host ID",
        "Host Name",
        "Title",
        "Description",
        "Image/Video",
        "Contact Details",
        "Created At",
        'Trackstatus',
        "Revert Back",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Host ID"],
      item1["Host Name"],
      item1["Title"],
      item1["Description"],
      item1["Image/Video"],
      item1["Contact Details"],
      item1["Created At"],
      item1['Trackstatus'],
      item1["Revert Back"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Host Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleOnClickAlert = (img) => {
    setImg(img);
    setShowImageAlert(true);
  };

  const handleOnClickAlertClose = () => {
    setShowImageAlert(false);
  };

  const handleHostFeedbackRevert = (getId) => {
    setShowRevertAlert(true);
    setGetId(getId);
  };

  const handleHostFeedbackRevertClose = () => {
    setShowRevertAlert(false);
  };

  const handleReply = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.SENDREPLYHOST +
        `/${getId}`,
      "POST",
      {
        response: replyFeedback,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowRevertAlert(false);
        getAllHostsFeedback();
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  const getAllHostsFeedback = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETHOSTFEEDBACK,
      "POST",
      {
        hostId: id,
        startDate,
        endDate,
        key: value,
        page: page,
        perPage: perPage,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setHostFeeback(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setHostFeeback([]);
      });
  };

  useEffect(() => {
    getAllHostsFeedback();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleExportData = () => {
    const filename =`Host Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleCloseClick = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.SENDREPLYHOST +
        `/${getId}`,
      "POST",
      {
        response: replyFeedback,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowRevertAlert(false);
        getAllHostsFeedback();
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleExportCsv = () => {
    const filename =`Host Feedback Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
const navigate=useNavigate()
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="host_feedback_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>

        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="host__feedback__container web_table_container">
        {/* <div className="table_parent_box"> */}
        <table className="host__feedback__table__container">
          <thead>
            <th className="host__feedback__table__heading">S.No.</th>
            {!id ? (
              <>
                <th className="host__feedback__table__heading">Host ID</th>
                <th className="host__feedback__table__heading">Host Name</th>
              </>
            ) : null}

            <th className="host__feedback__table__heading">Title</th>
            <th className="host__feedback__table__heading">Description</th>
            <th className="host__feedback__table__heading">Image/Video</th>
            <th className="host__feedback__table__heading">Contact Details</th>
            <th className="host__feedback__table__heading">Created At</th>
            <th className="host__feedback__table__heading"> Track Status </th>
            <th className="host__feedback__table__heading">Revert Back</th>
            <th className="host__feedback__table__heading">Close Feedback </th>
          </thead>
          <tbody>
            {hostFeedback?.length > 0 &&
              hostFeedback.map((data, index) => {
                return (
                  <tr>
                    <td className="host__feedback__table__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    {!id && (
                      <>
                        <td className="host__feedback__table__data">
                          {data?.hostId?.userId?data?.hostId?.userId:'Deleted-user'}
                        </td>
                        <td className="host__feedback__table__data">
                          {data?.hostId?.name?data?.hostId?.name:'Deleted-User'}
                        </td>
                      </>
                    )}
                    <td className="host__feedback__table__data">
                      {data?.feedbackType}
                    </td>
                    <td className="host__feedback__table__data">
                      <div
                        className="feedback__table__comment"
                        onClick={
                          data?.comment?.length > 12
                            ? () =>
                                modalProvider.handleCommentClick(
                                  data?.comment,
                                  "Description"
                                )
                            : () => {}
                        }
                      >
                        {data?.comment}
                      </div>
                    </td>
                    <td className="host__feedback__table__data">
                      <AiFillEye
                        onClick={() => {
                          handleOnClickAlert(data?.feedbackImage);
                        }}
                        className="host__feedback__eye__icon"
                      />
                    </td>
                    <td className="host__feedback__table__data">
                      {data?.contact || data?.email}
                    </td>
                    <td className="host__feedback__table__data">
                      {moment(data?.createdAt).format("DD/MM/YYYY LT")}
                    </td>
                    <td className="host__feedback__table__data">
                      {data?.trackStatus[data?.trackStatus?.length - 1]?.title}
                    </td>
                    {data?.trackStatus[1]?.response ? (
                      <td className="host__feedback__table__data host__feedback__view__btn">
                        <div
                          className="feedback__table__comment"
                          // onClick={
                          //   data?.trackStatus[1]?.response?.length > 12
                          //     ? () =>
                          //         modalProvider?.handleCommentClick(
                          //           data?.trackStatus[1]?.response,
                          //           "Revert"
                          //         )
                          //     : () => {}
                          // }
                          onClick={() =>navigate(`/chat/${data._id}`, {
                            state: { userId: data?.hostId?._id ,description:data?.comment,status:data?.trackStatus[data?.trackStatus?.length - 1]?.title,feedBackType:data?.feedbackType}
                          })}
                        >
                          {data?.trackStatus[1]?.response}
                        </div>
                      </td>
                    ) : (
                      <td
                        onClick={() =>navigate(`/chat/${data._id}`, {
                          state: { userId: data?.hostId?._id ,description:data?.comment,status:data?.trackStatus[data?.trackStatus?.length - 1]?.title,feedBackType:data?.feedbackType}
                        })}
                        className="host__feedback__table__data host__feedback__view__btn"
                      >
                        Reply
                      </td>
                    )}{" "}
                    <td className="host__feedback__table__data host_feedback_column">
                      {data?.trackStatus &&
                       data?.trackStatus[data?.trackStatus?.length - 1]
                       ?.title === "Closed" ? (
                        <p className="host_feedback_btn_disable">Close</p>
                      ) : (
                        <p
                          className="host_feedback_btn_click"
                          onClick={() => handleCloseClick(data?._id)}
                        >
                          Close
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!hostFeedback?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {hostFeedback && hostFeedback?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[5, 10, 15, 20]}
        />
      ) : null}

      <AlertPopUp
        open={showRevertAlert}
        handleOpen={handleHostFeedbackRevert}
        handleClose={handleHostFeedbackRevertClose}
        textField={true}
        submitText="Submit"
        header="Feedback Revert"
        onSubmitClick={handleReply}
        onCancelClick={handleHostFeedbackRevertClose}
        cancelText="Cancel"
        onChangeField={(e) => setReplyFeedback(e.target.value)}
      />

      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleOnClickAlertClose}
        images={img}
      />
    </>
  );
};

export default HostFeedbackTable;
