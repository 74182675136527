import { useEffect, useState } from "react";
import { useLoader } from "../../base/Context/loaderProvider";
import SearchInput from "../../components/SearchInput";
import Button from "../../components/library/Button";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import { useApi } from "../../base/Context/apiProvider";
import Lottie from "react-lottie";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import noData from "../../base/Animation/No Data Found.json";
import moment from "moment";
import InactiveUserForm from "../../components/formComponents/InactiveUserForm";
import BulkPopup from "../../components/sendMails/bulkPopup";
import MailPopup from "../../components/sendMails/mailPopup";

const OfflineUser = () => {
  const apiProvider = useApi();
  const [bulkMail, setBulkMail] = useState(false);
  const [mail, setMail] = useState(false);
  const [activeUser, setActiveUser] = useState([]);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [value, setValue] = useState("");

  const loader = useLoader();
  useEffect(() => {
    fetchActiveUser();
  }, [apiProvider?.getApiUrl(), page, perPage, value]);

  const fetchActiveUser = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.OFFLINEUSER,
      "POST",
      {
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        console.log(res?.data, "data  ");
        setActiveUser(res?.data);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err, "err========");
      });
  };

  const handleActiveUser = () => {
    if (data.length > 0) {
      setShowNotificationPopup(true);
    }
  };

  const handleActiveUserClose = () => {
    setShowNotificationPopup(false);
  };

  const handleChange = (value) => {
    console.log(value, "datachanged", data);
    const itemExists = data.some((item) => item.id === value._id);
    if (itemExists) {
      const filteredData = data.filter((item) => item.id !== value._id);
      setData(
        filteredData.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      );
    } else {
      setData([...data, { id: value._id, name: value.name }]);
    }
    console.log(data,'noto')
  };

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let activeUserId = activeUser.map((id) => {
        return id?._id;
      });
      setAllData(activeUserId);
      setCheckHeaderClick(true);
      setData([...activeUserId  ]);
    } else {
      setCheckHeaderClick(false);
      setAllData([]);
    }
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };
  const handleSendBulkMail = () => {
    setBulkMail(true);
    setMail(false);
  };
  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />

      <div className="active__user" style={{padding:'5px'}}>
        <Button
          onClick={handleSendBulkMail}
          text="Bulk Mail"
          className="header_btn"
          type="button"
          style={{ textAlign: "center", marginRight: "20px" }}
        />
        <Button
          
          text="Send Notification"
          onClick={handleActiveUser}
          style={{ textAlign: "center" }}
        />
      </div>
      <div className="active__user__container web_table_container">
        <div className="table_parent_box ">
          <table className="active__user__table">
            <thead>
              <th className="active__user__header">
                <input
                  className="active__user__input"
                  type="checkbox"
                  onChange={handleAllChecked}
                />
              </th>
              <th className="active__user__header">S.No.</th>
              <th className="active__user__header">User ID</th>
              <th className="active__user__header">Name</th>
              <th className="active__user__header">Email</th>
              <th className="active__user__header">Mobile Number</th>
              <th className="active__user__header">Created At</th>
            </thead>
            <tbody>
              {activeUser && activeUser.length > 0 ? (
                activeUser.map((data, index) => {
                  return (
                    <tr>
                      <td className="active__user__data">
                        <input
                          onChange={() => handleChange(data)}
                          value={data._id}
                          className="active__user__input"
                          type="checkbox"
                          {...(checkHeaderClick ? { checked: true } : {})}
                        />
                      </td>
                      <td className="active__user__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="active__user__data">{data?.userId}</td>
                      <td className="active__user__data">{data?.name}</td>
                      <td className="active__user__data">{data?.email}</td>
                      <td className="active__user__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="active__user__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
        </div>

        {!activeUser.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
        <div className="send_mail_box">
          {bulkMail ? (
            <BulkPopup
              show={bulkMail}
              setShow={setBulkMail}
              // handleMailList={handleMails}
              users={data}
            />
          ) : null}
          {mail ? (
            <MailPopup
              show={mail}
              setShow={setMail}
              // handleMailList={handleMails}
            />
          ) : null}
        </div>

        <FormAlertPopUp
          open={showNotificationPopup}
          onRequestClose={handleActiveUserClose}
        >
          <InactiveUserForm
            allData={allData}
            data={data}
            user={true}
            setData={setData}
            setAllData={setAllData}
            setShowNotificationPopup={setShowNotificationPopup}
            checkHeaderClick={checkHeaderClick}
          />
        </FormAlertPopUp>
      </div>
      {activeUser && activeUser.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[5, 10, 15, 20]}
        />
      ) : null}
    </>
  );
};

export default OfflineUser;
