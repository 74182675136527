import { useContext, useState } from "react";
import { Modal } from "../../../base/Context/modalProvider";
import "./style.css";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Button from "../../library/Button";

const TopTalentTable = ({ tableData, isHost, page, perPage }) => {
  const modalProvider = useContext(Modal);

  return (
    <div className="top__talent__table">
      {/* <div className="top__talent__container web_table_container"> */}
      <table className="">
        <thead>
          <th className="top__talent__header">Rank No.</th>
          <th className="top__talent__header">User ID</th>
          <th className="top__talent__header">User Name</th>
          <th className="top__talent__header">Sticker Coins</th>
          <th className="top__talent__header">Gift Coins</th>
          <th className="top__talent__header">Video Call Coins</th>
          <th className="top__talent__header">Rose Coins</th>
          <th className="top__talent__header">Audio Call Coins</th>
          <th className="top__talent__header">Post Coins</th>
          <th className="top__talent__header">Call Gift Coins</th>

          {isHost === false && (
            <th className="top__talent__header">Total Spent</th>
          )}
          {isHost === true && (
            <th className="top__talent__header">Total Earning</th>
          )}
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data, index) => {
                return (
                  <tr>
                    <td className="top__talent__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="top__talent__data">
                      {data?.userId ? data?.userId : "-"}
                    </td>
                    <td className="top__talent__data">
                      <div
                        className="feedback__table__comment"
                        onClick={
                          data?.name?.length > 12
                            ? () =>
                                modalProvider.handleCommentClick(
                                  data?.name,
                                  "Name"
                                )
                            : () => {}
                        }
                      >
                        {data?.name ? data?.name : "0"}
                      </div>
                    </td>
                    <td className="top__talent__data">
                      {data?.stickerCoins ? data?.stickerCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.giftCoins ? data?.giftCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.videoCoins ? data?.videoCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.roseCoins ? data?.roseCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.audioCoins ? data?.audioCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.hostPostCoins ? data?.hostPostCoins : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.callCoinsGift ? data?.callCoinsGift : "0"}
                    </td>
                    {isHost === false && (
                      <td className="top__talent__data">
                        {data?.spentCoins ? data?.spentCoins : "0"}
                      </td>
                    )}
                    {isHost === true && (
                      <td className="top__talent__data">
                        {data?.host_balance ? data?.host_balance : "0"}
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      {!tableData?.length ? (
        <div className="host__no__data__found__icon">
          <Lottie
            options={{ animationData: noData, loop: true }}
            style={{ width: "40%", height: "30%" }}
          />
          <p className="no__data__found">No Data Found</p>
        </div>
      ) : null}
      {/* </div> */}
    </div>
  );
};

export default TopTalentTable;
