import moment from "moment";

const getDays = (isEven) => {
  let days = [];
  let loopInitializer = isEven ? 2 : 1;
  while (loopInitializer <= 31) {
    days.push(loopInitializer);
    loopInitializer = loopInitializer + 2;
  }

  return days;
};
export const checkLength=(e)=>{
  const handleWheel = (event) => {
    event.target.blur();
  };

  // Attach the onWheel event listener to the input element
  e.target.addEventListener('wheel', handleWheel, { passive: true });
  const KEYS = {
     leftKey: 37,
     rightKey: 39,
     backspace: 8,
     deleteKey: 46,
     digitZero: 48,
     digitNine: 57
  };
if (
    e.keyCode === KEYS.backspace ||
    e.keyCode === KEYS.deleteKey ||
    e.keyCode === KEYS.rightKey ||
    e.keyCode === KEYS.leftKey) {
      return true;
  }
if(e.keyCode < KEYS.digitZero ||
 e.keyCode > KEYS.digitNine ||
 e.target.value.length ===e.target.maxLength ||
 e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      return false;
  }
  return true;
}
const getCurrWeek = () => {
  let startOfWeek = moment().startOf("isoWeek");
  let endOfWeek = moment().endOf("isoWeek");

  let weekDays = [];
  let currDay = startOfWeek;

  while (currDay <= endOfWeek) {
    weekDays.push(
      `${currDay.toDate().getDate()} ${currDay.clone().format("MMM")}`
    );
    currDay = currDay.clone().add(1, "d");
  }

  return weekDays;
};

export const YearArray = [
  { name: "Jan", value: 1 },
  { name: "Feb", value: 2 },
  { name: "Mar", value: 3 },
  { name: "Apr", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "Aug", value: 8 },
  { name: "Sept", value: 9 },
  { name: "Oct", value: 10 },
  { name: "Nov", value: 11 },
  { name: "Dec", value: 12 },
];
export const OddMonthDateArray = getDays(false);
export const EvenMonthDateArray = getDays(true);
export const month = [...OddMonthDateArray, ...EvenMonthDateArray].sort(
  (a, b) => {
    return a - b;
  }
);
export const CurrWeekArray = getCurrWeek();
